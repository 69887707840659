import Logger from './Logger';
import hark from 'hark';
import { getSignalingUrl } from './urlFactory';
import { SocketTimeoutError, filename2type } from './utils';
import * as requestActions from './actions/requestActions';
import * as meActions from './actions/meActions';
import * as roomActions from './actions/roomActions';
import * as pollActions from './actions/pollActions';
import * as chatDrawerActions from './actions/chatDrawerActions';
import * as peerActions from './actions/peerActions';
import * as broadcasterActions from './actions/broadcasterActions';
import * as peerAudioActions from './actions/peerAudioActions';
import * as settingsActions from './actions/settingsActions';
import * as chatActions from './actions/chatActions';
import * as fileActions from './actions/fileActions';
import * as lobbyPeerActions from './actions/lobbyPeerActions';
import * as lobbyActions from './actions/lobbyActions';
import * as dialogActions from './actions/dialogActions';
import * as consumerActions from './actions/consumerActions';
import * as producerActions from './actions/producerActions';
import * as notificationActions from './actions/notificationActions';
import * as transportActions from './actions/transportActions';
import * as selectRandomPersonActions from './actions/selectRandomPersonActions';
import * as likesActions from './actions/likesActions';
import * as colorActions from './actions/colorActions';
import * as musicActions from './actions/musicActions';
import * as themeActions from './actions/themeActions';
import * as roomManagementActions from './actions/roomManagementActions';
import RecordRTC from 'recordrtc';
import Spotlights from './Spotlights';
import { permissions } from './permissions';
import getVideoConstraints from './VideoConstraints';
import deviceInfo from './deviceInfo';
import * as StackBlur from 'stackblur-canvas';
import simpleDebounce from './helpers/simpleDebounce';
import SoundFx from './SoundFx';

let createTorrent;

let WebTorrent;

let saveAs;

let mediasoupClient;

let io;

let ScreenShare;

let requestTimeout,
	lastN,
	mobileLastN,
	videoAspectRatio;

if (process.env.NODE_ENV !== 'test')
{
	({
		requestTimeout = 20000,
		lastN = 4,
		mobileLastN = 1,
		videoAspectRatio = 1.777 // 16 : 9
	} = window.config);
}

const logger = new Logger('RoomClient');

const VIDEO_CONSTRAINTS = getVideoConstraints(videoAspectRatio);

const VIDEO_SVC_ENCODINGS =
	[
		{ scalabilityMode: 'S3T3', dtx: true }
	];

const PC_PROPRIETARY_CONSTRAINTS =
{
	optional : [ { googDscp: true } ]
};

const VIDEO_SIMULCAST_PROFILES = {
	3840 :
			[
				{ scaleResolutionDownBy: 4, maxBitRate: 1500000 },
				{ scaleResolutionDownBy: 2, maxBitRate: 4000000 },
				{ scaleResolutionDownBy: 1, maxBitRate: 10000000 }
			],
	1920 :
			[
				{ scaleResolutionDownBy: 4, maxBitRate: 750000 },
				{ scaleResolutionDownBy: 2, maxBitRate: 1500000 },
				{ scaleResolutionDownBy: 1, maxBitRate: 4000000 }
			],
	1280 :
			[
				{ scaleResolutionDownBy: 4, maxBitRate: 250000 },
				{ scaleResolutionDownBy: 2, maxBitRate: 900000 },
				{ scaleResolutionDownBy: 1, maxBitRate: 3000000 }
			],
	960 :
			[
				{ scaleResolutionDownBy: 2, maxBitRate: 250000 },
				{ scaleResolutionDownBy: 1, maxBitRate: 900000 }
			],
	640 :
			[
				{ scaleResolutionDownBy: 2, maxBitRate: 250000 },
				{ scaleResolutionDownBy: 1, maxBitRate: 900000 }
			],
	480 :
			[
				{ scaleResolutionDownBy: 1, maxBitRate: 400000 }
			],
	320 :
			[
				{ scaleResolutionDownBy: 1, maxBitRate: 250000 }
			]
};

// Used for VP9 webcam video.
const VIDEO_KSVC_ENCODINGS =
[
	{ scalabilityMode: 'S3T3_KEY' }
];

/**
 * Validates the simulcast `encodings` array extracting the resolution scalings
 * array.
 * ref. https://www.w3.org/TR/webrtc/#rtp-media-api
 *
 * @param {*} encodings
 * @returns [] the resolution scalings array
 */
function getResolutionScalings(encodings)
{
	const resolutionScalings = [];

	// SVC encodings
	if (encodings.length === 1)
	{
		const { spatialLayers } =
			mediasoupClient.parseScalabilityMode(encodings[0].scalabilityMode);

		for (let i=0; i < spatialLayers; i++)
		{
			resolutionScalings.push(2 ** (spatialLayers - i - 1));
		}

		return resolutionScalings;
	}

	// Simulcast encodings
	let scaleResolutionDownByDefined = false;

	encodings.forEach((encoding) =>
	{
		if (encoding.scaleResolutionDownBy !== undefined)
		{
			// at least one scaleResolutionDownBy is defined
			scaleResolutionDownByDefined = true;
			// scaleResolutionDownBy must be >= 1.0
			resolutionScalings.push(Math.max(1.0, encoding.scaleResolutionDownBy));
		}
		else
		{
			// If encodings contains any encoding whose scaleResolutionDownBy
			// attribute is defined, set any undefined scaleResolutionDownBy
			// of the other encodings to 1.0.
			resolutionScalings.push(1.0);
		}
	});

	// If the scaleResolutionDownBy attribues of sendEncodings are
	// still undefined, initialize each encoding's scaleResolutionDownBy
	// to 2^(length of sendEncodings - encoding index - 1).
	if (!scaleResolutionDownByDefined)
	{
		encodings.forEach((encoding, index) =>
		{
			resolutionScalings[index] = 2 ** (encodings.length - index - 1);
		});
	}

	return resolutionScalings;
}

let store;

let intl;

const urlParams = new URLSearchParams(window.location.search);
const isView = urlParams.has('view');

if (isView)
{
	document.cookie = 'isView=1; SameSite=None; Secure';
}

export default class RoomClient
{
	/**
	 * @param  {Object} data
	 * @param  {Object} data.store - The Redux store.
	 * @param  {Object} data.intl - react-intl object
	 */
	static init(data)
	{
		store = data.store;
		intl = data.intl;
	}

	constructor(
		{
			peerId,
			accessCode,
			device,
			produce,
			forceTcp,
			displayName,
			muted,
			basePath
		} = {})
	{
		if (!peerId)
			throw new Error('Missing peerId');
		else if (!device)
			throw new Error('Missing device');

		logger.debug(
			'constructor() [peerId: "%s", device: "%s", produce: "%s", forceTcp: "%s", displayName ""]',
			peerId, device.flag, produce, forceTcp, displayName);

		this._signalingUrl = null;

		// Closed flag.
		this._closed = false;

		// Whether we should produce.
		this._produce = produce;

		// Whether we force TCP
		this._forceTcp = forceTcp;

		// URL basepath
		this._basePath = basePath;

		this._loadedDynamicImports = false;

		// Use displayName
		if (displayName)
			store.dispatch(settingsActions.setDisplayName(displayName));

		this._tracker = 'wss://tracker.lab.vvc.niif.hu:443';

		// Torrent support
		this._torrentSupport = null;

		// This device
		this._device = device;

		if (this._device.platform === 'mobile')
			store.dispatch(settingsActions.setMirrorOwnVideo(false));

		// Whether simulcast should be used.
		this._useSimulcast = false;

		if ('simulcast' in window.config)
			this._useSimulcast = window.config.simulcast;

		// Whether simulcast should be used for sharing
		this._useSharingSimulcast = false;

		if ('simulcastSharing' in window.config)
			this._useSharingSimulcast = window.config.simulcastSharing;

		this._muted = muted;

		// My peer name.
		this._peerId = peerId;

		// Access code
		this._accessCode = accessCode;

		this._soundFx = new SoundFx();

		// Alert sound
		this._soundAlert = new Audio('/sounds/notify.mp3');
		this._soundAlert.addEventListener('loadeddata', () =>
		{
			this._soundAlert.volume = 0.4;
		});

		// Socket.io peer connection
		this._signalingSocket = null;

		// The room ID
		this._roomId = null;

		// The sub room ID
		this._subRoomId = null;

		// mediasoup-client Device instance.
		// @type {mediasoupClient.Device}
		this._mediasoupDevice = null;

		// Put the browser info into state
		store.dispatch(meActions.setBrowser(device));

		// Our WebTorrent client
		this._webTorrent = null;

		// Max spotlights
		if (device.platform === 'desktop')
			this._maxSpotlights = lastN;
		else
			this._maxSpotlights = mobileLastN;

		store.dispatch(
			settingsActions.setLastN(this._maxSpotlights));

		// Manager of spotlight
		this._spotlights = new Spotlights(this._maxSpotlights, store.getState().settings.hideNoVideoParticipants, this);

		// Transport for sending.
		this._sendTransport = null;

		// Transport for receiving.
		this._recvTransport = null;

		// Local mic mediasoup Producer.
		this._micProducer = null;

		// Local mic hark
		this._hark = null;

		// Local MediaStream for hark
		this._harkStream = null;

		// Local webcam mediasoup Producer.
		this._webcamProducer = null;

		// Extra videos being produced
		this._extraVideoProducers = new Map();

		this._chatAutoDisplayed = false;

		// Map of webcam MediaDeviceInfos indexed by deviceId.
		// @type {Map<String, MediaDeviceInfos>}
		this._webcams = {};

		this._audioDevices = {};

		this._audioOutputDevices = {};

		// mediasoup Consumers.
		// @type {Map<String, mediasoupClient.Consumer>}
		this._consumers = new Map();

		this._screenSharing = null;

		this._screenSharingProducer = null;
		this._screenSharingAudioProducer = null;

		this._recorder = null;

		this._startKeyListener();

		this._updateDevices();

		this._startDevicesListener();

		if (store.getState().settings.localPicture)
		{
			store.dispatch(meActions.setPicture(store.getState().settings.localPicture));
		}

		this._recvRestartIce = { timer: null, restarting: false };
		this._sendRestartIce = { timer: null, restarting: false };

		this.selfies = [];

		this._peerColorHighlights = {};
	}

	leave()
	{
		const doNotAsk = window.localStorage.getItem('leave_do_not_ask') === 'true';

		if (doNotAsk)
		{
			this.close();
		}
		else
		{
			store.dispatch(roomActions.openLeaveDialog());
		}
	}

	close(redirect = true)
	{
		if (this._closed)
			return;

		this._closed = true;

		logger.debug('close()');

		this.disconnect();

		store.dispatch(roomActions.setRoomState('closed'));

		if (redirect)
		{
			if (this._iOS())
			{
				window.location = `/${this._roomId}`;
			}
			else
			{
				window.location = `https://withlocals.typeform.com/to/azd31VyV?roomid=${this._roomId}`;
			}
		}
	}

	_iOS()
	{
		return [
			'iPad Simulator',
			'iPhone Simulator',
			'iPod Simulator',
			'iPad',
			'iPhone',
			'iPod'
		].includes(navigator.platform)
		// iPad on iOS 13 detection
		|| (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
	}

	disconnect()
	{
		this._signalingSocket.close();

		// Close mediasoup Transports.
		if (this._sendTransport)
			this._sendTransport.close();

		if (this._recvTransport)
			this._recvTransport.close();
	}

	_startKeyListener()
	{
		// Add keydown event listener on document
		document.addEventListener('keydown', (event) =>
		{
			if (event.repeat) return;
			const key = event.key;

			const source = event.target;

			const exclude = [ 'input', 'textarea' ];

			if (exclude.indexOf(source.tagName.toLowerCase()) === -1)
			{
				const controlModifier = event.ctrlKey || event.metaKey;

				if (!controlModifier)
					return;

				logger.debug('keyDown() [key:"%s"]', key);

				switch (key)
				{
					case 'd': // Toggle microphone
					{
						event.preventDefault();
						event.stopPropagation();

						if (this._micProducer)
						{
							if (!this._micProducer.paused)
							{
								this.muteMic();

								store.dispatch(requestActions.notify(
									{
										text : intl.formatMessage({
											id             : 'devices.microphoneMute',
											defaultMessage : 'Muted your microphone'
										})
									}));
							}
							else
							{
								this.unmuteMic();

								store.dispatch(requestActions.notify(
									{
										text : intl.formatMessage({
											id             : 'devices.microphoneUnMute',
											defaultMessage : 'Unmuted your microphone'
										})
									}));
							}
						}
						else
						{
							this.updateMic({ start: true });

							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'devices.microphoneEnable',
										defaultMessage : 'Enabled your microphone'
									})
								}));
						}

						break;
					}

					default:
					{
						break;
					}
				}
			}
		});
	}

	async _updateDevices()
	{
		logger.debug('_updateDevices()');

		await this._updateAudioDevices();
		await this._updateWebcams();
		await this._updateAudioOutputDevices();
	}

	_startDevicesListener()
	{
		const device = deviceInfo();

		if (device.flag !== 'safari' && device.flag !== 'firefox' && navigator.permissions)
		{
			navigator.permissions.query({ name: 'camera' }).then((result) =>
			{
				result.onchange = (async (e) =>
				{
					if (e.type === 'change')
					{
						const newState = e.target.state;

						if (newState === 'granted')
						{
							await this._updateWebcams();
						}
					}
				});
			});

			navigator.permissions.query({ name: 'microphone' }).then((result) =>
			{
				result.onchange = (async (e) =>
				{
					if (e.type === 'change')
					{
						const newState = e.target.state;

						if (newState === 'granted')
						{
							await this._updateAudioDevices();
							await this._updateAudioOutputDevices();
						}
					}
				});
			});

			navigator.mediaDevices.removeEventListener('devicechange', this._updateDevices.bind(this));
			navigator.mediaDevices.addEventListener('devicechange', this._updateDevices.bind(this));
		}
	}

	login({ roomId = this._roomId, history })
	{
		const searchParams = new URLSearchParams();

		searchParams.set('peerId', this._peerId);
		searchParams.set('roomId', roomId);

		history.push({
			pathname : '/login',
			search   : searchParams.toString()
		});
	}

	logout({ roomId = this._roomId })
	{
		window.open(`/auth/logout?peerId=${this._peerId}&roomId=${roomId}`, 'logoutWindow');
	}

	feedback()
	{
		const searchParams = new URLSearchParams();

		if (this._subRoomId)
		{
			searchParams.set('subroomid', this._subRoomId);
		}

		searchParams.set('roomid', this._roomId);

		window.open(`https://withlocals.typeform.com/to/azd31VyV?${searchParams.toString()}`, '_blank', 'noopener');
	}

	_loadImage(path)
	{
		return new Promise((resolve) =>
		{
			const img = new Image();

			img.onload = () => resolve(img);
			img.onerror = () => resolve({ path, status: 'error' });

			img.src = path;
		});
	}

	async openRemotePresentationControl(peerId)
	{
		logger.debug('openRemotePresentationControl(%s)', peerId);

		try
		{

			const {
				code
			} = await this.sendRequest(
				'getRemotePresentationControlCode',
				{ peerId });

			window.open(
				`https://slides.limhenry.xyz/${code}`,
				'',
				'width=600,height=400,left=200,top=200'
			);
		}
		catch (error)
		{
			logger.error('openRemotePresentationControl() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : 'Could not establish connection with remote presentation'
				}));
		}
	}

	async setAudioGain(micConsumer, peerId, audioGain)
	{
		logger.debug(
			'setAudioGain() [micConsumer:"%o", peerId:"%s", type:"%s"]',
			micConsumer,
			peerId,
			audioGain
		);

		if (!micConsumer)
		{
			return;
		}

		micConsumer.audioGain = audioGain;

		try
		{
			for (const consumer of this._consumers.values())
			{
				if (consumer.appData.peerId === peerId)
				{
					store.dispatch(consumerActions.setConsumerAudioGain(consumer.id, audioGain));
				}
			}
		}
		catch (error)
		{
			logger.error('setAudioGain() [error:"%o"]', error);
		}
	}

	async setRemotePresentationControlCode(code)
	{
		logger.debug('setRemotePresentationControlCode(%s)', code);

		try
		{
			await this.sendRequest('setRemotePresentationControlCode', { code });

			store.dispatch(meActions.setRemotePresentationControlCode(code));
			store.dispatch(dialogActions.setRemotePresentationDialogOpen(false));
		}
		catch (error)
		{
			logger.error('setRemotePresentationControlCode() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : 'Failed to set remote presentation control code'
				}));
		}
	}

	async getFaces()
	{
		logger.debug('getFaces');

		try
		{
			const peerId = this._peerId;

			await this.sendRequest('getScreenshots', { peerId, width: 640, height: 360 });
			await window.faceapi.loadSsdMobilenetv1Model('/models');
			const numberOfPeers = Object.values(store.getState().peers).length;

			const doSelfie = async () =>
			{
				store.dispatch(requestActions.notify(
					{
						text : intl.formatMessage({
							id             : 'room.selfiesReceived',
							defaultMessage : 'Received {nr} selfies'
						}, {
							nr : this.selfies.length
						})
					}));

				const images = await Promise.all(this.selfies.map((data) => this._loadImage(data)));
				const width = images.reduce((p, c) => (p.width > c.width ? p : c)).width;
				const height = images.reduce((p, c) => (p.height > c.height ? p : c)).height;
				const canvas = document.createElement('canvas');
				const ctx = canvas.getContext('2d');

				const maxNumberOfImagesPerRow = 3;

				const rows = Math.ceil(images.length / maxNumberOfImagesPerRow);

				canvas.width = Math.min(width * images.length, width * maxNumberOfImagesPerRow);
				canvas.height = height * rows;
				ctx.fillStyle = 'white';
				ctx.fillRect(0, 0, canvas.width, canvas.height);
				let curX = 0;

				let curY = 0;

				let maxX = 0;

				let maxY = 0;

				let maxBoxHeight = 0;

				for (let i = 0, currentRow = 0; i < images.length; i++)
				{
					const detection = await window.faceapi
						.detectSingleFace(images[i], new window.faceapi.SsdMobilenetv1Options());

					if (detection && detection.box)
					{
						const box = detection.box;
						const canvas2 = document.createElement('canvas');

						canvas2.width = width;
						canvas2.height = height;

						const ctx2 = canvas2.getContext('2d');

						// ctx2.fillStyle = 'white';
						// ctx2.fillRect(0, 0, canvas2.width, canvas2.height);
						ctx2.beginPath();
						ctx2.arc((box.topLeft.x + box.topRight.x) / 2, ((box.topLeft.y + box.bottomLeft.y) / 2) - 35, height / 3, 0, Math.PI * 2);
						ctx2.clip();
						ctx2.lineWidth = 2;
						ctx2.stroke();
						ctx2.closePath();
						ctx2.drawImage(images[i], 0, 0);

						ctx.shadowOffsetX = 10;
						ctx.shadowOffsetY = 10;
						ctx.shadowColor = 'black';
						ctx.shadowBlur = 30;
						ctx.drawImage(canvas2, box.topLeft.x - 40, box.topLeft.y - 80, box.width + 80, box.height + 80, curX, curY, box.width, box.height);
						curX = curX + box.width + 20;

						maxX = Math.max(maxX, curX);
						maxY = Math.max(maxY, curY);
						maxBoxHeight = Math.max(maxBoxHeight, box.height);
						if ((i + 1) >= (maxNumberOfImagesPerRow * (currentRow + 1)))
						{
							currentRow++;
							curY = curY + maxBoxHeight + 20;
							curX = 0;
						}
					}
				}

				maxY += maxBoxHeight;

				const tmpCanvas = document.createElement('canvas');

				tmpCanvas.width = maxX;
				tmpCanvas.height = maxY + 70;
				const ctx3 = tmpCanvas.getContext('2d');

				ctx3.drawImage(canvas, 0, 0);
				const b2bLogo = await this._loadImage('/images/logo.svg');

				ctx3.drawImage(b2bLogo, (maxX / 2) - 75, maxY + 20, 150, 35);

				const image = tmpCanvas.toDataURL('image/png');

				this._downloadImage(image);

				this.selfies = [];
			};

			// eslint-disable-next-line prefer-const
			let timeoutRef, intervalRef;

			// eslint-disable-next-line prefer-const
			timeoutRef = setTimeout(() =>
			{
				if (intervalRef)
					clearInterval(intervalRef);
				doSelfie();
			}, 12000);

			// eslint-disable-next-line prefer-const
			intervalRef = setInterval(() =>
			{
				if (this.selfies.length === numberOfPeers)
				{
					if (intervalRef)
						clearInterval(intervalRef);
					if (timeoutRef)
						clearTimeout(timeoutRef);

					doSelfie();
				}
			}, 500);
		}
		catch (error)
		{
			logger.error('getFaces() [error:"%o"]', error);
		}
	}

	async getScreenshots()
	{
		logger.debug('getScreenshots');

		try
		{
			const peerId = this._peerId;

			await this.sendRequest('getScreenshots', { peerId, width: 640, height: 360 });
			const numberOfPeers = Object.values(store.getState().peers).length;

			const doSelfie = async () =>
			{
				store.dispatch(requestActions.notify(
					{
						text : intl.formatMessage({
							id             : 'room.selfiesReceived',
							defaultMessage : 'Received {nr} selfies'
						}, {
							nr : this.selfies.length
						})
					}));

				const images = await Promise.all(this.selfies.map((data) => this._loadImage(data)));
				const width = images.reduce((p, c) => (p.width > c.width ? p : c)).width;
				const height = images.reduce((p, c) => (p.height > c.height ? p : c)).height;
				const canvas = document.createElement('canvas');
				const ctx = canvas.getContext('2d');

				const maxNumberOfImagesPerRow = 3;

				const rows = Math.ceil(images.length / maxNumberOfImagesPerRow);

				canvas.width = Math.min(width * images.length, width * maxNumberOfImagesPerRow);
				canvas.height = height * rows;
				ctx.fillRect(0, 0, canvas.width, canvas.height);

				for (let i = 0, currentRow = 0; i < images.length; i++)
				{
					ctx.drawImage(images[i], (i - (currentRow * maxNumberOfImagesPerRow)) * width, currentRow * height);
					if ((i + 1) >= (maxNumberOfImagesPerRow * (currentRow + 1)))
					{
						currentRow++;
					}
				}

				const image = canvas.toDataURL('image/png');

				this._downloadImage(image);

				this.selfies = [];
			};

			// eslint-disable-next-line prefer-const
			let timeoutRef, intervalRef;

			// eslint-disable-next-line prefer-const
			timeoutRef = setTimeout(() =>
			{
				if (intervalRef)
					clearInterval(intervalRef);
				doSelfie();
			}, 12000);

			// eslint-disable-next-line prefer-const
			intervalRef = setInterval(() =>
			{
				if (this.selfies.length === numberOfPeers)
				{
					if (intervalRef)
						clearInterval(intervalRef);
					if (timeoutRef)
						clearTimeout(timeoutRef);

					doSelfie();
				}
			}, 500);
		}
		catch (error)
		{
			logger.error('getScreenshots() [error:"%o"]', error);
		}
	}

	_makeScreenshot(videoElement, width, height)
	{
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');
		const w = videoElement.videoWidth;
		const h = videoElement.videoHeight;

		canvas.width = width || w;
		canvas.height = height || h;
		ctx.fillRect(0, 0, canvas.width, canvas.height);
		ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

		const screenshot = canvas.toDataURL('image/png');

		ctx.clearRect(0, 0, w, h); // clean the canvas

		return screenshot;
	}

	async sendScreenshotOfSelf(peerId, width, height)
	{
		try
		{
			const currentSetting = store.getState().room.hideSelfView;

			store.dispatch(roomActions.setHideSelfView(false));
			setTimeout(async () =>
			{
				const myVideo = document.getElementsByTagName('video')[0];
				const screenshot = this._makeScreenshot(myVideo, width, height);

				store.dispatch(roomActions.setHideSelfView(currentSetting));
				await this.sendRequest('sendScreenshotsOfSelf', { peerId, screenshot });
			}, currentSetting ? 2000 : 0);
		}
		catch (error)
		{
			logger.error('sendScreenshotOfSelf() [error:"%o"]', error);
		}
	}

	_dataURIToBlob(dataURI, callback)
	{
		const binStr = atob(dataURI.split(',')[1]);
		const len = binStr.length;
		const arr = new Uint8Array(len);

		for (let i = 0; i < len; i++)
		{
			arr[i] = binStr.charCodeAt(i);
		}

		callback(new Blob([ arr ]));
	}

	_downloadImage(image)
	{
		this._dataURIToBlob(image, (blob) =>
		{
			const link = document.createElement('a');

			link.href = URL.createObjectURL(blob);
			link.download = 'Image.png';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		});
	}

	screenshot(videoElement)
	{
		try
		{
			const screenshot = this._makeScreenshot(videoElement);

			this._downloadImage(screenshot);
		}
		catch (error)
		{
			logger.error('screenshot() [error:"%o"]', error);
		}
	}

	handleLogin(data)
	{
		const { displayName, picture, peerId, roles } = data;

		store.dispatch(settingsActions.setDisplayName(displayName));
		store.dispatch(meActions.setPicture(picture));
		roles.forEach((role) =>
		{
			store.dispatch(peerActions.addPeerRole(peerId, role.id));
			store.dispatch(meActions.addRole(role.id));
		});

		store.dispatch(meActions.loggedIn(true));

		store.dispatch(requestActions.notify(
			{
				text : intl.formatMessage({
					id             : 'room.loggedIn',
					defaultMessage : 'You are logged in'
				})
			}));
	}

	notify(message)
	{
		store.dispatch(requestActions.notify({ text: message }));
	}

	setPicture(picture)
	{
		store.dispatch(settingsActions.setLocalPicture(picture));
		store.dispatch(meActions.setPicture(picture));
		this.changePicture(picture);
	}

	receiveLoginChildWindow(data)
	{
		logger.debug('receiveFromChildWindow() | [data:"%o"]', data);

		const { displayName, picture } = data;

		store.dispatch(settingsActions.setDisplayName(displayName));
		if (!store.getState().settings.localPicture)
		{
			store.dispatch(meActions.setPicture(picture));
		}

		store.dispatch(meActions.loggedIn(true));

		store.dispatch(requestActions.notify(
			{
				text : intl.formatMessage({
					id             : 'room.loggedIn',
					defaultMessage : 'You are logged in'
				})
			}));
	}

	receiveLogoutChildWindow()
	{
		logger.debug('receiveLogoutChildWindow()');

		if (!store.getState().settings.localPicture)
		{
			store.dispatch(meActions.setPicture(null));
		}

		store.dispatch(meActions.loggedIn(false));

		store.dispatch(requestActions.notify(
			{
				text : intl.formatMessage({
					id             : 'room.loggedOut',
					defaultMessage : 'You are logged out'
				})
			}));
	}

	_soundNotification()
	{
		const { notificationSounds } = store.getState().settings;

		if (notificationSounds)
		{
			const alertPromise = this._soundAlert.play();

			if (alertPromise !== undefined)
			{
				alertPromise
					.then()
					.catch((error) =>
					{
						logger.error('_soundAlert.play() [error:"%o"]', error);
					});
			}
		}
	}

	timeoutCallback(callback)
	{
		let called = false;

		const interval = setTimeout(
			() =>
			{
				if (called)
					return;
				called = true;
				callback(new SocketTimeoutError('Request timed out'));
			},
			requestTimeout
		);

		return (...args) =>
		{
			if (called)
				return;
			called = true;
			clearTimeout(interval);

			callback(...args);
		};
	}

	_sendRequest(method, data)
	{
		return new Promise((resolve, reject) =>
		{
			if (!this._signalingSocket)
			{
				reject('No socket connection');
			}
			else
			{
				this._signalingSocket.emit(
					'request',
					{ method, data },
					this.timeoutCallback((err, response) =>
					{
						if (err)
							reject(err);
						else
							resolve(response);
					})
				);
			}
		});
	}

	async getTransportStats()
	{
		try
		{
			if (this._recvTransport)
			{
				logger.debug('getTransportStats() - recv [transportId: "%s"]', this._recvTransport.id);

				const recv = await this.sendRequest('getTransportStats', { transportId: this._recvTransport.id });

				store.dispatch(
					transportActions.addTransportStats(recv, 'recv'));
			}

			if (this._sendTransport)
			{
				logger.debug('getTransportStats() - send [transportId: "%s"]', this._sendTransport.id);

				const send = await this.sendRequest('getTransportStats', { transportId: this._sendTransport.id });

				store.dispatch(
					transportActions.addTransportStats(send, 'send'));
			}
		}
		catch (error)
		{
			logger.error('getTransportStats() [error:"%o"]', error);
		}
	}

	async sendRequest(method, data)
	{
		logger.debug('sendRequest() [method:"%s", data:"%o"]', method, data);

		const {
			requestRetries = 3
		} = window.config;

		for (let tries = 0; tries < requestRetries; tries++)
		{
			try
			{
				return await this._sendRequest(method, data);
			}
			catch (error)
			{
				if (
					error instanceof SocketTimeoutError &&
					tries < requestRetries
				)
					logger.warn('sendRequest() | timeout, retrying [attempt:"%s"]', tries);
				else
					throw error;
			}
		}
	}

	async setPeerLabel(peerId, label)
	{
		logger.debug('setPeerLabel(peerId:"%s", label:"%s" )', peerId, label);

		try
		{
			await this.sendRequest('moderator:setPeerLabel', { peerId, label });
		}
		catch (error)
		{
			logger.error('setPeerLabel() [error:"%o"]', error);
		}
	}

	async setShowWebsite(showWebsite)
	{
		logger.debug('setShowWebsite() to "%s"', showWebsite);

		try
		{
			await this.sendRequest('moderator:setShowWebsite', { showWebsite });
		}
		catch (error)
		{
			logger.error('setShowWebsite() [error:"%o"]', error);
		}
	}

	async forceHighlightedPeerId(peerId)
	{
		logger.debug('forceHighlightedPeerId() to peerId "%s"', peerId);

		try
		{
			const { forcedLayout } = store.getState().room;

			const forceFocusLayout = forcedLayout !== 'discussion' && forcedLayout !== 'focus';

			await this.sendRequest('forceHighlightedPeerId', { peerId, forceFocusLayout });
		}
		catch (error)
		{
			logger.error('forceHighlightedPeerId() [error:"%o"]', error);
		}
	}

	async removeHighlightedPeerId(peerId)
	{
		logger.debug('removeHighlightedPeerId() to peerId "%s"', peerId);

		try
		{
			await this.sendRequest('removeHighlightedPeerId', { peerId });
		}
		catch (error)
		{
			logger.error('removeHighlightedPeerId() [error:"%o"]', error);
		}
	}

	async clearHighlightedPeerId()
	{
		logger.debug('clearHighlightedPeerId()');

		try
		{
			await this.sendRequest('clearForcedHighlightedPeerId');
		}
		catch (error)
		{
			logger.error('clearHighlightedPeerId() [error:"%o"]', error);
		}
	}

	async addToAlwaysVisiblePeerId(peerId, forceDiscussionLayout)
	{
		logger.debug('addToAlwaysVisiblePeerId() to peerId "%s"', peerId);

		try
		{
			await this.sendRequest('addToAlwaysVisiblePeerId', { peerId, forceDiscussionLayout });
		}
		catch (error)
		{
			logger.error('addToAlwaysVisiblePeerId() [error:"%o"]', error);
		}
	}

	async removeFromAlwaysVisiblePeerId(peerId)
	{
		logger.debug('removeFromAlwaysVisiblePeerId() to peerId "%s"', peerId);

		try
		{
			await this.sendRequest('removeFromAlwaysVisiblePeerId', { peerId });
		}
		catch (error)
		{
			logger.error('removeFromAlwaysVisiblePeerId() [error:"%o"]', error);
		}
	}

	async changeDisplayName(displayName)
	{
		displayName = displayName.trim();

		if (!displayName.trim())
		{
			store.dispatch(requestActions.notify(
				{
					text : 'A name is required!',
					type : 'error'
				}));

			return;
		}

		logger.debug('changeDisplayName() [displayName:"%s"]', displayName);

		store.dispatch(
			meActions.setDisplayNameInProgress(true));

		try
		{
			await this.sendRequest('changeDisplayName', { displayName });

			store.dispatch(settingsActions.setDisplayName(displayName));

			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'room.changedDisplayName',
						defaultMessage : 'Your display name changed to {displayName}'
					}, {
						displayName
					})
				}));
		}
		catch (error)
		{
			logger.error('changeDisplayName() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'room.changeDisplayNameError',
						defaultMessage : 'An error occurred while changing your display name'
					})
				}));
		}

		store.dispatch(
			meActions.setDisplayNameInProgress(false));
	}

	async changePicture(picture)
	{
		logger.debug('changePicture() [picture: "%s"]', picture);

		try
		{
			await this.sendRequest('changePicture', { picture });
		}
		catch (error)
		{
			logger.error('changePicture() [error:"%o"]', error);
		}
	}

	async sendChatMessage(chatMessage)
	{
		logger.debug('sendChatMessage() [chatMessage:"%s"]', chatMessage);

		try
		{
			const { recipients } = store.getState().chat;

			if (recipients.length === 0)
			{
				return;
			}

			const enrichedRecipients = Object.values(store.getState().peers)
				.filter((peer) => recipients.includes(peer.id))
				.map((peer) =>
				{
					return {
						id   : peer.id,
						name : peer.displayName
					};
				});

			store.dispatch(
				chatActions.addUserMessage(chatMessage.text, chatMessage.type, enrichedRecipients));

			await this.sendRequest('chatMessage', { chatMessage, recipients });
		}
		catch (error)
		{
			logger.error('sendChatMessage() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'room.chatError',
						defaultMessage : 'Unable to send chat message'
					})
				}));
		}
	}

	async sendPlayingMusic(playingMusic)
	{
		logger.debug('sendPlayingMusic() [url:"%s"]', playingMusic);

		try
		{
			await this.sendRequest('sendPlayingMusic', { playingMusic });
		}
		catch (error)
		{
			logger.error('sendPlayingMusic() [error:"%o"]', error);
		}
	}

	async sendMusicPlayIndex(playIndex)
	{
		logger.debug('sendMusicPlayIndex() [playIndex:"%s"]', playIndex);

		try
		{
			await this.sendRequest('sendMusicPlayIndex', { playIndex });
		}
		catch (error)
		{
			logger.error('sendMusicPlayIndex() [error:"%o"]', error);
		}
	}

	async sendMusicPlayback(playback)
	{
		logger.debug('sendMusicPlayback() [playback:"%s"]', playback);

		try
		{
			await this.sendRequest('sendMusicPlayback', { playback });
		}
		catch (error)
		{
			logger.error('sendMusicPlayback() [error:"%o"]', error);
		}
	}

	async sendMusicSeek(currentTime)
	{
		logger.debug('sendMusicSeek() [currentTime:"%s"]', currentTime);

		try
		{
			await this.sendRequest('sendMusicSeek', { currentTime });
		}
		catch (error)
		{
			logger.error('sendMusicSeek() [error:"%o"]', error);
		}
	}

	async sendMusicVolume(volume)
	{
		logger.debug('sendMusicVolume() [volume:"%s"]', volume);

		try
		{
			await this.sendRequest('sendMusicVolume', { volume });
		}
		catch (error)
		{
			logger.error('sendMusicVolume() [error:"%o"]', error);
		}
	}

	saveFile(file)
	{
		file.getBlob((err, blob) =>
		{
			if (err)
			{
				store.dispatch(requestActions.notify(
					{
						type : 'error',
						text : intl.formatMessage({
							id             : 'filesharing.saveFileError',
							defaultMessage : 'Unable to save file'
						})
					}));

				return;
			}

			saveAs(blob, file.name);
		});
	}

	getImageFromFile(file)
	{
		return new Promise((resolve) =>
		{
			file.getBlob((_, blob) =>
			{
				const url = URL.createObjectURL(blob);

				resolve(url);
			});
		});
	}

	handleDownload(magnetUri)
	{
		store.dispatch(
			fileActions.setFileActive(magnetUri));

		const existingTorrent = this._webTorrent.get(magnetUri);

		if (existingTorrent)
		{
			// Never add duplicate torrents, use the existing one instead.
			return this._handleTorrent(existingTorrent);
		}

		return new Promise((resolveFn, rejectFn) =>
		{
			this._webTorrent.add(magnetUri, null, (torrent) =>
			{
				torrent.on('done', () =>
				{
					this._handleTorrent(torrent).then(resolveFn);
				});

				torrent.on('noPeers', () =>
				{
					torrent.destroy(rejectFn);
					store.dispatch(
						fileActions.removeFile(magnetUri)
					);
				});
			});
		});
	}

	_decodeFiles(files)
	{
		const promises =
      files
      	.map((file) => ({ file, type: filename2type(file.name) }))
      	.filter(({ type }) => Boolean(type))
      	.map(({ file, type }) =>
      		this.getImageFromFile(file)
      			.then((url) => ({ url, type }))
      	);

		return Promise.all(promises);
	}

	_handleTorrent(torrent)
	{
		// Torrent already done, this can happen if the
		// same file was sent multiple times.
		if (torrent.progress === 1)
		{
			return this._decodeFiles(torrent.files)
				.then((decodedFiles) =>
				{
					store.dispatch(
						fileActions.setFileDone(
							torrent.magnetURI,
							torrent.files,
							decodedFiles
						));

					return decodedFiles;
				});
		}

		return new Promise((resolveFn) =>
		{
			let lastMove = 0;

			torrent.on('download', () =>
			{
				if (Date.now() - lastMove > 1000)
				{
					store.dispatch(
						fileActions.setFileProgress(
							torrent.magnetURI,
							torrent.progress
						));

					lastMove = Date.now();
				}
			});

			torrent.on('done', () =>
			{
				this._decodeFiles(torrent.files)
					.then((decodedFiles) =>
					{
						store.dispatch(
							fileActions.setFileDone(
								torrent.magnetURI,
								torrent.files,
								decodedFiles
							));
						resolveFn(decodedFiles);
					});
			});
		});
	}

	shareFiles(files)
	{
		return new Promise((resolveFn, rejectFn) =>
		{
			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'filesharing.startingFileShare',
						defaultMessage : 'Attempting to share file'
					})
				}));

			createTorrent(files, (err, torrent) =>
			{
				if (err)
				{
					store.dispatch(requestActions.notify(
						{
							type : 'error',
							text : intl.formatMessage({
								id             : 'filesharing.unableToShare',
								defaultMessage : 'Unable to share file'
							})
						}));

					rejectFn();
				}

				const existingTorrent = this._webTorrent.get(torrent);

				if (existingTorrent)
				{
					store.dispatch(requestActions.notify(
						{
							text : intl.formatMessage({
								id             : 'filesharing.successfulFileShare',
								defaultMessage : 'File successfully shared'
							})
						}));

					store.dispatch(fileActions.addFile(
						this._peerId,
						existingTorrent.magnetURI
					));

					this._sendFile(existingTorrent.magnetURI);

					resolveFn(existingTorrent.magnetURI);
				}

				this._webTorrent.seed(
					files,
					{ announceList: [ [ this._tracker ] ] },
					(newTorrent) =>
					{
						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'filesharing.successfulFileShare',
									defaultMessage : 'File successfully shared'
								})
							}));

						store.dispatch(fileActions.addFile(
							this._peerId,
							newTorrent.magnetURI
						));

						this._sendFile(newTorrent.magnetURI);

						resolveFn(newTorrent.magnetURI);
					});
			});
		});
	}

	// { file, name, picture }
	async _sendFile(magnetUri)
	{
		logger.debug('sendFile() [magnetUri:"%o"]', magnetUri);

		try
		{
			await this.sendRequest('sendFile', { magnetUri });
		}
		catch (error)
		{
			logger.error('sendFile() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'filesharing.unableToShare',
						defaultMessage : 'Unable to share file'
					})
				}));
		}
	}

	async muteMic(softMute = false)
	{
		logger.debug('muteMic()');

		if (!this._micProducer)
		{
			logger.error('muteMic() no producer availble');

			return;
		}

		this._micProducer.pause();

		try
		{
			await this.sendRequest(
				'pauseProducer', { producerId: this._micProducer.id });

			store.dispatch(
				producerActions.setProducerPaused(this._micProducer.id));

			if (!softMute)
			{
				store.dispatch(
					settingsActions.setAudioMuted(true));
			}
		}
		catch (error)
		{
			logger.error('muteMic() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'devices.microphoneMuteError',
						defaultMessage : 'Unable to mute your microphone'
					})
				}));
		}
	}

	async unmuteMic(softUnmute = false)
	{
		logger.debug('unmuteMic()');

		const { audioMuted } = store.getState().settings;

		if (!this._micProducer)
		{
			if ((softUnmute && !audioMuted) || !softUnmute)
			{
				this.updateMic({ start: true });
			}
		}
		else
		if ((softUnmute && !audioMuted) || !softUnmute)
		{
			this._micProducer.resume();

			try
			{
				await this.sendRequest(
					'resumeProducer', { producerId: this._micProducer.id });

				store.dispatch(
					producerActions.setProducerResumed(this._micProducer.id));

				store.dispatch(
					settingsActions.setAudioMuted(false));
			}
			catch (error)
			{
				logger.error('unmuteMic() [error:"%o"]', error);

				store.dispatch(requestActions.notify(
					{
						type : 'error',
						text : intl.formatMessage({
							id             : 'devices.microphoneUnMuteError',
							defaultMessage : 'Unable to unmute your microphone'
						})
					}));
			}
		}
	}

	changeMaxSpotlights(maxSpotlights)
	{
		this._spotlights.maxSpotlights = maxSpotlights;

		store.dispatch(
			settingsActions.setLastN(maxSpotlights));
	}

	async updateAlwaysVisibleSpotlights(alwaysVisibleSpotlights)
	{
		logger.debug('updateAlwaysVisibleSpotlights()');

		store.dispatch(roomActions.setAlwaysVisibleSpotlights(alwaysVisibleSpotlights));
	}

	// Updated consumers based on spotlights
	async updateSpotlights(spotlights)
	{
		logger.debug('updateSpotlights()');

		store.dispatch(roomActions.setSpotlights(spotlights));

		await this.refreshSpotlights();
	}

	async refreshSpotlights()
	{
		const { spotlights } = store.getState().room;
		const alwaysVisibleSpotlights = this._spotlights.alwaysVisibleSpotlights;
		const { highlightedPeersOnly } = store.getState().settings;

		try
		{
			for (const consumer of this._consumers.values())
			{
				if (consumer.appData.source.startsWith('broadcast_'))
					continue;

				if (consumer.kind === 'video')
				{
					if ((highlightedPeersOnly && alwaysVisibleSpotlights.includes(consumer.appData.peerId))||
						(!highlightedPeersOnly && spotlights.includes(consumer.appData.peerId)))
						await this._resumeConsumer(consumer);
					else
					{
						await this._pauseConsumer(consumer);
						store.dispatch(
							roomActions.removeSelectedPeer(consumer.appData.peerId));
					}
				}
			}

			await this.updateConsumerPreferredLayers();
		}
		catch (error)
		{
			logger.error('updateSpotlights() [error:"%o"]', error);
		}
	}

	disconnectLocalHark()
	{
		logger.debug('disconnectLocalHark()');

		if (this._harkStream != null)
		{
			let [ track ] = this._harkStream.getAudioTracks();

			track.stop();
			track = null;

			this._harkStream = null;
		}

		if (this._hark != null)
			this._hark.stop();
	}

	connectLocalHark(track)
	{
		logger.debug('connectLocalHark() [track:"%o"]', track);

		this._harkStream = new MediaStream();

		const newTrack = track.clone();

		this._harkStream.addTrack(newTrack);

		newTrack.enabled = true;

		this._hark = hark(this._harkStream,
			{
				play      : false,
				interval  : 10,
				threshold : store.getState().settings.noiseThreshold,
				history   : 100
			});

		this._hark.lastVolume = -100;

		this._hark.on('speaking', () =>
		{
			store.dispatch(meActions.setIsSpeaking(true));

			if (
				(store.getState().settings.voiceActivatedUnmute ||
				store.getState().me.isAutoMuted) &&
				this._micProducer &&
				this._micProducer.paused
			)
				this._micProducer.resume();

			store.dispatch(peerAudioActions.setPeerMakingSound(`mic-${this._peerId}`, true));
			store.dispatch(meActions.setAutoMuted(false)); // sanity action
		});

		this._hark.on('stopped_speaking', () =>
		{
			store.dispatch(meActions.setIsSpeaking(false));

			if (
				store.getState().settings.voiceActivatedUnmute &&
				this._micProducer &&
				!this._micProducer.paused
			)
			{
				this._micProducer.pause();

				store.dispatch(meActions.setAutoMuted(true));
			}
			store.dispatch(peerAudioActions.setPeerMakingSound(`mic-${this._peerId}`, false));
		});
	}

	async changeAudioOutputDevice(deviceId)
	{
		logger.debug('changeAudioOutputDevice() [deviceId:"%s"]', deviceId);

		store.dispatch(
			meActions.setAudioOutputInProgress(true));

		try
		{
			const device = this._audioOutputDevices[deviceId];

			if (!device)
				throw new Error('Selected audio output device no longer available');

			store.dispatch(settingsActions.setSelectedAudioOutputDevice(deviceId));

			await this._updateAudioOutputDevices();
		}
		catch (error)
		{
			logger.error('changeAudioOutputDevice() [error:"%o"]', error);
		}

		store.dispatch(
			meActions.setAudioOutputInProgress(false));
	}

	async cycleCamera()
	{
		const webcams = Object.values(this._webcams);
		const { selectedWebcam } = store.getState().settings;

		const currentDeviceId = (selectedWebcam && this._webcams[selectedWebcam]) ? selectedWebcam : webcams[0] ? webcams[0].deviceId : null;
		const currentIndex = webcams.findIndex((f) => f.deviceId === currentDeviceId);
		const newIndex = (currentIndex === webcams.length - 1 && currentIndex !== 0) ? 0 : currentIndex + 1;

		const newWebcam = webcams[newIndex];

		if (newWebcam)
		{
			this.updateWebcam({ newDeviceId: newWebcam.deviceId, restart: true });
			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'device.switchedCamera',
						defaultMessage : 'Switched camera to {newCamera}'
					}, {
						newCamera : newWebcam.label
					})
				}));
		}
	}

	// Only Firefox supports applyConstraints to audio tracks
	// See:
	// https://bugs.chromium.org/p/chromium/issues/detail?id=796964
	async updateMic({
		start = false,
		isLocal = false,
		restart = false ?? this._device.flag !== 'firefox',
		newDeviceId = null,
		forceAudioCodec = null
	} = {})
	{
		logger.debug(
			'updateMic() [start:"%s", restart:"%s", newDeviceId:"%s"]',
			start,
			restart,
			newDeviceId
		);

		let track;

		try
		{
			if (!isLocal && !this._mediasoupDevice.canProduce('audio'))
				throw new Error('cannot produce audio');

			if (!isLocal && newDeviceId && !restart)
				throw new Error('changing device requires restart');

			if (newDeviceId)
				store.dispatch(settingsActions.setSelectedAudioDevice(newDeviceId));

			store.dispatch(meActions.setAudioInProgress(true));

			const deviceId = await this._getAudioDeviceId();
			const device = this._audioDevices[deviceId];

			if (!device)
				throw new Error('no audio devices');

			const {
				sampleRate,
				channelCount,
				volume,
				autoGainControl,
				echoCancellation,
				noiseSuppression,
				sampleSize,
				audioMuted: wasAudioMuted
			} = store.getState().settings;

			if (
				(restart && this._micProducer) ||
				start
			)
			{
				this.disconnectLocalHark();

				if (!isLocal && this._micProducer)
					await this.disableMic();

				const stream = await navigator.mediaDevices.getUserMedia(
					{
						audio : {
							deviceId : { exact: deviceId },
							sampleRate,
							channelCount,
							volume,
							autoGainControl,
							echoCancellation,
							noiseSuppression,
							sampleSize
						}
					}
				);

				([ track ] = stream.getAudioTracks());

				const { deviceId: trackDeviceId } = track.getSettings();

				store.dispatch(settingsActions.setSelectedAudioDevice(trackDeviceId));

				if (!isLocal)
				{
					this._micProducer = await this._sendTransport.produce(
						{
							track,
							codecOptions :
								{
									opusStereo          : false,
									opusDtx             : true,
									opusFec             : true,
									opusPtime           : '20',
									opusMaxPlaybackRate : 48000
								},
							appData :
								{ source: 'mic' }
						});

					const audioCodecToUse = forceAudioCodec ? forceAudioCodec : this._micProducer.rtpParameters.codecs[0].mimeType.split('/')[1];

					store.dispatch(producerActions.addProducer(
						{
							id            : this._micProducer.id,
							source        : 'mic',
							paused        : this._micProducer.paused,
							track         : this._micProducer.track,
							rtpParameters : this._micProducer.rtpParameters,
							codec         : audioCodecToUse
						}));

					this._micProducer.on('transportclose', () =>
					{
						this._micProducer = null;
					});

					this._micProducer.on('trackended', () =>
					{
						store.dispatch(requestActions.notify(
							{
								type : 'error',
								text : intl.formatMessage({
									id             : 'devices.microphoneDisconnected',
									defaultMessage : 'Microphone disconnected'
								})
							}));

						this.disableMic();
					});

					this._micProducer.volume = 0;
				}

				this.connectLocalHark(track);

				if (newDeviceId && wasAudioMuted)
				{
					this.muteMic();
				}
				else
				{
					store.dispatch(
						settingsActions.setAudioMuted(false));
				}
			}
			else if (this._micProducer)
			{
				({ track } = this._micProducer);

				await track.applyConstraints(
					{
						sampleRate,
						channelCount,
						volume,
						autoGainControl,
						echoCancellation,
						noiseSuppression,
						sampleSize
					}
				);

				if (this._harkStream != null)
				{
					const [ harkTrack ] = this._harkStream.getAudioTracks();

					harkTrack && await harkTrack.applyConstraints(
						{
							sampleRate,
							channelCount,
							volume,
							autoGainControl,
							echoCancellation,
							noiseSuppression,
							sampleSize
						}
					);
				}
			}

			await this._updateAudioDevices();
		}
		catch (error)
		{
			logger.error('updateMic() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'devices.microphoneError',
						defaultMessage : 'An error occurred while accessing your microphone'
					})
				}));

			if (track)
				track.stop();
		}

		store.dispatch(meActions.setAudioInProgress(false));
	}

	async _loadBodyPix()
	{
		const options = {
			multiplier : 0.75,
			stride     : 16,
			quantBytes : 2
		};

		this._net = await window.bodyPix.load(options);

		return this._net;
	}

	async _drawImage(video, canvas, even)
	{
		const options = {
			internalResolution    : 'medium',
			segmentationThreshold : 0.5,
			maxDetections         : 1
		};

		const segmentation = even || !this._segmentation ? (await this._net.segmentPerson(video, options)) : this._segmentation;

		this._segmentation = segmentation;

		if (canvas.width !== video.width)
		{
			canvas.width = video.width;
			canvas.height = video.height;
		}

		const ctx = canvas.getContext('2d');

		const foregroundColor = { r: 0, g: 0, b: 0, a: 255 };
		const backgroundColor = { r: 0, g: 0, b: 0, a: 0 };
		const backgroundDarkeningMask = window.bodyPix.toMask(segmentation, foregroundColor, backgroundColor, false);

		StackBlur.imageDataRGBA(backgroundDarkeningMask, 0, 0, video.width, video.height, Math.round(video.width / 26));

		if (this._imageBackground)
		{
			this._compositeFrame(ctx, backgroundDarkeningMask, video, this._imageBackground);
		}
		else
		{
			let inputCanvasCtx = this._inputCanvasCtx;

			if (!inputCanvasCtx || video.width !== this._inputCanvas.width)
			{
				this._inputCanvas = document.createElement('canvas');
				this._inputCanvas.width = video.width;
				this._inputCanvas.height = video.height;
				this._inputCanvasCtx = this._inputCanvas.getContext('2d');
				inputCanvasCtx = this._inputCanvasCtx;
			}

			inputCanvasCtx.drawImage(video, 0, 0, video.width, video.height);

			StackBlur.canvasRGB(this._inputCanvas, 0, 0, video.width, video.height, 12);
			this._compositeFrame(ctx, backgroundDarkeningMask, video, this._inputCanvas);
		}
	}

	async _compositeFrame(ctx, backgroundDarkeningMask, video, image)
	{
		if (!backgroundDarkeningMask) return;
		ctx.globalCompositeOperation = 'destination-over';
		ctx.putImageData(backgroundDarkeningMask, 0, 0);
		ctx.globalCompositeOperation = 'source-in';
		ctx.drawImage(video, 0, 0, video.width, video.height);
		ctx.globalCompositeOperation = 'destination-over';
		ctx.drawImage(image, 0, 0, video.width, video.height);
	}

	_getCanvasTrack(stream, track, frameRate)
	{
		const canvasElement = document.createElement('canvas');
		const width = track.getSettings().width;
		const height = track.getSettings().height;

		canvasElement.width = width;
		canvasElement.height = height;

		const canvasVideoElement = document.createElement('video');

		canvasVideoElement.setAttribute('autoplay', 1);
		canvasVideoElement.setAttribute('muted', 1);
		canvasVideoElement.setAttribute('playsinline', 1);
		canvasVideoElement.width = width;
		canvasVideoElement.height = height;
		canvasVideoElement.srcObject = stream;
		canvasVideoElement.play();

		clearInterval(this._canvasInterval);

		const [ canvasTrack ] = canvasElement.captureStream().getVideoTracks();

		canvasVideoElement.addEventListener('loadeddata', () =>
		{
			let even = 0;

			this._canvasInterval = window.setInterval(() =>
			{
				if (even === 4)
					even = 0;
				this._drawImage(canvasVideoElement, canvasElement, even === 0);
				even++;
			}, 1000 / frameRate);
		});

		return canvasTrack;
	}

	async restartIce(transport, ice, delay)
	{
		logger.error('restartIce(%o, %o, %d)');

		if (!transport)
		{
			logger.error('restartIce(): missing invalid transport object');

			return;
		}

		if (!ice)
		{
			logger.error('restartIce(): missing invalid ice object');

			return;
		}

		clearTimeout(ice.timer);
		ice.timer = setTimeout(async () =>
		{
			try
			{
				if (ice.restarting)
					return;

				ice.restarting = true;

				const iceParameters = await this.sendRequest(
					'restartIce',
					{ transportId: transport.id });

				await transport.restartIce({ iceParameters });

				ice.restarting = false;
				logger.debug('Ice restarted for transport %s', transport.id);
			}
			catch (error)
			{
				logger.error('restartIce() | failed:%o', error);

				ice.restarting = false;
				ice.timer = setTimeout(() =>
				{
					this.restartIce(transport, ice, delay * 2);
				}, delay);
			}
		});
	}

	async updateWebcam({
		start = false,
		restart = false,
		isLocal = false,
		newDeviceId = null,
		newResolution = null,
		newFrameRate = null,
		newVideoCodec = null,
		newZoom = null
	} = {})
	{
		logger.debug(
			'updateWebcam() [start:"%s", restart:"%s", newDeviceId:"%s", newResolution:"%s", newFrameRate:"%s"]',
			start,
			restart,
			newDeviceId,
			newResolution,
			newFrameRate,
			newZoom
		);

		let track;

		try
		{
			if (!isLocal && !this._mediasoupDevice.canProduce('video'))
				throw new Error('cannot produce video');

			if (!isLocal && newDeviceId && !restart)
				throw new Error('changing device requires restart');

			if (newDeviceId)
				store.dispatch(settingsActions.setSelectedWebcamDevice(newDeviceId));

			if (newResolution)
				store.dispatch(settingsActions.setVideoResolution(newResolution));

			if (newFrameRate)
				store.dispatch(settingsActions.setVideoFrameRate(newFrameRate));

			if (newVideoCodec)
				store.dispatch(settingsActions.setVideoCodec(newVideoCodec));

			if (newZoom)
				store.dispatch(settingsActions.setCurrentZoomLevel(newZoom));

			store.dispatch(meActions.setWebcamInProgress(true));

			const deviceId = await this._getWebcamDeviceId();
			const device = this._webcams[deviceId];

			if (!device)
				throw new Error('no webcam devices');

			const {
				resolution,
				frameRate,
				videoCodec,
				currentZoomLevel
			} = store.getState().settings;

			let zoomConstraints = { zoom: { ideal: true } };

			if (newZoom)
			{
				zoomConstraints = {
					zoom     : { ideal: true },
					advanced : [
						{
							zoom : currentZoomLevel
						}
					]
				};
			}

			if (newDeviceId || restart)
			{
				zoomConstraints = undefined;
				store.dispatch(settingsActions.setCurrentZoomLevel(null));
				store.dispatch(settingsActions.setCameraZoomCapabilities(null));
			}

			if (
				(restart && this._webcamProducer) ||
				start
			)
			{

				if (this._webcamProducer)
					await this.disableWebcam();

				const stream = await navigator.mediaDevices.getUserMedia(
					{
						video :
						{
							deviceId : { exact: deviceId },
							...VIDEO_CONSTRAINTS[resolution],
							frameRate
						},
						...zoomConstraints
					});

				([ track ] = stream.getVideoTracks());

				const { deviceId: trackDeviceId, zoom, width, height } = track.getSettings();

				store.dispatch(settingsActions.setSelectedWebcamDevice(trackDeviceId));

				if (!zoom)
				{
					store.dispatch(settingsActions.setCameraZoomCapabilities(null));
				}
				else
				{
					const capabilities = track.getCapabilities();
					const cameraZoomCapabilities = {
						min   : capabilities.zoom.min,
						max   : capabilities.zoom.max,
						step  : capabilities.zoom.step,
						value : currentZoomLevel ? currentZoomLevel : zoom
					};

					store.dispatch(settingsActions.setCameraZoomCapabilities(cameraZoomCapabilities));
				}

				let trackToUse = track;

				if (store.getState().settings.imageBackground)
				{
					this._imageBackground = await this._loadImage('/images/video-background.png');
				}
				else
				{
					this._imageBackground = null;
				}

				if (store.getState().settings.blurBackground || store.getState().settings.imageBackground)
				{
					if (!this._net)
						this._net = await this._loadBodyPix();
					trackToUse = this._getCanvasTrack(stream, track, frameRate);
				}
				else
				{
					clearInterval(this._canvasInterval);
				}

				if (!isLocal)
				{
					if (this._useSimulcast)
					{
						const videoCodecToUse = this._mediasoupDevice
							.rtpCapabilities
							.codecs
							.find((c) =>
							{
								if (videoCodec)
								{
									return c.mimeType.toLowerCase() === videoCodec.toLowerCase();
								}

								return c.kind === 'video';
							});

						const encodings = this._getEncodings(width, height, videoCodecToUse);
						const resolutionScalings = getResolutionScalings(encodings);

						this._webcamProducer = await this._sendTransport.produce(
							{
								track        : trackToUse,
								encodings,
								codec        : videoCodecToUse,
								codecOptions :
									{
										videoGoogleStartBitrate : 1000
									},
								appData :
									{
										source : 'webcam',
										width,
										height,
										resolutionScalings
									}
							});
					}
					else
					{
						this._webcamProducer = await this._sendTransport.produce({
							track   : trackToUse,
							appData :
								{
									source : 'webcam',
									width,
									height
								}
						});
					}

					store.dispatch(producerActions.addProducer(
						{
							id            : this._webcamProducer.id,
							source        : 'webcam',
							paused        : this._webcamProducer.paused,
							track         : this._webcamProducer.track,
							rtpParameters : this._webcamProducer.rtpParameters,
							codec         : this._webcamProducer.rtpParameters.codecs[0].mimeType.split('/')[1]
						}));

					this._webcamProducer.on('transportclose', () =>
					{
						this._webcamProducer = null;
					});

					this._webcamProducer.on('trackended', () =>
					{
						store.dispatch(requestActions.notify(
							{
								type : 'error',
								text : intl.formatMessage({
									id             : 'devices.cameraDisconnected',
									defaultMessage : 'Camera disconnected'
								})
							}));

						this.disableWebcam();
					});

					store.dispatch(settingsActions.setVideoMuted(false));
				}
			}
			else if (this._webcamProducer)
			{
				({ track } = this._webcamProducer);

				await track.applyConstraints(
					{
						...VIDEO_CONSTRAINTS[resolution],
						frameRate
					}
				);

				if (zoomConstraints)
				{
					await track.applyConstraints(
						{
							...zoomConstraints
						}
					);
				}

				// Also change resolution of extra video producers
				for (const producer of this._extraVideoProducers.values())
				{
					({ track } = producer);

					await track.applyConstraints(
						{
							...VIDEO_CONSTRAINTS[resolution],
							frameRate
						}
					);
				}
			}

			await this._updateWebcams();
		}
		catch (error)
		{
			logger.error('updateWebcam() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'devices.cameraError',
						defaultMessage : 'An error occurred while accessing your camera'
					})
				}));

			store.dispatch(settingsActions.setCameraZoomCapabilities(null));
			store.dispatch(settingsActions.setCurrentZoomLevel(null));

			if (track)
				track.stop();

			store.dispatch(roomActions.setHelpOpen(true));
		}

		store.dispatch(
			meActions.setWebcamInProgress(false));
	}

	addForcedHighlightedPeerId(peerId, prepend = false, reset = false)
	{
		this._spotlights.addAlwaysVisibleSpotlightPeer(peerId, prepend, reset);

		store.dispatch(
			roomActions.addAlwaysVisiblePeer(peerId, prepend, reset)
		);
	}

	removeForcedHighlightedPeerId(peerId)
	{
		this._spotlights.removeAlwaysVisibleSpotlightPeer(peerId);

		store.dispatch(
			roomActions.removeAlwaysVisiblePeer(peerId)
		);
	}

	clearForcedHighlightedPeerId()
	{
		this._spotlights.clearAlwaysVisibleSpotlights();

		store.dispatch(
			roomActions.setAlwaysVisibleSpotlights([]));
	}

	addForcedSecondHighlightedPeerId(peerId)
	{
		this._spotlights.addAlwaysVisibleSpotlightPeer(peerId);

		store.dispatch(
			roomActions.setForcedSecondHighlightedPeerId(peerId));
	}

	addSelectedPeer(peerId)
	{
		logger.debug('addSelectedPeer() [peerId:"%s"]', peerId);

		this._spotlights.addPeerToSpotlight(peerId);

		store.dispatch(
			roomActions.addSelectedPeer(peerId));
	}

	removeSelectedPeer(peerId)
	{
		logger.debug('removeSelectedPeer() [peerId:"%s"]', peerId);

		this._spotlights.removePeerSpotlight(peerId);

		store.dispatch(
			roomActions.removeSelectedPeer(peerId));
	}

	async promoteAllLobbyPeers()
	{
		logger.debug('promoteAllLobbyPeers()');

		store.dispatch(
			roomActions.setLobbyPeersPromotionInProgress(true));

		try
		{
			await this.sendRequest('promoteAllPeers');
		}
		catch (error)
		{
			logger.error('promoteAllLobbyPeers() [error:"%o"]', error);
		}

		store.dispatch(
			roomActions.setLobbyPeersPromotionInProgress(false));
	}

	async promoteLobbyPeer(peerId)
	{
		logger.debug('promoteLobbyPeer() [peerId:"%s"]', peerId);

		store.dispatch(
			lobbyPeerActions.setLobbyPeerPromotionInProgress(peerId, true));

		try
		{
			await this.sendRequest('promotePeer', { peerId });
		}
		catch (error)
		{
			logger.error('promoteLobbyPeer() [error:"%o"]', error);
		}

		store.dispatch(
			lobbyPeerActions.setLobbyPeerPromotionInProgress(peerId, false));
	}

	async setAllowSelfMoving(allowSelfMoving)
	{
		logger.debug('setAllowSelfMoving() [allowSelfMoving: "%s"]', allowSelfMoving);

		try
		{
			await this.sendRequest('moderator:setAllowSelfMoving', { allowSelfMoving });
		}
		catch (error)
		{
			logger.error('setAllowSelfMoving() [error:"%o"]', error);
		}
	}

	async moveToRoom({ roomId, subRoomId, peerId })
	{
		let actualPeerId = peerId;

		if (!actualPeerId)
		{
			actualPeerId = this._peerId;
			logger.warn('moveToRoom() peerId changed to current peer id');
		}

		let actualRoomId = roomId;

		if (!actualRoomId)
		{
			actualRoomId = this._roomId;
			logger.warn('moveToRoom() subRoomId changed to this._roomId');
		}

		if (roomId === subRoomId)
		{
			subRoomId = null;
		}

		try
		{
			await this.sendRequest('moveToSubRoom', {
				subRoomId,
				peerId : actualPeerId,
				roomId : actualRoomId
			});
		}
		catch (error)
		{
			logger.error('moveToSubRoom() [error:"%o"]', error);
		}
	}

	async clearChat()
	{
		logger.debug('clearChat()');

		store.dispatch(
			roomActions.setClearChatInProgress(true));

		try
		{
			await this.sendRequest('moderator:clearChat');

			store.dispatch(chatActions.clearChat());
		}
		catch (error)
		{
			logger.error('clearChat() [error:"%o"]', error);
		}

		store.dispatch(
			roomActions.setClearChatInProgress(false));
	}

	async clearFileSharing()
	{
		logger.debug('clearFileSharing()');

		store.dispatch(
			roomActions.setClearFileSharingInProgress(true));

		try
		{
			await this.sendRequest('moderator:clearFileSharing');

			store.dispatch(fileActions.clearFiles());
		}
		catch (error)
		{
			logger.error('clearFileSharing() [error:"%o"]', error);
		}

		store.dispatch(
			roomActions.setClearFileSharingInProgress(false));
	}

	async givePeerRole(peerId, roleId)
	{
		logger.debug('givePeerRole() [peerId:"%s", roleId:"%s"]', peerId, roleId);

		store.dispatch(
			peerActions.setPeerModifyRolesInProgress(peerId, true));

		try
		{
			await this.sendRequest('moderator:giveRole', { peerId, roleId });
		}
		catch (error)
		{
			logger.error('givePeerRole() [error:"%o"]', error);
		}

		store.dispatch(
			peerActions.setPeerModifyRolesInProgress(peerId, false));
	}

	async removePeerRole(peerId, roleId)
	{
		logger.debug('removePeerRole() [peerId:"%s", roleId:"%s"]', peerId, roleId);

		store.dispatch(
			peerActions.setPeerModifyRolesInProgress(peerId, true));

		try
		{
			await this.sendRequest('moderator:removeRole', { peerId, roleId });
		}
		catch (error)
		{
			logger.error('removePeerRole() [error:"%o"]', error);
		}

		store.dispatch(
			peerActions.setPeerModifyRolesInProgress(peerId, false));
	}

	async goToUrl(peerId, urlToGoTo)
	{
		logger.debug('goToUrl() [peerId:"%s"] [url:"%s"]', peerId, urlToGoTo);

		try
		{
			await this.sendRequest('moderator:goToUrl', { peerId, urlToGoTo });
		}
		catch (error)
		{
			logger.error('goToUrl() [error:"%o"]', error);
		}
	}

	async goToUrlAll(urlToGoTo)
	{
		logger.debug('goToUrlAll() [url:"%s"]', urlToGoTo);

		try
		{
			await this.sendRequest('moderator:goToUrlAll', { urlToGoTo });
		}
		catch (error)
		{
			logger.error('goToUrlAll() [error:"%o"]', error);
		}
	}

	async playGame(chapter, video)
	{
		logger.debug('playGame()');

		try
		{
			await this.sendRequest('playGame', { chapter, video });
		}
		catch (error)
		{
			logger.error('playGame() [error:"%o"]', error);
		}
	}

	async extendPlayTime()
	{
		logger.debug('extendPlayTime()');

		try
		{
			await this.sendRequest('extendPlayTime');
		}
		catch (error)
		{
			logger.error('extendPlayTime() [error:"%o"]', error);
		}
	}

	async reducePlayTime()
	{
		logger.debug('reducePlayTime()');

		try
		{
			await this.sendRequest('reducePlayTime');
		}
		catch (error)
		{
			logger.error('reducePlayTime() [error:"%o"]', error);
		}
	}

	async goToUrlAllInSubrooms(urlToGoTo)
	{
		logger.debug('goToUrlAllInSubrooms() [url:"%s"]', urlToGoTo);

		try
		{
			await this.sendRequest('moderator:goToUrlAllInSubrooms', { urlToGoTo });
		}
		catch (error)
		{
			logger.error('goToUrlAllInSubrooms() [error:"%o"]', error);
		}
	}

	async setIFrameURL(IFrameURL)
	{
		logger.debug('setIFrameURL() [url:"%s"]', IFrameURL);

		try
		{
			await this.sendRequest('moderator:setIFrameURL', { IFrameURL });
		}
		catch (error)
		{
			logger.error('setIFrameURL() [error:"%o"]', error);
		}
	}

	async playEffect(effect)
	{
		logger.debug('playEffect(%s)', effect);

		try
		{
			await this.sendRequest('soundFx:play', { effect });
		}
		catch (error)
		{
			logger.error('playEffect() [error:"%o"]', error);
		}
	}

	async colorHighlight(peerId)
	{
		logger.debug('colorHighlight(%s)', peerId);

		function randomNum(min, max)
		{
			return Math.floor(Math.random() * (max - min + 1)) + min;
		}

		const colorOffset = randomNum(20, 340);

		try
		{
			await this.sendRequest('colorSplashPeer', { peerId, colorOffset });
		}
		catch (error)
		{
			logger.error('colorHighlight() [error:"%o"]', error);
		}
	}

	async sendPoll(id, question, answers, points, correctAnswer, status)
	{
		logger.debug('sendPoll() [question:"%s"]', question);

		try
		{
			const userId = store.getState().settings.userId;

			await this.sendRequest('sendPoll', { id, question, answers, points, correctAnswer, status, userId });
		}
		catch (error)
		{
			logger.error('sendPoll() [error:"%o"]', error);
		}
	}

	async deletePoll(id)
	{
		logger.debug('deletePoll() [id:"%s"]', id);

		try
		{
			const userId = store.getState().settings.userId;

			await this.sendRequest('deletePoll', { id, userId });
		}
		catch (error)
		{
			logger.error('deletePoll() [error:"%o"]', error);
		}
	}

	async movePollToTop(id)
	{
		logger.debug('movePollToTop() [id:"%s"]', id);

		try
		{
			const userId = store.getState().settings.userId;

			await this.sendRequest('movePollToTop', { id, userId });
		}
		catch (error)
		{
			logger.error('movePollToTop() [error:"%o"]', error);
		}
	}

	async setPollStatus(id, status)
	{
		logger.debug('setPollStatus() [id:"%s"]', id);

		try
		{
			const userId = store.getState().settings.userId;

			await this.sendRequest('setPollStatus', { id, status, userId });
		}
		catch (error)
		{
			logger.error('setPollStatus() [error:"%o"]', error);
		}
	}

	async sendPollAnswer(pollId, answerId)
	{
		logger.debug('sendPollAnswer() [answerId:"%s"]', answerId);

		try
		{
			const { userId, displayName } = store.getState().settings;

			await this.sendRequest('sendPollAnswer', { pollId, answerId, userId, displayName });
		}
		catch (error)
		{
			logger.error('sendPollAnswer() [error:"%o"]', error);
		}
	}

	async clearPolls()
	{
		logger.debug('clearPolls()');

		try
		{
			await this.sendRequest('clearPolls');
		}
		catch (error)
		{
			logger.error('clearPolls() [error:"%o"]', error);
		}
	}

	async sendDrawingTogether(drawingTogether)
	{
		logger.debug('sendDrawingTogether() [url:"%s"]', drawingTogether);

		try
		{
			await this.sendRequest('moderator:setDrawingTogether', { drawingTogether });
		}
		catch (error)
		{
			logger.error('sendDrawingTogether() [error:"%o"]', error);
		}
	}

	async sendWritingTogether(writingTogether)
	{
		logger.debug('sendWritingTogether() [url:"%s"]', writingTogether);

		try
		{
			await this.sendRequest('moderator:setWritingTogether', { writingTogether });
		}
		catch (error)
		{
			logger.error('sendWritingTogether() [error:"%o"]', error);
		}
	}

	async closeSelfHighlight()
	{
		logger.debug('closeSelfHighlight()');

		try
		{
			await this.sendRequest('peer:closeSelfHighlight');
		}
		catch (error)
		{
			logger.error('closeSelfHighlight() [error:"%o"]', error);
		}
	}

	async setFriendlyRoomName(friendlyRoomName)
	{
		logger.debug('setFriendlyRoomName() [name:"%s"]', friendlyRoomName);

		try
		{
			const { displayName } = store.getState().settings;

			await this.sendRequest('setFriendlyRoomName', { friendlyRoomName, displayName });
		}
		catch (error)
		{
			logger.error('setFriendlyRoomName() [error:"%o"]', error);
		}
	}

	async setFriendlyRoomDescription(friendlyRoomDescription)
	{
		logger.debug('setFriendlyRoomDescription() [description:"%s"]', friendlyRoomDescription);

		try
		{
			const { displayName } = store.getState().settings;

			await this.sendRequest('setFriendlyRoomDescription', { friendlyRoomDescription, displayName });
		}
		catch (error)
		{
			logger.error('setFriendlyRoomDescription() [error:"%o"]', error);
		}
	}

	async setRoomBackgroundImage(image)
	{
		logger.debug('setRoomBackgroundImage()');

		try
		{
			await this.sendRequest('setRoomBackgroundImage', { image });
		}
		catch (error)
		{
			logger.error('setRoomBackgroundImage() [error:"%o"]', error);
		}
	}

	async togglePersistRoom({ roomId, subRoomId })
	{
		logger.debug('togglePersistRoom()');

		try
		{
			await this.sendRequest('moderator:togglePersistRoom', { roomId, subRoomId });
		}
		catch (error)
		{
			logger.error('togglePersistRoom() [error:"%o"]', error);
		}
	}

	async voteNext()
	{
		logger.debug('voteNext()');

		try
		{
			await this.sendRequest('voteNext');
		}
		catch (error)
		{
			logger.error('voteNext() [error:"%o"]', error);
		}
	}

	async modifyCountdownTimer(timestamp)
	{
		logger.debug('modifyCountdownTimer() [timestamp:"%s"]', timestamp);

		try
		{
			await this.sendRequest('modifyCountdownTimer', { timestamp });
		}
		catch (error)
		{
			logger.error('modifyCountdownTimer() [error:"%o"]', error);
		}
	}

	async modifyCountdownTimerPeer(peerId, timestamp)
	{
		logger.debug('modifyCountdownTimerPeer() [timestamp:"%s"]', timestamp);

		try
		{
			await this.sendRequest('moderator:modifyCountdownTimerPeer', { peerId, timestamp });
		}
		catch (error)
		{
			logger.error('modifyCountdownTimerPeer() [error:"%o"]', error);
		}
	}

	async modifyCountdownTimerSubroom(subRoomId, timestamp)
	{
		logger.debug('modifyCountdownTimerSubroom() [timestamp:"%s"]', timestamp);

		try
		{
			await this.sendRequest('moderator:modifyCountdownTimerSubroom', { subRoomId, timestamp });
		}
		catch (error)
		{
			logger.error('modifyCountdownTimerSubroom() [error:"%o"]', error);
		}
	}

	async modifyCountdownTimerSubrooms(timestamp)
	{
		logger.debug('modifyCountdownTimerSubrooms() [timestamp:"%s"]', timestamp);

		try
		{
			await this.sendRequest('moderator:modifyCountdownTimerSubrooms', { timestamp });
		}
		catch (error)
		{
			logger.error('modifyCountdownTimerSubrooms() [error:"%o"]', error);
		}
	}

	async kickPeer(peerId)
	{
		logger.debug('kickPeer() [peerId:"%s"]', peerId);

		store.dispatch(
			peerActions.setPeerKickInProgress(peerId, true));

		try
		{
			await this.sendRequest('moderator:kickPeer', { peerId });
		}
		catch (error)
		{
			logger.error('kickPeer() [error:"%o"]', error);
		}

		store.dispatch(
			peerActions.setPeerKickInProgress(peerId, false));
	}

	async reportUser(peerId)
	{
		logger.debug('reportUser() [peerId:"%s"]', peerId);

		try
		{
			await this.sendRequest('reportUser', { peerId });
		}
		catch (error)
		{
			logger.error('reportUser() [error:"%o"]', error);
		}
	}

	async mutePeer(peerId)
	{
		logger.debug('mutePeer() [peerId:"%s"]', peerId);

		store.dispatch(
			peerActions.setMutePeerInProgress(peerId, true));

		try
		{
			await this.sendRequest('mute', { peerId });
		}
		catch (error)
		{
			logger.error('mutePeer() [error:"%o"]', error);
		}

		store.dispatch(
			peerActions.setMutePeerInProgress(peerId, false));
	}

	async unmutePeer(peerId)
	{
		logger.debug('unmutePeer() [peerId:"%s"]', peerId);

		store.dispatch(
			peerActions.setMutePeerInProgress(peerId, true));

		try
		{
			await this.sendRequest('moderator:unmute', { peerId });
		}
		catch (error)
		{
			logger.error('unmutePeer() [error:"%o"]', error);
		}

		store.dispatch(
			peerActions.setMutePeerInProgress(peerId, false));
	}

	async startPeerVideo(peerId)
	{
		logger.debug('startPeerVideo() [peerId:"%s"]', peerId);

		store.dispatch(
			peerActions.setStopPeerVideoInProgress(peerId, true));

		try
		{
			await this.sendRequest('moderator:startVideo', { peerId });
		}
		catch (error)
		{
			logger.error('startPeerVideo() [error:"%o"]', error);
		}

		store.dispatch(
			peerActions.setStopPeerVideoInProgress(peerId, false));
	}

	async stopPeerVideo(peerId)
	{
		logger.debug('stopPeerVideo() [peerId:"%s"]', peerId);

		store.dispatch(
			peerActions.setStopPeerVideoInProgress(peerId, true));

		try
		{
			await this.sendRequest('moderator:stopVideo', { peerId });
		}
		catch (error)
		{
			logger.error('stopPeerVideo() [error:"%o"]', error);
		}

		store.dispatch(
			peerActions.setStopPeerVideoInProgress(peerId, false));
	}

	async stopPeerScreenSharing(peerId)
	{
		logger.debug('stopPeerScreenSharing() [peerId:"%s"]', peerId);

		store.dispatch(
			peerActions.setStopPeerScreenSharingInProgress(peerId, true));

		try
		{
			await this.sendRequest('moderator:stopScreenSharing', { peerId });
		}
		catch (error)
		{
			logger.error('stopPeerScreenSharing() [error:"%o"]', error);
		}

		store.dispatch(
			peerActions.setStopPeerScreenSharingInProgress(peerId, false));
	}

	async muteAllPeers()
	{
		logger.debug('muteAllPeers()');

		store.dispatch(
			roomActions.setMuteAllInProgress(true));

		try
		{
			await this.sendRequest('moderator:muteAll');
		}
		catch (error)
		{
			logger.error('muteAllPeers() [error:"%o"]', error);
		}

		store.dispatch(
			roomActions.setMuteAllInProgress(false));
	}

	async stopAllPeerVideo()
	{
		logger.debug('stopAllPeerVideo()');

		store.dispatch(
			roomActions.setStopAllVideoInProgress(true));

		try
		{
			await this.sendRequest('moderator:stopAllVideo');
		}
		catch (error)
		{
			logger.error('stopAllPeerVideo() [error:"%o"]', error);
		}

		store.dispatch(
			roomActions.setStopAllVideoInProgress(false));
	}

	async stopAllPeerScreenSharing()
	{
		logger.debug('stopAllPeerScreenSharing()');

		store.dispatch(
			roomActions.setStopAllScreenSharingInProgress(true));

		try
		{
			await this.sendRequest('moderator:stopAllScreenSharing');
		}
		catch (error)
		{
			logger.error('stopAllPeerScreenSharing() [error:"%o"]', error);
		}

		store.dispatch(
			roomActions.setStopAllScreenSharingInProgress(false));
	}

	async closeMeeting()
	{
		logger.debug('closeMeeting()');

		store.dispatch(
			roomActions.setCloseMeetingInProgress(true));

		try
		{
			await this.sendRequest('moderator:closeMeeting');
		}
		catch (error)
		{
			logger.error('closeMeeting() [error:"%o"]', error);
		}

		store.dispatch(
			roomActions.setCloseMeetingInProgress(false));
	}

	// type: mic/webcam/screen
	// mute: true/false
	async modifyPeerConsumer(peerId, type, mute)
	{
		logger.debug(
			'modifyPeerConsumer() [peerId:"%s", type:"%s"]',
			peerId,
			type
		);

		if (type.startsWith('broadcast'))
		{
			if (type === 'broadcast_audio')
			{
				store.dispatch(
					broadcasterActions.setBroadcasterAudioInProgress(peerId, true));
			}
			else if (type === 'broadcast_video')
			{
				store.dispatch(
					broadcasterActions.setBroadcasterVideoInProgress(peerId, true));
			}
		}
		else
		if (type === 'mic')
			store.dispatch(
				peerActions.setPeerAudioInProgress(peerId, true));
		else if (type === 'webcam')
			store.dispatch(
				peerActions.setPeerVideoInProgress(peerId, true));
		else if (type === 'screen')
			store.dispatch(
				peerActions.setPeerScreenInProgress(peerId, true));

		try
		{
			for (const consumer of this._consumers.values())
			{
				if (consumer.appData.peerId === peerId && consumer.appData.source === type)
				{
					if (mute)
						await this._pauseConsumer(consumer);
					else
						await this._resumeConsumer(consumer);
				}
			}
		}
		catch (error)
		{
			logger.error('modifyPeerConsumer() [error:"%o"]', error);
		}

		if (type.startsWith('broadcast_'))
		{
			if (type === 'broadcast_audio')
			{
				store.dispatch(
					broadcasterActions.setBroadcasterAudioInProgress(peerId, false));
			}
			else if (type === 'broadcast_video')
			{
				store.dispatch(
					broadcasterActions.setBroadcasterVideoInProgress(peerId, false));
			}
		}
		else
		if (type === 'mic')
			store.dispatch(
				peerActions.setPeerAudioInProgress(peerId, false));
		else if (type === 'webcam')
			store.dispatch(
				peerActions.setPeerVideoInProgress(peerId, false));
		else if (type === 'screen')
			store.dispatch(
				peerActions.setPeerScreenInProgress(peerId, false));
	}

	async _pauseConsumer(consumer)
	{
		logger.debug('_pauseConsumer() [consumer:"%o"]', consumer);

		if (consumer.paused || consumer.closed || isView)
			return;

		try
		{
			await this.sendRequest('pauseConsumer', { consumerId: consumer.id });

			consumer.pause();

			store.dispatch(
				consumerActions.setConsumerPaused(consumer.id, 'local'));
		}
		catch (error)
		{
			logger.error('_pauseConsumer() [consumerId: %s; error:"%o"]', consumer.id, error);
			if (error.notFoundInMediasoupError)
			{
				this._closeConsumer(consumer.id);
			}
		}
	}

	async _resumeConsumer(consumer, { initial = false } = {})
	{
		logger.debug('_resumeConsumer() [consumer:"%o"]', consumer);

		if ((!initial && !consumer.paused) || consumer.closed)
			return;

		try
		{
			consumer.resume();

			await this.sendRequest('resumeConsumer', { consumerId: consumer.id });

			store.dispatch(
				consumerActions.setConsumerResumed(consumer.id, 'local'));
		}
		catch (error)
		{
			logger.error('_resumeConsumer() [consumerId: %s; error:"%o"]', consumer.id, error);
			if (error.notFoundInMediasoupError)
			{
				this._closeConsumer(consumer.id);
			}
		}
	}

	async _startConsumer(consumer)
	{
		return this._resumeConsumer(consumer, { initial: true });
	}

	async lowerPeerHand(peerId)
	{
		logger.debug('lowerPeerHand() [peerId:"%s"]', peerId);

		store.dispatch(
			peerActions.setPeerRaisedHandInProgress(peerId, true));

		try
		{
			await this.sendRequest('moderator:lowerHand', { peerId });
		}
		catch (error)
		{
			logger.error('lowerPeerHand() [error:"%o"]', error);
		}

		store.dispatch(
			peerActions.setPeerRaisedHandInProgress(peerId, false));
	}

	async setRaisedHand(raisedHand)
	{
		logger.debug('setRaisedHand: ', raisedHand);

		store.dispatch(
			meActions.setRaisedHandInProgress(true));

		try
		{
			await this.sendRequest('raisedHand', { raisedHand });

			store.dispatch(
				meActions.setRaisedHand(raisedHand));
		}
		catch (error)
		{
			logger.error('setRaisedHand() [error:"%o"]', error);

			// We need to refresh the component for it to render changed state
			store.dispatch(meActions.setRaisedHand(!raisedHand));
		}

		store.dispatch(
			meActions.setRaisedHandInProgress(false));
	}

	async sendLike()
	{
		logger.debug('sendLike');

		try
		{
			await this.sendRequest('sendLike');
		}
		catch (error)
		{
			logger.error('sendLike() [error:"%o"]', error);
		}
	}

	async setLobbyMessage(message)
	{
		logger.debug('setLobbyMessage() [message:"%s"]', message);

		try
		{
			await this.sendRequest('lobby:setLobbyMessage', { message });
		}
		catch (error)
		{
			logger.error('setLobbyMessage() [error:"%o"]', error);
		}
	}

	async setSlackIntegration(slackWebHookUrl)
	{
		logger.debug('setSlackIntegration() [slackWebHookUrl:"%s"]', slackWebHookUrl);

		try
		{
			await this.sendRequest('moderator:setSlackIntegration', { slackWebHookUrl });
		}
		catch (error)
		{
			logger.error('sendLike() [error:"%o"]', error);
		}
	}

	async setMaxSendingSpatialLayer(spatialLayer)
	{
		logger.debug('setMaxSendingSpatialLayer() [spatialLayer:"%s"]', spatialLayer);

		try
		{
			if (this._webcamProducer)
				await this._webcamProducer.setMaxSpatialLayer(spatialLayer);
			if (this._screenSharingProducer)
				await this._screenSharingProducer.setMaxSpatialLayer(spatialLayer);
		}
		catch (error)
		{
			logger.error('setMaxSendingSpatialLayer() [error:"%o"]', error);
		}
	}

	async setConsumerPreferredLayers(consumerId, spatialLayer, temporalLayer)
	{
		logger.debug(
			'setConsumerPreferredLayers() [consumerId:"%s", spatialLayer:"%s", temporalLayer:"%s"]',
			consumerId, spatialLayer, temporalLayer);

		try
		{
			await this.sendRequest(
				'setConsumerPreferredLayers',
				{ consumerId, spatialLayer, temporalLayer }
			);

			store.dispatch(consumerActions.setConsumerPreferredLayers(
				consumerId, spatialLayer, temporalLayer));
		}
		catch (error)
		{
			logger.error('setConsumerPreferredLayers() [consumerId: %s; error:"%o"]', consumerId, error);
			if (error.notFoundInMediasoupError)
			{
				this._closeConsumer(consumerId);
			}
		}
	}

	async setConsumerPriority(consumerId, priority)
	{
		logger.debug(
			'setConsumerPriority() [consumerId:"%s", priority:%d]',
			consumerId, priority);

		try
		{
			await this.sendRequest('setConsumerPriority', { consumerId, priority });

			store.dispatch(consumerActions.setConsumerPriority(consumerId, priority));
		}
		catch (error)
		{
			logger.error('setConsumerPriority() [consumerId: %s; error:"%o"]', consumerId, error);
			if (error.notFoundInMediasoupError)
			{
				this._closeConsumer(consumerId);
			}
		}
	}

	async requestConsumerKeyFrame(consumerId)
	{
		logger.debug('requestConsumerKeyFrame() [consumerId:"%s"]', consumerId);

		try
		{
			await this.sendRequest('requestConsumerKeyFrame', { consumerId });
		}
		catch (error)
		{
			logger.error('requestConsumerKeyFrame() [consumerId: %s; error:"%o"]', consumerId, error);
			if (error.notFoundInMediasoupError)
			{
				this._closeConsumer(consumerId);
			}
		}
	}

	async _loadDynamicImports()
	{
		({ default: createTorrent } = await import(

			/* webpackPrefetch: true */
			/* webpackChunkName: "createtorrent" */
			'create-torrent'
		));

		({ default: WebTorrent } = await import(

			/* webpackPrefetch: true */
			/* webpackChunkName: "webtorrent" */
			'webtorrent'
		));

		({ default: saveAs } = await import(

			/* webpackPrefetch: true */
			/* webpackChunkName: "file-saver" */
			'file-saver'
		));

		({ default: ScreenShare } = await import(

			/* webpackPrefetch: true */
			/* webpackChunkName: "screensharing" */
			'./ScreenShare'
		));

		mediasoupClient = await import(

			/* webpackPrefetch: true */
			/* webpackChunkName: "mediasoup" */
			'mediasoup-client'
		);

		({ default: io } = await import(

			/* webpackPrefetch: true */
			/* webpackChunkName: "socket.io" */
			'socket.io-client'
		));

		this._loadedDynamicImports = true;
	}

	async moveBackToMainRoom()
	{
		const { videoMuted, audioMuted } = store.getState().settings;

		this.disconnect();
		// eslint-disable-next-line no-restricted-globals
		history.pushState({}, null, `/${this._roomId}`);
		await this.join({ roomId: this._roomId, joinVideo: videoMuted === false, joinAudio: audioMuted === false, overrideLock: true });
	}

	async setAutoDistributeStrategy(distributionStrategyId)
	{
		logger.debug('setAutoDistributeStrategy(%s)', distributionStrategyId);

		try
		{
			await this.sendRequest('setAutoDistributeStrategy', { distributionStrategyId });
		}
		catch (error)
		{
			logger.error('setAutoDistributeStrategy(%s) [error:"%o"]', distributionStrategyId, error);
		}
	}

	async autoDistributePeersNow()
	{
		logger.debug('autoDistributePeersNow()');

		try
		{
			await this.sendRequest('moveAllPeersToSubRooms');
		}
		catch (error)
		{
			logger.error('autoDistributePeersNow() [error:"%o"]', error);
		}
	}

	async enableAutoDistributePeers()
	{
		logger.debug('enableAutoDistributePeers()');

		try
		{
			await this.sendRequest('enableAutoDistribution');
		}
		catch (error)
		{

			logger.error('enableAutoDistributePeers() [error:"%o"]', error);
		}
	}

	async disableAutoDistributePeers()
	{
		logger.debug('disableAutoDistributePeers()');

		try
		{
			await this.sendRequest('disableAutoDistribution');
		}
		catch (error)
		{

			logger.error('disableAutoDistributePeers() [error:"%o"]', error);
		}
	}

	async enableAutoDistributeCreateRooms()
	{
		logger.debug('enableAutoDistributeCreateRooms()');

		try
		{
			await this.sendRequest('enableAutoDistributeCreateRooms');
		}
		catch (error)
		{

			logger.error('enableAutoDistributeCreateRooms() [error:"%o"]', error);
		}
	}

	async disableAutoDistributeCreateRooms()
	{
		logger.debug('disableAutoDistributeCreateRooms()');

		try
		{
			await this.sendRequest('disableAutoDistributeCreateRooms');
		}
		catch (error)
		{

			logger.error('disableAutoDistributeCreateRooms() [error:"%o"]', error);
		}
	}

	async setAutoCodecSwitching(autoCodecSwitching)
	{
		logger.debug('setAutoCodecSwitching()');

		try
		{
			await this.sendRequest('moderator:setAutoCodecSwitching', { autoCodecSwitching });
		}
		catch (error)
		{

			logger.error('setAutoCodecSwitching() [error:"%o"]', error);
		}
	}

	async setRemotePeerResolution({ peerId, resolution })
	{
		logger.debug('setRemotePeerResolution({ peerId: %s, resolution: %s })', peerId, resolution);

		try
		{
			await this.sendRequest('moderator:setPeerResolution', { peerId, newResolution: resolution });
		}
		catch (error)
		{

			logger.error('setRemotePeerResolution() [error:"%o"]', error);
		}
	}

	async setRemotePeerVideoCodec({ peerId, videoCodec })
	{
		logger.debug('setRemotePeerVideoCodec({ peerId: %s, codec: %s })', peerId, videoCodec);

		try
		{
			await this.sendRequest('moderator:setPeerVideoCodec', { peerId, newVideoCodec: videoCodec });
		}
		catch (error)
		{

			logger.error('setRemotePeerVideoCodec() [error:"%o"]', error);
		}
	}

	async setRemotePeerAudioCodec({ peerId, audioCodec })
	{
		logger.debug('setRemotePeerAudioCodec({ peerId: %s, codec: %s })', peerId, audioCodec);

		try
		{
			await this.sendRequest('moderator:setPeerAudioCodec', { peerId, newAudioCodec: audioCodec });
		}
		catch (error)
		{

			logger.error('setRemotePeerAudioCodec() [error:"%o"]', error);
		}
	}

	async setRemotePeerFrameRate({ peerId, framerate })
	{
		logger.debug('setRemotePeerFrameRate({ peerId: %s, framerate: %s })', peerId, framerate);

		try
		{
			await this.sendRequest('moderator:setPeerFrameRate', { peerId, newFrameRate: framerate });
		}
		catch (error)
		{

			logger.error('setRemotePeerFrameRate() [error:"%o"]', error);
		}
	}

	async setAllRemotePeerResolution({ resolution })
	{
		logger.debug('setAllRemotePeerResolution({ resolution: %s })', resolution);

		try
		{
			await this.sendRequest('moderator:setAllPeerResolution', { newResolution: resolution });
		}
		catch (error)
		{

			logger.error('setAllRemotePeerResolution() [error:"%o"]', error);
		}
	}

	async setRoomMode(roomMode)
	{
		logger.debug('setRoomMode(roomMode: %s })', roomMode);

		try
		{
			await this.sendRequest('moderator:setRoomMode', { roomMode });
		}
		catch (error)
		{

			logger.error('setRoomMode() [error:"%o"]', error);
		}
	}

	async lockConsumerPreferredLayers()
	{
		logger.debug('lockConsumerPreferredLayers()');

		try
		{
			await this.sendRequest('moderator:lockConsumerPreferredLayers');
		}
		catch (error)
		{

			logger.error('lockConsumerPreferredLayers() [error:"%o"]', error);
		}
	}

	async unlockConsumerPreferredLayers()
	{
		logger.debug('unlockConsumerPreferredLayers()');

		try
		{
			await this.sendRequest('moderator:unlockConsumerPreferredLayers');
		}
		catch (error)
		{

			logger.error('unlockConsumerPreferredLayers() [error:"%o"]', error);
		}
	}

	async setAllRemotePeerLayout({ layout })
	{
		logger.debug('setAllRemotePeerLayout({ layout: %s })', layout);

		try
		{
			await this.sendRequest('setAllPeerLayout', { newLayout: layout });
		}
		catch (error)
		{

			logger.error('setAllRemotePeerLayout() [error:"%o"]', error);
		}
	}

	async setAllRemotePeerVideoCodec({ videoCodec })
	{
		logger.debug('setAllRemotePeerVideoCodec({ videoCodec: %s })', videoCodec);

		try
		{
			await this.sendRequest('moderator:setAllPeerVideoCodec', { newVideoCodec: videoCodec });
		}
		catch (error)
		{

			logger.error('setAllRemotePeerVideoCodec() [error:"%o"]', error);
		}
	}

	async setAllRemotePeerFrameRate({ framerate })
	{
		logger.debug('setAllRemotePeerFrameRate({ framerate: %s })', framerate);

		try
		{
			await this.sendRequest('moderator:setAllPeerFrameRate', { newFrameRate: framerate });
		}
		catch (error)
		{

			logger.error('setAllRemotePeerFrameRate() [error:"%o"]', error);
		}
	}

	async remoteCycleCamera({ peerId })
	{
		logger.debug('remoteCycleCamera({ peerId: %s })', peerId);

		try
		{
			await this.sendRequest('moderator:remoteCycleCamera', { peerId });
		}
		catch (error)
		{

			logger.error('remoteCycleCamera() [error:"%o"]', error);
		}
	}

	async remoteToggleVoiceActivatedUnmute({ peerId })
	{
		logger.debug('remoteToggleVoiceActivatedUnmute({ peerId: %s })', peerId);

		try
		{
			await this.sendRequest('moderator:remoteToggleVoiceActivatedUnmute', { peerId });
		}
		catch (error)
		{

			logger.error('remoteToggleVoiceActivatedUnmute() [error:"%o"]', error);
		}
	}

	async remoteEnableEchoCancellation({ peerId })
	{
		logger.debug('remoteEnableEchoCancellation({ peerId: %s })', peerId);

		try
		{
			await this.sendRequest('moderator:remoteEnableEchoCancellation', { peerId });
		}
		catch (error)
		{

			logger.error('remoteEnableEchoCancellation() [error:"%o"]', error);
		}
	}

	async remoteEnablePerformanceMode({ peerId })
	{
		logger.debug('remoteEnablePerformanceMode({ peerId: %s })', peerId);

		try
		{
			await this.sendRequest('moderator:enablePerformanceMode', { peerId });
		}
		catch (error)
		{

			logger.error('remoteEnablePerformanceMode() [error:"%o"]', error);
		}
	}

	async remoteDisablePerformanceMode({ peerId })
	{
		logger.debug('remoteDisablePerformanceMode({ peerId: %s })', peerId);

		try
		{
			await this.sendRequest('moderator:disablePerformanceMode', { peerId });
		}
		catch (error)
		{

			logger.error('remoteDisablePerformanceMode() [error:"%o"]', error);
		}
	}

	async remotePeerLocalMute({ peerId, targetPeerId })
	{
		logger.debug('remotePeerLocalMute({ peerId: %s, targetPeerId: %s })', peerId, targetPeerId);

		try
		{
			await this.sendRequest('moderator:remotePeerLocalMute', { peerId, targetPeerId });
		}
		catch (error)
		{

			logger.error('remotePeerLocalMute() [error:"%o"]', error);
		}
	}

	async remotePeerLocalUnmute({ peerId, targetPeerId })
	{
		logger.debug('remotePeerLocalUnmute({ peerId: %s, targetPeerId %s })', peerId, targetPeerId);

		try
		{
			await this.sendRequest('moderator:remotePeerLocalUnmute', { peerId, targetPeerId });
		}
		catch (error)
		{

			logger.error('remotePeerLocalUnmute() [error:"%o"]', error);
		}
	}

	async remoteSpeakerMute({ peerId })
	{
		logger.debug('remoteSpeakerMute({ peerId: %s })', peerId);

		try
		{
			await this.sendRequest('moderator:remoteSpeakerMute', { peerId });
		}
		catch (error)
		{

			logger.error('remoteSpeakerMute() [error:"%o"]', error);
		}
	}

	async remoteSpeakerUnmute({ peerId })
	{
		logger.debug('remoteSpeakerUnmute({ peerId: %s })', peerId);

		try
		{
			await this.sendRequest('moderator:remoteSpeakerUnmute', { peerId });
		}
		catch (error)
		{

			logger.error('remoteSpeakerUnmute() [error:"%o"]', error);
		}
	}

	getUserId()
	{
		return store.getState().settings.userId;
	}

	async join({ roomId, subRoomId, joinVideo, joinAudio = true, overrideLock = false })
	{
		const { displayName } = store.getState().settings;

		if (!displayName.trim())
		{
			store.dispatch(requestActions.notify(
				{
					text : 'A name is required!',
					type : 'error'
				}
			));

			return;
		}

		if (!this._loadedDynamicImports)
		{
			await this._loadDynamicImports();
		}

		this._roomId = roomId;
		this._subRoomId = subRoomId;
		const combinedRoomName = this._subRoomId ? `${this._roomId}/${this._subRoomId}` : this._roomId;

		const userId = store.getState().settings.userId;

		store.dispatch(roomActions.setRoomName(combinedRoomName));
		store.dispatch(roomActions.setRoomId(this._roomId));
		store.dispatch(roomActions.setSubRoomId(this._subRoomId));

		this._signalingUrl = getSignalingUrl({
			peerId    : this._peerId,
			roomId    : this._roomId,
			subRoomId : this._subRoomId,
			overrideLock,
			isView,
			userId
		});

		this._screenSharing = ScreenShare.create(this._device);

		this._signalingSocket = io(this._signalingUrl,
			{
				transports : [ 'websocket' ],
				upgrade    : false,
				timeout    : requestTimeout
			});

		store.dispatch(roomActions.setRoomState('connecting'));

		this._signalingSocket.on('connect', () =>
		{
			logger.debug('signaling Peer "connect" event');
		});

		this._signalingSocket.on('disconnect', (reason) =>
		{
			logger.warn('signaling Peer "disconnect" event [reason:"%s"]', reason);

			if (this._closed)
				return;

			if (reason === 'io server disconnect')
			{
				store.dispatch(requestActions.notify(
					{
						text : intl.formatMessage({
							id             : 'socket.disconnected',
							defaultMessage : 'You are disconnected'
						})
					}));

				this.close();
			}

			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'socket.reconnecting',
						defaultMessage : 'You are disconnected, attempting to reconnect'
					})
				}));

			if (this._screenSharingAudioProducer)
			{
				this._screenSharingAudioProducer.close();

				store.dispatch(
					producerActions.removeProducer(this._screenSharingAudioProducer.id));

				this._screenSharingAudioProducer = null;
			}

			if (this._screenSharingProducer)
			{
				this._screenSharingProducer.close();

				store.dispatch(
					producerActions.removeProducer(this._screenSharingProducer.id));

				this._screenSharingProducer = null;
			}

			if (this._webcamProducer)
			{
				this._webcamProducer.close();

				store.dispatch(
					producerActions.removeProducer(this._webcamProducer.id));

				this._webcamProducer = null;
			}

			if (this._micProducer)
			{
				this._micProducer.close();

				store.dispatch(
					producerActions.removeProducer(this._micProducer.id));

				this._micProducer = null;
			}

			if (this._sendTransport)
			{
				this._sendTransport.close();

				this._sendTransport = null;
			}

			if (this._recvTransport)
			{
				if (this._recvTransport.closed === false)
					this._recvTransport.close();

				this._recvTransport = null;
			}

			this._spotlights.clearSpotlights();

			store.dispatch(peerActions.clearPeers());

			store.dispatch(consumerActions.clearConsumers());

			// reset room
			store.dispatch(roomActions.setAlwaysVisibleSpotlights([]));
			store.dispatch(roomActions.setDrawingTogether(false));
			store.dispatch(roomActions.setForcedLayout(null));
			store.dispatch(roomActions.setIFrameURL(''));
			store.dispatch(roomActions.clearSpotlights());
			store.dispatch(roomActions.setRoomActiveSpeaker(null));
			store.dispatch(roomActions.setRoomState('connecting'));
			store.dispatch(roomActions.setPlayGame(0, 0));
		});

		this._signalingSocket.on('reconnect_failed', () =>
		{
			logger.warn('signaling Peer "reconnect_failed" event');

			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'socket.disconnected',
						defaultMessage : 'You are disconnected'
					})
				}));

			this.close();
		});

		this._signalingSocket.on('reconnect', (attemptNumber) =>
		{
			logger.debug('signaling Peer "reconnect" event [attempts:"%s"]', attemptNumber);

			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'socket.reconnected',
						defaultMessage : 'You are reconnected'
					})
				}));

			store.dispatch(roomActions.setRoomState('connected'));
		});

		this._signalingSocket.on('request', async (request, cb) =>
		{
			logger.debug(
				'socket "request" event [method:"%s", data:"%o"]',
				request.method, request.data);

			switch (request.method)
			{
				default:
				{
					logger.error('unknown request.method "%s"', request.method);

					cb(500, `unknown request.method "${request.method}"`);
				}
			}
		});

		this._signalingSocket.on('notification', async (notification) =>
		{
			logger.debug(
				'socket "notification" event [method:"%s", data:"%o"]',
				notification.method, notification.data);

			try
			{
				switch (notification.method)
				{
					case 'newConsumer':
					{
						const {
							peerId,
							producerId,
							viewOnly,
							id,
							kind,
							rtpParameters,
							type,
							appData,
							producerPaused,
							score
						} = notification.data;

						const consumer = await this._recvTransport.consume(
							{
								id,
								producerId,
								kind,
								rtpParameters,
								appData : { ...appData, peerId } // Trick.
							});

						// Store in the map.
						this._consumers.set(consumer.id, consumer);

						consumer.on('transportclose', () =>
						{
							this._consumers.delete(consumer.id);
						});

						const { spatialLayers, temporalLayers } =
							mediasoupClient.parseScalabilityMode(
								consumer.rtpParameters.encodings[0].scalabilityMode);

						const { highlightedPeersOnly = false } = store.getState().settings;

						const startVideoConsumer =
							!highlightedPeersOnly ||
							(highlightedPeersOnly && this._spotlights.alwaysVisibleSpotlights.includes(peerId)) ||
							(this._spotlights.broadcaster === peerId);

						const consumerStoreObject = {
							id                     : consumer.id,
							viewOnly               : viewOnly,
							peerId                 : peerId,
							kind                   : kind,
							type                   : type,
							locallyPaused          : false,
							remotelyPaused         : producerPaused,
							rtpParameters          : consumer.rtpParameters,
							source                 : consumer.appData.source,
							spatialLayers          : spatialLayers,
							temporalLayers         : temporalLayers,
							width                  : consumer.appData.width,
							height                 : consumer.appData.height,
							resolutionScalings     : consumer.appData.resolutionScalings,
							preferredSpatialLayer  : 0,
							preferredTemporalLayer : 0,
							priority               : 1,
							codec                  : consumer.rtpParameters.codecs[0].mimeType.split('/')[1],
							track                  : consumer.track,
							score                  : score,
							audioGain              : undefined
						};

						this._spotlights.addVideoConsumer(consumerStoreObject);

						store.dispatch(consumerActions.addConsumer(consumerStoreObject,
							peerId));

						await this._startConsumer(consumer);

						if (kind === 'audio')
						{
							consumer.volume = 0;

							const stream = new MediaStream();

							stream.addTrack(consumer.track);

							if (!stream.getAudioTracks()[0])
								throw new Error('request.newConsumer | given stream has no audio track');

							consumer.hark = hark(stream, { play: false, threshold: -75 });

							store.dispatch(peerAudioActions.setPeerMakingSound(`${consumer.appData.source}-${peerId}`, false));

							consumer.hark.on('speaking', () =>
							{
								store.dispatch(peerAudioActions.setPeerMakingSound(`${consumer.appData.source}-${peerId}`, true));
							});

							consumer.hark.on('stopped_speaking', () =>
							{
								store.dispatch(peerAudioActions.setPeerMakingSound(`${consumer.appData.source}-${peerId}`, false));
							});
						}

						break;
					}

					case 'enteredLobby':
					{
						const { lobbyMessage } = notification.data;

						store.dispatch(lobbyActions.setLobbyMessage(lobbyMessage));

						store.dispatch(roomActions.setInLobby(true));

						const { displayName, videoMuted, audioMuted } = store.getState().settings;
						const { picture } = store.getState().me;

						await this.sendRequest('changePreviewVideoMuted', { previewVideoMuted: videoMuted });
						await this.sendRequest('changePreviewAudioMuted', { previewAudioMuted: audioMuted });
						await this.sendRequest('changeDisplayName', { displayName });
						await this.sendRequest('changePicture', { picture });
						break;
					}

					case 'signInRequired':
					{
						store.dispatch(roomActions.setSignInRequired(true));

						break;
					}

					case 'overRoomLimit':
					{
						store.dispatch(roomActions.setOverRoomLimit(true));

						break;
					}

					case 'roomReady':
					{
						const { turnServers } = notification.data;

						this._turnServers = turnServers;

						store.dispatch(roomActions.toggleJoined());
						store.dispatch(roomActions.setInLobby(false));

						await this._joinRoom({ joinVideo, joinAudio });

						break;
					}

					case 'roomBack':
					{
						await this._joinRoom({ joinVideo, joinAudio });

						break;
					}

					case 'moderator:closeRoom':
					{
						const { roomId } = notification.data;

						store.dispatch(
							roomManagementActions.removeRoom(roomId)
						);

						break;
					}

					case 'randomPersonSelectStarted':
					{
						const { initiator } = notification.data;

						store.dispatch(
							selectRandomPersonActions.setShowDialog(true)
						);

						store.dispatch(
							selectRandomPersonActions.setRandomPersonSelectInProgress(true)
						);

						store.dispatch(
							selectRandomPersonActions.resetSelectedPeer()
						);

						if (initiator)
						{
							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'room.startedRandomPersonSelector',
										defaultMessage : '{initiator} started the random person selector'
									},
									{
										initiator
									})
								}));
						}

						break;
					}

					case 'randomPersonSelected':
					{
						const { selectedPeer } = notification.data;

						store.dispatch(
							selectRandomPersonActions.setRandomPersonSelectInProgress(false)
						);

						if (!selectedPeer)
						{
							store.dispatch(requestActions.notify(
								{
									type : 'error',
									text : 'The randomly selected person left.. But you can try again!'
								}));

							store.dispatch(
								selectRandomPersonActions.setShowDialog(false)
							);

							return;
						}

						store.dispatch(
							selectRandomPersonActions.setSelectedPeer(selectedPeer)
						);

						if (selectedPeer === this._peerId)
						{
							store.dispatch(requestActions.notify(
								{
									text : 'You have been randomly chosen to take the word!'
								}));
						}

						this.addForcedHighlightedPeerId(selectedPeer);

						window.setTimeout(() =>
						{
							store.dispatch(
								selectRandomPersonActions.setShowDialog(false)
							);
						}, 3000);

						break;
					}

					case 'setRoomTheme':
					{
						const { colorString } = notification.data;

						store.dispatch(
							colorActions.setRoomColors(colorString)
						);

						const { currentTheme } = store.getState().theme;

						logger.debug('currentTheme', currentTheme);

						if (currentTheme.name === 'room')
						{
							store.dispatch(
								colorActions.setColors(currentTheme.colors, true)
							);
						}

						break;
					}

					case 'moderator:enablePerformanceMode':
					{
						const {
							highlightedPeersOnly
						} = store.getState().settings;

						if (!highlightedPeersOnly)
						{
							store.dispatch(
								settingsActions.togglePerformanceMode()
							);
							this.refreshSpotlights();
						}

						break;
					}

					case 'moderator:disablePerformanceMode':
					{
						const {
							highlightedPeersOnly
						} = store.getState().settings;

						if (highlightedPeersOnly)
						{
							store.dispatch(
								settingsActions.togglePerformanceMode()
							);
							this.refreshSpotlights();
						}

						break;
					}

					case 'moderator:setAutoCodecSwitching':
					{
						const { autoCodecSwitching } = notification.data;

						store.dispatch(roomActions.setAutoCodecSwitching(autoCodecSwitching));

						break;
					}

					case 'moderator:videoCodecLockSet':
					{
						const { peerId, lockedVideoCodec } = notification.data;

						store.dispatch(peerActions.setPeerVideoCodecLock(peerId, lockedVideoCodec));

						break;
					}

					case 'moderator:setShowWebsite':
					{
						const { showWebsite } = notification.data;

						logger.debug('Setting show website to %s', showWebsite);

						store.dispatch(roomActions.setShowWebsite(showWebsite));

						break;
					}

					case 'forceHighlightedPeerId':
					{
						const { peerId, prepend, reset } = notification.data;

						logger.debug('Setting selected peer id %s', peerId);

						this.addForcedHighlightedPeerId(peerId, prepend, reset);

						break;
					}

					case 'removeHighlightedPeerId':
					{
						const { peerId } = notification.data;

						this.removeForcedHighlightedPeerId(peerId);

						break;
					}

					case 'addToAlwaysVisiblePeerId':
					{
						const { peerId } = notification.data;

						logger.debug('Setting selected peer id %s', peerId);

						this._spotlights.addAlwaysVisibleSpotlightPeer(peerId);

						break;
					}

					case 'removeFromAlwaysVisiblePeerId':
					{
						const { peerId } = notification.data;

						logger.debug('Removing selected peer id %s', peerId);

						this._spotlights.removeAlwaysVisibleSpotlightPeer(peerId);

						break;
					}

					case 'clearForcedHighlightedPeerId':
					{
						this.clearForcedHighlightedPeerId();

						break;
					}

					case 'peer:closeSelfHighlight':
					{
						const {
							drawingTogether,
							writingTogether,
							forcedLayout,
							iframeUrlSource,
							peerId
						} = notification.data;

						this.removeForcedHighlightedPeerId(peerId);

						store.dispatch(roomActions.setForcedLayout(forcedLayout));

						this.setIframeUrlSource(iframeUrlSource);
						this.setWritingTogether(writingTogether);
						this.setDrawingTogether(drawingTogether);

						break;
					}

					case 'moderator:setPeerFrameRate':
					{
						const { newFrameRate } = notification.data;

						this.updateWebcam({ newFrameRate });

						break;
					}

					case 'moderator:setPeerVideoCodec':
					{
						const { newVideoCodec } = notification.data;
						const {
							videoCodec
						} = store.getState().settings;

						if (!newVideoCodec)
						{
							break;
						}

						if (!videoCodec || videoCodec.toLowerCase() !== newVideoCodec.toLowerCase())
						{
							this.updateWebcam({ restart: true, newVideoCodec });
						}

						break;
					}

					case 'moderator:setPeerResolution':
					{

						const { newResolution } = notification.data;

						this.updateWebcam({ newResolution });

						break;
					}

					case 'setPeerLayout':
					{
						const { newLayout } = notification.data;

						store.dispatch(roomActions.setForcedLayout(newLayout));

						break;
					}

					case 'moderator:remoteCycleCamera':
					{
						this.cycleCamera();

						break;
					}

					case 'moderator:remoteToggleVoiceActivatedUnmute':
					{
						const { voiceActivatedUnmute } = store.getState().settings;

						store.dispatch(
							settingsActions.setVoiceActivatedUnmute(!voiceActivatedUnmute));

						break;
					}

					case 'moderator:remoteEnableEchoCancellation':
					{
						store.dispatch(
							settingsActions.setEchoCancellation(true));
						this.updateMic({ isLocal: false, restart: true });

						break;
					}

					case 'moderator:remoteSpeakerMute':
					{
						const { peerId } = notification.data;

						this.modifyPeerConsumer(peerId, 'mic', true);

						break;
					}

					case 'moderator:remoteSpeakerUnmute':
					{
						const { peerId } = notification.data;

						this.modifyPeerConsumer(peerId, 'mic', false);

						break;
					}

					case 'moderator:setIFrameURL':
					{
						const { iframeUrlSource, forcedLayout } = notification.data;

						logger.debug('Playing video %s', iframeUrlSource);

						this.setIframeUrlSource(iframeUrlSource);

						store.dispatch(roomActions.setForcedLayout(forcedLayout));

						break;
					}

					case 'soundFx:play':
					{
						const { effect } = notification.data;

						this._soundFx.play(effect);

						switch (effect)
						{
							case 'applause':
							{
								store.dispatch(roomActions.setConfetti(true));
								clearTimeout(this._applauseTimeout);
								this._applauseTimeout = setTimeout(() =>
								{
									store.dispatch(roomActions.setConfetti(false));
									this._applauseTimeout = null;
								}, 1000 * 6);
								break;
							}
							case 'crickets':
							{
								store.dispatch(roomActions.setCricket(true));
								clearTimeout(this._cricketTimeout);
								this._cricketTimeout = setTimeout(() =>
								{
									store.dispatch(roomActions.setCricket(false));
									this._cricketTimeout = null;
								}, 1000 * 6);
								break;
							}
							default:
								break;
						}

						break;

					}

					case 'colorSplashPeer':
					{
						const { peerId, colorOffset } = notification.data;

						this._soundFx.play('colorHighlight');

						store.dispatch(peerActions.setPeerColorHighlight(peerId, colorOffset));
						clearTimeout(this._peerColorHighlights[peerId]);
						this._peerColorHighlights[peerId] = setTimeout(() =>
						{
							store.dispatch(peerActions.setPeerColorHighlight(peerId, null));
							this._peerColorHighlights[peerId] = null;
						}, 1000 * 12);

						break;
					}

					case 'moderator:setRoomMode':
					{
						const { roomMode, roomPermissions } = notification.data;

						store.dispatch(roomActions.setRoomPermissions(roomPermissions));

						store.dispatch(roomActions.setRoomMode(roomMode));

						if (roomMode === 'webinar')
						{
							store.dispatch(requestActions.notify(
								{
									text : 'This room is now in Webinar Mode. Raise your hand to ask for speaker rights.'
								}));
						}
						else if (roomMode === 'video_only')
						{
							store.dispatch(requestActions.notify(
								{
									text : 'This room is now in video-only Mode. Microphones are disabled.'
								}));
						}
						else if (roomMode === 'audio_only')
						{
							store.dispatch(requestActions.notify(
								{
									text : 'This room is now in audio-only Mode. Cameras are disabled.'
								}));
						}
						else if (roomMode === 'experience')
						{
							// nothing to mention here
						}
						else
						{
							store.dispatch(requestActions.notify(
								{
									text : 'The room mode has been set to default. You can enable your camera/microphone freely.'
								}));
						}

						break;
					}

					case 'moderator:drawingTogether':
					{
						const { drawingTogether, forcedLayout } = notification.data;

						logger.debug('Drawing together %s', drawingTogether);

						this.setDrawingTogether(drawingTogether);

						store.dispatch(roomActions.setForcedLayout(forcedLayout));

						break;
					}

					case 'moderator:setPersistRoom':
					{
						const { _roomId, _subRoomId, persistRoom } = notification.data;

						store.dispatch(
							roomManagementActions.setRoomPersistence(_subRoomId ? _subRoomId : _roomId, persistRoom)
						);

						break;
					}

					case 'playingMusic':
					{
						const { playingMusic, initiator } = notification.data;

						logger.debug('Playing music %s', playingMusic);

						const currentState = store.getState().music.playingMusic;

						store.dispatch(musicActions.setShowMusicPlayer(playingMusic));

						if (initiator && playingMusic && currentState !== playingMusic)
						{
							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'room.startedPlayingMusic',
										defaultMessage : '{initiator} started playing music'
									},
									{
										initiator
									})
								}));
						}
						else if (initiator && currentState !== playingMusic)
						{
							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'room.stoppedPlayingMusic',
										defaultMessage : '{initiator} stopped playing music'
									},
									{
										initiator
									})
								}));
						}

						break;
					}

					case 'playIndex':
					{
						const { playIndex, initiator } = notification.data;

						logger.debug('Play index %s', playIndex);

						const currentState = store.getState().music.playIndex;

						store.dispatch(musicActions.setPlayIndex(playIndex));

						if (initiator && currentState !== playIndex)
						{
							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'room.skippedTrack',
										defaultMessage : '{initiator} skipped music track'
									},
									{
										initiator
									})
								}));
						}

						break;
					}

					case 'playback':
					{
						const { playback, initiator } = notification.data;

						logger.debug('Playback %s', playback);

						const currentState = store.getState().music.playback;

						if (currentState !== playback)
							store.dispatch(musicActions.setPlayback(playback));

						if (initiator && currentState !== playback)
						{
							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'room.changedPlayback',
										defaultMessage : '{initiator} changed music playback'
									},
									{
										initiator
									})
								}));
						}

						break;
					}

					case 'musicSeek':
					{
						const { currentTime } = notification.data;

						logger.debug('Music seek %s', currentTime);

						store.dispatch(musicActions.setMusicSeek(currentTime));

						break;
					}

					case 'musicVolume':
					{
						const { volume, initiator } = notification.data;

						logger.debug('Volume %s', volume);

						const currentState = store.getState().music.volume;

						if (currentState !== volume)
							store.dispatch(musicActions.setMusicVolume(volume));

						if (initiator && currentState !== volume)
						{
							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'room.changedMusicVolume',
										defaultMessage : '{initiator} changed music volume'
									},
									{
										initiator
									})
								}));
						}

						break;
					}

					case 'moderator:writingTogether':
					{
						const { writingTogether, forcedLayout, initiator } = notification.data;

						logger.debug('Writing together %s', writingTogether);

						this.setWritingTogether(writingTogether);

						store.dispatch(roomActions.setForcedLayout(forcedLayout));

						if (initiator)
						{
							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'room.startedWritingTogether',
										defaultMessage : '{initiator} {status} writing together'
									},
									{
										initiator,
										status : writingTogether ? 'started' : 'stopped'
									})
								}));
						}

						break;
					}

					case 'setRoomBackgroundImage':
					{
						const { image } = notification.data;

						logger.debug('New background image');

						store.dispatch(roomActions.setBackgroundImage(image));

						break;
					}

					case 'setFriendlyRoomName':
					{
						const { friendlyRoomName, displayName, _roomId, _subRoomId } = notification.data;

						logger.debug('New friendly room name %s', friendlyRoomName, _roomId, _subRoomId, this._roomId, this._subRoomId);

						if (this._roomId === _roomId && _subRoomId === this._subRoomId)
						{
							store.dispatch(roomActions.setFriendlyRoomName(friendlyRoomName));

							this.notify(intl.formatMessage({
								id             : 'room.setFriendlyRoomName',
								defaultMessage : '{displayName} set new room name'
							}, { displayName }));
						}

						store.dispatch(roomManagementActions.setFriendlyRoomName(_subRoomId ? _subRoomId : _roomId, friendlyRoomName));

						break;
					}

					case 'setFriendlyRoomDescription':
					{
						const { friendlyRoomDescription, displayName } = notification.data;

						logger.debug('New friendly room description %s', friendlyRoomDescription);

						store.dispatch(roomActions.setFriendlyRoomDescription(friendlyRoomDescription));

						this.notify(intl.formatMessage({
							id             : 'room.setFriendlyRoomDescription',
							defaultMessage : '{displayName} set new room description'
						}, { displayName }));

						break;
					}

					case 'runPoll':
					{
						const { poll } = notification.data;

						logger.debug('Poll %s', poll);

						const polls = [ ...store.getState().polls.polls ];

						polls.push(poll);
						this.setPolls(polls);

						if (polls && polls.length && polls.filter((pollItem) => pollItem.kind !== 'overlay').some((pollItem) => pollItem.status === 'active'))
						{
							store.dispatch(chatDrawerActions.openChatDrawer());
							store.dispatch(chatDrawerActions.setChatDrawerTab('polls'));
						}

						break;
					}

					case 'setPollStatus':
					{
						const { id, status, timestamp } = notification.data;

						const polls = [ ...store.getState().polls.polls ];

						const pollIndex = polls.findIndex((item) => item.id === id);

						if (pollIndex !== -1)
						{
							polls[pollIndex].status = status;
							if (timestamp)
								polls[pollIndex].timestamp = timestamp;

							this.setPolls(polls);

							if (polls && polls.length && polls.filter((pollItem) => pollItem.kind !== 'overlay').some((poll) => poll.status === 'active'))
							{
								store.dispatch(chatDrawerActions.openChatDrawer());
								store.dispatch(chatDrawerActions.setChatDrawerTab('polls'));
							}
						}

						break;
					}

					case 'setPollTimestamp':
					{
						const { id, timestamp } = notification.data;

						const polls = [ ...store.getState().polls.polls ];

						const pollIndex = polls.findIndex((item) => item.id === id);

						if (pollIndex !== -1)
						{
							polls[pollIndex].timestamp = timestamp;
							this.setPolls(polls);
						}

						break;
					}

					case 'updatePollVotes':
					{
						const { pollId, votes, highscore, topAnswer } = notification.data;

						const polls = [ ...store.getState().polls.polls ];

						const pollIndex = polls.findIndex((item) => item.id === pollId);

						if (pollIndex !== -1)
						{
							polls[pollIndex].votes = votes;
							polls[pollIndex].highscore = highscore;
							polls[pollIndex].topAnswer = topAnswer;
							this.setPolls(polls);
						}

						break;
					}

					case 'clearPolls':
					{
						this.setPolls([]);

						break;
					}

					case 'deletePoll':
					{
						const { id } = notification.data;

						const polls = [ ...store.getState().polls.polls ];

						this.setPolls(polls.filter((item) => item.id !== id));

						break;
					}

					case 'voteNext':
					{
						const { votes, initiator } = notification.data;

						store.dispatch(
							roomActions.voteNext(votes));

						if (initiator)
						{
							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'room.voteNext',
										defaultMessage : '{initiator} voted to go to the next video'
									},
									{
										initiator
									})
								}));
						}

						break;
					}

					case 'modifyCountdownTimer':
					{

						const { timestamp: previousTimestamp } = store.getState().room;

						const { timestamp, initiator } = notification.data;

						logger.debug('modifyCountdownTimer %s', timestamp);

						store.dispatch(
							roomActions.modifyCountdownTimer(timestamp));

						if (initiator)
						{
							let newStatus;

							if (previousTimestamp === null && timestamp)
							{
								newStatus = 'started';
							}
							else if (previousTimestamp && timestamp)
							{
								newStatus = 'changed';
							}
							else if (previousTimestamp && timestamp == null)
							{
								newStatus = 'stopped';
							}

							if (newStatus)
							{
								store.dispatch(requestActions.notify(
									{
										text : intl.formatMessage({
											id             : 'room.changedCountdownTimer',
											defaultMessage : '{initiator} {newStatus} the countdown timer'
										},
										{
											initiator,
											newStatus
										})
									}));
							}

						}

						break;
					}

					case 'moderator:setAutoDistributeStrategy':
					{
						const { distributeStrategy } = notification.data;

						store.dispatch(
							roomActions.setAutoDistributeStrategy(distributeStrategy));

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.setAutoDistributeStrategy',
									defaultMessage : 'Auto-distribute strategy set to {distributeStrategy}'
								},
								{
									distributeStrategy : distributeStrategy.label
								})
							}));

						break;
					}

					case 'moderator:enableAutoDistribution':
					{
						store.dispatch(
							roomActions.setRoomAutoDistribute(true));

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.enableAutoDistribute',
									defaultMessage : 'Enabled auto-distributing peers'
								})
							}));

						break;
					}

					case 'moderator:disableAutoDistribution':
					{
						store.dispatch(
							roomActions.setRoomAutoDistribute(false));

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.disableAutoDistribute',
									defaultMessage : 'Disabled auto-distributing peers'
								})
							}));

						break;
					}

					case 'moderator:disableAutoDistributeCreateRooms':
					{
						store.dispatch(
							roomActions.setAutoDistributeCreateRooms(false));

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.disableAutoDistributeCreateRooms',
									defaultMessage : 'Disabled auto-creation of new rooms when auto-distributing'
								})
							}));

						break;
					}

					case 'moderator:enableAutoDistributeCreateRooms':
					{
						store.dispatch(
							roomActions.setAutoDistributeCreateRooms(true));

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.enableAutoDistributeCreateRooms',
									defaultMessage : 'Enabled auto-creation of new rooms when auto-distributing'
								})
							}));

						break;
					}

					case 'aboutToMoveNotification':
					{
						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.youWillBeMoved',
									defaultMessage : 'You are being moved to another room. Please wait.'
								})
							}));
						break;
					}

					case 'lockRoom':
					{
						const { _roomId, _subRoomId } = notification.data;

						if (_roomId === this._roomId && _subRoomId === this._subRoomId)
						{
							store.dispatch(
								roomActions.setRoomLocked());

							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'room.locked',
										defaultMessage : 'Room is now locked'
									})
								}));
						}

						store.dispatch(
							roomManagementActions.setRoomLock(_subRoomId ? _subRoomId : _roomId, true)
						);
						break;
					}

					case 'unlockRoom':
					{
						const { _roomId, _subRoomId } = notification.data;

						if (_roomId === this._roomId && _subRoomId === this._subRoomId)
						{
							store.dispatch(
								roomActions.setRoomUnLocked());

							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'room.unlocked',
										defaultMessage : 'Room is now unlocked'
									})
								}));
						}

						store.dispatch(
							roomManagementActions.setRoomLock(_subRoomId ? _subRoomId : _roomId, false)
						);

						break;
					}

					case 'deny':
					{
						store.dispatch(roomActions.setUnavailable(true));

						this.close(false);

						break;
					}

					case 'parkedPeer':
					{
						const { peerId } = notification.data;

						store.dispatch(
							lobbyPeerActions.addLobbyPeer(peerId));
						store.dispatch(
							roomActions.setToolbarsVisible(true));
						this._soundNotification();

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.newLobbyPeer',
									defaultMessage : 'New participant entered the lobby'
								})
							}));

						break;
					}

					case 'parkedPeers':
					{
						store.dispatch(
							lobbyPeerActions.resetLobbyPeers()
						);

						const { lobbyPeers } = notification.data;

						if (lobbyPeers.length > 0)
						{
							lobbyPeers.forEach((peer) =>
							{
								store.dispatch(
									lobbyPeerActions.addLobbyPeer(peer.id));

								store.dispatch(
									lobbyPeerActions.setLobbyPeerDisplayName(
										peer.displayName,
										peer.id
									)
								);

								store.dispatch(
									lobbyPeerActions.setLobbyPeerPicture(
										peer.picture,
										peer.id
									)
								);
							});

							store.dispatch(
								roomActions.setToolbarsVisible(true));

							this._soundNotification();

							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'room.newLobbyPeer',
										defaultMessage : 'New participant entered the lobby'
									})
								}));
						}

						break;
					}

					case 'lobby:setLobbyMessage':
					{
						const { message } = notification.data;

						store.dispatch(
							lobbyActions.setLobbyMessage(message)
						);

						break;
					}

					case 'lobby:peerClosed':
					{
						const { peerId } = notification.data;

						store.dispatch(
							lobbyPeerActions.removeLobbyPeer(peerId));

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.lobbyPeerLeft',
									defaultMessage : 'Participant in lobby left'
								})
							}));

						break;
					}

					case 'lobby:promotedPeer':
					{
						const { peerId } = notification.data;

						store.dispatch(
							lobbyPeerActions.removeLobbyPeer(peerId));

						break;
					}

					case 'lobby:changeDisplayName':
					{
						const { peerId, displayName } = notification.data;

						store.dispatch(
							lobbyPeerActions.setLobbyPeerDisplayName(displayName, peerId));

						if (this._havePermission(permissions.PROMOTE_PEER))
						{
							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'room.lobbyPeerChangedDisplayName',
										defaultMessage : 'Participant in lobby changed name to {displayName}'
									}, {
										displayName
									})
								}));
						}
						break;
					}

					case 'lobby:changePreviewVideoMuted':
					{
						const { peerId, previewVideoMuted } = notification.data;

						store.dispatch(
							lobbyPeerActions.setLobbyPeerPreviewVideoMuted(previewVideoMuted, peerId));

						break;
					}

					case 'lobby:changePreviewAudioMuted':
					{
						const { peerId, previewAudioMuted } = notification.data;

						store.dispatch(
							lobbyPeerActions.setLobbyPeerPreviewAudioMuted(previewAudioMuted, peerId));

						break;
					}

					case 'lobby:changePicture':
					{
						const { peerId, picture } = notification.data;

						store.dispatch(
							lobbyPeerActions.setLobbyPeerPicture(picture, peerId));

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.lobbyPeerChangedPicture',
									defaultMessage : 'Participant in lobby changed picture'
								})
							}));

						break;
					}

					case 'changeLabel':
					{
						const { peerId, label } = notification.data;

						store.dispatch(
							peerActions.setPeerLabel(peerId, label)
						);

						break;
					}

					case 'lobby:changeLabel':
					{
						const { peerId, label } = notification.data;

						store.dispatch(
							lobbyPeerActions.setLobbyPeerLabel(peerId, label));

						break;
					}

					case 'setAccessCode':
					{
						const { accessCode } = notification.data;

						store.dispatch(
							roomActions.setAccessCode(accessCode));

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.setAccessCode',
									defaultMessage : 'Access code for room updated'
								})
							}));

						break;
					}

					case 'setJoinByAccessCode':
					{
						const { joinByAccessCode } = notification.data;

						store.dispatch(
							roomActions.setJoinByAccessCode(joinByAccessCode));

						if (joinByAccessCode)
						{
							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'room.accessCodeOn',
										defaultMessage : 'Access code for room is now activated'
									})
								}));
						}
						else
						{
							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'room.accessCodeOff',
										defaultMessage : 'Access code for room is now deactivated'
									})
								}));
						}

						break;
					}

					case 'activeSpeaker':
					{
						const { peerId } = notification.data;

						store.dispatch(
							roomActions.setRoomActiveSpeaker(peerId));

						const alwaysVisiblePeers = store.getState().room.alwaysVisibleSpotlights;

						if (peerId && peerId !== this._peerId && !alwaysVisiblePeers.includes(peerId))
							this._spotlights.handleActiveSpeaker(peerId);

						break;
					}

					case 'changeDisplayName':
					{
						const { peerId, displayName, roomId: peerRoomId, subRoomId: peerSubRoomId, oldDisplayName } = notification.data;

						store.dispatch(
							peerActions.setPeerDisplayName(displayName, peerId, roomId, subRoomId));

						if (this._peerId !== peerId && this._roomId === peerRoomId && this._subRoomId === peerSubRoomId)
						{
							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'room.peerChangedDisplayName',
										defaultMessage : '{oldDisplayName} is now {displayName}'
									}, {
										oldDisplayName,
										displayName
									})
								}));
						}

						break;
					}

					case 'changePicture':
					{
						const { peerId, picture } = notification.data;

						store.dispatch(peerActions.setPeerPicture(peerId, picture));

						break;
					}

					case 'raisedHand':
					{
						const {
							peerId,
							raisedHand,
							raisedHandTimestamp
						} = notification.data;

						store.dispatch(
							peerActions.setPeerRaisedHand(
								peerId,
								raisedHand,
								raisedHandTimestamp
							)
						);

						const { displayName } = store.getState().peers[peerId];

						let text;

						if (raisedHand)
						{
							text = intl.formatMessage({
								id             : 'room.raisedHand',
								defaultMessage : '{displayName} raised their hand'
							}, {
								displayName
							});

							if (peerId && peerId !== this._peerId)
								this._spotlights.handleRaisedHand(peerId);
						}
						else
						{
							text = intl.formatMessage({
								id             : 'room.loweredHand',
								defaultMessage : '{displayName} put their hand down'
							}, {
								displayName
							});
						}

						if (displayName)
						{
							store.dispatch(requestActions.notify(
								{
									text
								}));
						}

						this._soundNotification();

						break;
					}

					case 'chatMessage':
					{
						const { peerId, chatMessage } = notification.data;

						store.dispatch(
							chatActions.addResponseMessage({ ...chatMessage, peerId }));

						if (
							!store.getState().chatDrawer.chatDrawerOpen ||
							(store.getState().chatDrawer.chatDrawerOpen &&
							store.getState().chatDrawer.currentChatDrawerTab !== 'chat')
						) // Make sound
						{
							store.dispatch(roomActions.setToolbarsVisible(true));
							if (!this._chatAutoDisplayed)
							{
								this._chatAutoDisplayed = true;
								store.dispatch(chatDrawerActions.openChatDrawer());
								store.dispatch(chatDrawerActions.setChatDrawerTab('chat'));
							}
							this._soundNotification();
						}

						if (
							peerId !== this._peerId &&
							(!store.getState().chatDrawer.chatDrawerOpen || store.getState().chatDrawer.currentChatDrawerTab !== 'chat')
						)
						{
							store.dispatch(requestActions.notifyChat(chatMessage));
						}

						break;
					}

					case 'server:chatMessage':
					{
						const { chatMessage } = notification.data;

						store.dispatch(
							chatActions.addResponseMessage({ ...chatMessage }));

						store.dispatch(requestActions.notifyChat(chatMessage));

						break;
					}

					case 'moderator:clearChat':
					{
						store.dispatch(chatActions.clearChat());

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'moderator.clearChat',
									defaultMessage : 'Moderator cleared the chat'
								})
							}));

						break;
					}

					case 'sendFile':
					{
						const { peerId, magnetUri } = notification.data;

						store.dispatch(fileActions.addFile(peerId, magnetUri));

						this.handleDownload(magnetUri)
							.then(() =>
							{
								store.dispatch(requestActions.notify(
							  {
							  	text : intl.formatMessage({
							  		id             : 'room.newFile',
							  		defaultMessage : 'New file available'
							  	})
							  }));
						    if (
						    	!store.getState().chatDrawer.chatDrawerOpen ||
						    	(store.getState().chatDrawer.chatDrawerOpen &&
						    	store.getState().chatDrawer.currentChatDrawerTab !== 'files')
						    ) // Make sound
						    {
						    	store.dispatch(
						    		roomActions.setToolbarsVisible(true));
						    	this._soundNotification();
						    }
							});
						break;
					}

					case 'moderator:clearFileSharing':
					{
						store.dispatch(fileActions.clearFiles());

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'moderator.clearFiles',
									defaultMessage : 'Moderator cleared the files'
								})
							}));

						break;
					}

					case 'producerScore':
					{
						const { producerId, score } = notification.data;

						store.dispatch(
							producerActions.setProducerScore(producerId, score));

						break;
					}

					case 'newPeer':
					{
						const {
							id,
							displayName,
							picture,
							roles,
							roomId: peerRoomId,
							subRoomId: peerSubRoomId,
							customFields
						} = notification.data;

						store.dispatch(peerActions.addPeer(
							{ id, displayName, picture, roles, roomId: peerRoomId, subRoomId: peerSubRoomId, consumers: [], customFields }));

						this._spotlights.newPeer(id);

						if (!displayName.includes('_view_'))
						{
							this._soundNotification();

							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'room.newPeer',
										defaultMessage : '{displayName} joined the room'
									}, {
										displayName
									})
								}));
						}

						break;
					}

					case 'newBroadcaster':
					{
						const {
							id,
							displayName,
							picture,
							roomId: broadcasterRoomId,
							subRoomId: broadcasterSubRoomId,
							customFields
						} = notification.data;

						const broadcaster = {
							id,
							displayName,
							picture,
							roomId    : broadcasterRoomId,
							subRoomId : broadcasterSubRoomId,
							customFields,
							consumers : []
						};

						store.dispatch(
							broadcasterActions.addBroadcaster(broadcaster)
						);

						this._spotlights.newBroadcaster(id);

						break;
					}

					case 'peerClosed':
					{
						const { peerId, _roomId, _subRoomId } = notification.data;

						store.dispatch(
							roomManagementActions.removePeer(_subRoomId ? _subRoomId : _roomId, peerId)
						);

						for (const consumer of this._consumers.values())
						{
							if (peerId === consumer.appData.peerId)
							{
								this._closeConsumer(consumer.id);
							}
						}

						this._spotlights.closePeer(peerId);

						store.dispatch(
							peerActions.removePeer(peerId));

						store.dispatch(
							chatActions.removeRecipient(peerId));

						this.removeForcedHighlightedPeerId(peerId);

						break;
					}

					case 'broadcasterClosed':
					{
						const { broadcasterId, _roomId, _subRoomId } = notification.data;

						for (const consumer of this._consumers.values())
						{
							if (broadcasterId === consumer.appData.peerId)
							{
								this._closeConsumer(consumer.id);
							}
						}

						this._spotlights.closeBroadcaster(broadcasterId);

						store.dispatch(
							broadcasterActions.removeBroadcaster(broadcasterId)
						);

						break;
					}

					case 'consumerClosed':
					{
						const { consumerId } = notification.data;

						this._closeConsumer(consumerId);

						break;
					}

					case 'consumerPaused':
					{
						const { consumerId } = notification.data;
						const consumer = this._consumers.get(consumerId);

						if (!consumer)
							break;

						store.dispatch(
							consumerActions.setConsumerPaused(consumerId, 'remote'));

						this._spotlights.pauseVideoConsumer(consumerId);

						break;
					}

					case 'consumerResumed':
					{
						const { consumerId } = notification.data;
						const consumer = this._consumers.get(consumerId);

						if (!consumer)
							break;

						store.dispatch(
							consumerActions.setConsumerResumed(consumerId, 'remote'));

						this._spotlights.resumeVideoConsumer(consumerId);

						break;
					}

					case 'consumerLayersChanged':
					{
						const { consumerId, spatialLayer, temporalLayer } = notification.data;
						const consumer = this._consumers.get(consumerId);

						if (!consumer)
						{
							logger.error(
								'"consumerLayersChanged" event error: consumer ["%s"] not found',
								consumerId
							);
							break;
						}

						store.dispatch(consumerActions.setConsumerCurrentLayers(
							consumerId, spatialLayer, temporalLayer));

						break;
					}

					case 'consumerScore':
					{
						const { consumerId, score } = notification.data;

						store.dispatch(
							consumerActions.setConsumerScore(consumerId, score));

						break;
					}

					case 'moderator:moveToMainRoom':
					{

						this.moveBackToMainRoom();

						break;
					}

					case 'mute':
					{
						const { displayName } = notification.data;

						this.muteMic();

						if (displayName)
						{
							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'moderator.muteAudio',
										defaultMessage : '{displayName} muted your audio'
									}, { displayName })
								}));
						}

						break;
					}

					case 'reportUser':
					{
						const { requester, reportedUser } = notification.data;

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'moderator.reportUser',
									defaultMessage : '{requester} reported user {reportedUser} for inapprioriate behavior. The room has been locked.'
								}, { requester, reportedUser })
							}));

						break;
					}

					case 'softMute':
					{
						const { displayName } = notification.data;

						this.muteMic(true);

						if (displayName)
						{
							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'moderator.muteAudio',
										defaultMessage : '{displayName} muted your audio'
									}, { displayName })
								}));
						}

						break;
					}

					case 'softUnmute':
					{
						this.unmuteMic(true);

						break;
					}

					case 'moderator:unmute':
					{
						this.unmuteMic();

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'moderator.unmuteAudio',
									defaultMessage : 'Moderator unmuted your audio'
								})
							}));
						break;
					}

					case 'moderator:moveToSubRoom':
					{
						const { peerId, roomId: newRoomId, subRoomId: newSubRoomId } = notification.data;

						logger.debug('moderator:moveToSubRoom [peerId: "%s", newRoomId: "%s", newSubRoomId: "%s"]', peerId, newRoomId, newSubRoomId);

						if (this._roomId === newRoomId && this._subRoomId === newSubRoomId)
							throw new Error('Move to subroom is equal to current room');

						if (this._peerId === peerId)
						{

							this.disconnect();
							setTimeout(() =>
							{
								const { videoMuted, audioMuted } = store.getState().settings;
								const newUrlParts = [ newRoomId, newSubRoomId ].filter((f) => Boolean(f));

								// eslint-disable-next-line no-restricted-globals
								history.pushState({}, null, `/${newUrlParts.join('/')}`);
								this.join({ roomId: newRoomId, subRoomId: newSubRoomId, joinVideo: videoMuted === false, joinAudio: audioMuted === false, overrideLock: true });
							}, 1000);
						}

						break;
					}

					case 'moderator:setSubRooms':
					{
						const { parentRoom, subRooms } = notification.data;

						subRooms.forEach((subRoom) =>
						{
							subRoom.peers.forEach((subRoomPeer) =>
							{
								const peerExists = store.getState().peers[subRoomPeer.id];

								if (!peerExists)
								{
									store.dispatch(peerActions.addPeer(
										{
											...subRoomPeer,
											consumers : []
										}
									));
								}
								else
								{
									store.dispatch(peerActions.addPeer(
										{ ...peerExists,
											...subRoomPeer,
											consumers : subRoom.id === peerExists.subRoomId ? peerExists.consumers : []
										}
									));
								}
							});

							store.dispatch(
								roomManagementActions.addRoom(subRoom)
							);
						});

						parentRoom.peers.forEach((parentRoomPeer) =>
						{
							const peerExists = store.getState().peers[parentRoomPeer.id];

							if (!peerExists)
							{
								store.dispatch(peerActions.addPeer(
									{
										...parentRoomPeer,
										consumers : []
									}
								));
							}
							else
							{
								store.dispatch(peerActions.addPeer(
									{ ...peerExists,
										...parentRoomPeer,
										consumers : parentRoom.id === parentRoomPeer.roomId ? peerExists.consumers : []
									}
								));
							}

							store.dispatch(
								roomManagementActions.addRoom(parentRoom)
							);
						});

						break;
					}

					case 'moderator:setAllowSelfMoving':
					{
						const { allowSelfMoving } = notification.data;

						store.dispatch(
							roomActions.setAllowSelfMoving(allowSelfMoving));

						break;
					}

					case 'moderator:startVideo':
					{
						this.updateWebcam({ start: true });

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'moderator.startVideo',
									defaultMessage : 'Moderator started your video'
								})
							}));

						break;
					}

					case 'moderator:stopVideo':
					{
						if (this._webcamProducer && !this._webcamProducer.paused)
						{
							const { displayName } = notification.data;

							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'moderator.muteVideoSpecific',
										defaultMessage : '{displayName} stopped your video'
									}, {
										displayName
									})
								}));

							this.disableWebcam();
						}

						break;
					}

					case 'moderator:changeAudioCodec':
					{
						const { audioCodec } = notification.data;

						this.updateMic({ start: true, forceAudioCodec: audioCodec });

						break;
					}

					case 'moderator:stopScreenSharing':
					{
						this.disableScreenSharing();

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'moderator.stopScreenSharing',
									defaultMessage : 'Moderator stopped your screen sharing'
								})
							}));

						break;
					}

					case 'moderator:setConsumerPreferredLayersLocked':
					{
						const {
							consumerPreferredLayersLocked,
							temporalLayer,
							spatialLayer
						} = notification.data;

						// lock layers
						store.dispatch(
							roomActions.setConsumerPreferredLayersLocked(
								consumerPreferredLayersLocked
							)
						);

						// directly set consumer preferred layers
						if (temporalLayer && spatialLayer)
						{
							for (const consumer of this._consumers.values())
							{
								store.dispatch(consumerActions.setConsumerPreferredLayers(
									consumer.id, spatialLayer, temporalLayer));
							}
						}
						else
						{
							await this.updateConsumerPreferredLayers();
						}

						break;
					}

					case 'moderator:kick':
					{
						// Need some feedback
						this.close();

						break;
					}

					case 'moderator:goToUrl':
					{
						const { urlToGoTo } = notification.data;

						window.open(urlToGoTo, '_self');

						break;
					}

					case 'moderator:lowerHand':
					{
						this.setRaisedHand(false);

						break;
					}

					case 'gotRole':
					{
						const { peerId, roleId } = notification.data;

						const userRoles = store.getState().room.userRoles;

						if (peerId === this._peerId)
						{
							store.dispatch(meActions.addRole(roleId));

							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'roles.gotRole',
										defaultMessage : 'You got the role: {role}'
									}, {
										role : userRoles.get(roleId).label
									})
								}));
						}
						else
							store.dispatch(peerActions.addPeerRole(peerId, roleId));

						break;
					}

					case 'lostRole':
					{
						const { peerId, roleId } = notification.data;

						const userRoles = store.getState().room.userRoles;

						if (peerId === this._peerId)
						{
							store.dispatch(meActions.removeRole(roleId));

							store.dispatch(requestActions.notify(
								{
									text : intl.formatMessage({
										id             : 'roles.lostRole',
										defaultMessage : 'You lost the role: {role}'
									}, {
										role : userRoles.get(roleId).label
									})
								}));
						}
						else
							store.dispatch(peerActions.removePeerRole(peerId, roleId));

						break;
					}

					case 'customFieldUpdate':
					{
						const { peerId, fieldKey, fieldValue } = notification.data;

						if (peerId === this._peerId)
						{
							store.dispatch(meActions.setCustomField(fieldKey, fieldValue));
						}
						else
							store.dispatch(peerActions.setPeerCustomField(peerId, fieldKey, fieldValue));

						break;
					}

					case 'customFieldDeleted':
					{
						const { peerId, fieldKey } = notification.data;

						if (peerId === this._peerId)
						{
							store.dispatch(meActions.deleteCustomField(fieldKey));
						}
						else
							store.dispatch(peerActions.deletePeerCustomField(peerId, fieldKey));

						break;
					}

					case 'sendScreenshotsOfSelf':
					{
						const { peerId, width, height } = notification.data;

						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.sendScreenshotOfSelf',
									defaultMessage : 'Making a selfie'
								})
							}));

						this.sendScreenshotOfSelf(peerId, width, height);

						break;
					}

					case 'moderator:setSlackIntegrationSucceded':
					{
						store.dispatch(requestActions.notify(
							{
								text : intl.formatMessage({
									id             : 'room.setSlackIntegrationSucceded',
									defaultMessage : 'Succesfully added Slack integration to this room'
								})
							}));

						break;
					}

					case 'sendingScreenshotData':
					{
						const { screenshot } = notification.data;

						if (!this.selfies.includes(screenshot))
							this.selfies.push(screenshot);

						break;
					}

					case 'setLikes':
					{
						const { likes } = notification.data;

						store.dispatch(
							likesActions.setCount(likes));

						break;
					}

					default:
					{
						logger.error(
							'unknown notification.method "%s"', notification.method);
					}
				}
			}
			catch (error)
			{
				logger.error('error on socket "notification" event [error:"%o"]', error);

				store.dispatch(requestActions.notify(
					{
						type : 'error',
						text : intl.formatMessage({
							id             : 'socket.requestError',
							defaultMessage : 'Error on server request'
						})
					}));
			}

		});
	}

	async loadMediasoupClientDevices()
	{
		this._mediasoupDevice = new mediasoupClient.Device();

		const routerRtpCapabilities =
			await this.sendRequest('getRouterRtpCapabilities');

		routerRtpCapabilities.headerExtensions = routerRtpCapabilities.headerExtensions
			.filter((ext) => ext.uri !== 'urn:3gpp:video-orientation');

		const clientDevice = deviceInfo();

		routerRtpCapabilities.codecs = routerRtpCapabilities.codecs
			.filter((codec) =>
			{
				if (clientDevice.flag === 'firefox')
				{
					return codec.mimeType.toLowerCase() !== 'video/vp9';
				}

				return true;
			});

		this._mediasoupDevice.load({ routerRtpCapabilities });
	}

	async _joinRoom({ joinVideo, joinAudio = true })
	{
		logger.debug('_joinRoom()');

		document.title = `Club - ${this._roomId}${this._subRoomId ? ` - ${this._subRoomId}` : ''}`;

		const { displayName } = store.getState().settings;
		const { picture } = store.getState().me;

		try
		{
			await this.loadMediasoupClientDevices();

			this._torrentSupport = WebTorrent.WEBRTC_SUPPORT;

			this._webTorrent = this._torrentSupport && new WebTorrent({
				tracker : {
					rtcConfig : {
						iceServers : this._turnServers
					}
				}
			});

			this._webTorrent.on('error', (error) =>
			{
				logger.error('Filesharing [error:"%o"]', error);

				store.dispatch(requestActions.notify(
					{
						type : 'error',
						text : intl.formatMessage({
							id             : 'filesharing.error',
							defaultMessage : 'There was a filesharing error'
						})
					}));
			});

			if (this._produce)
			{
				const transportInfo = await this.sendRequest(
					'createWebRtcTransport',
					{
						forceTcp  : this._forceTcp,
						producing : true,
						consuming : false
					});

				const {
					id,
					iceParameters,
					iceCandidates,
					dtlsParameters
				} = transportInfo;

				this._sendTransport = this._mediasoupDevice.createSendTransport(
					{
						id,
						iceParameters,
						iceCandidates,
						dtlsParameters,
						iceServers             : this._turnServers,
						proprietaryConstraints : PC_PROPRIETARY_CONSTRAINTS
					});

				this._sendTransport.on(
					'connect', ({ dtlsParameters }, callback, errback) => // eslint-disable-line no-shadow
					{
						this.sendRequest(
							'connectWebRtcTransport',
							{
								transportId : this._sendTransport.id,
								dtlsParameters
							})
							.then(callback)
							.catch(errback);
					});

				this._sendTransport.on(
					'connectionstatechange', (connectState) =>
					{
						switch (connectState)
						{
							case 'disconnected':
							case 'failed':
								this.restartIce(this._sendTransport, this._sendRestartIce, 7500);
								break;

							default:
								clearTimeout(this._sendRestartIce.timer);
								break;
						}
					});

				this._sendTransport.on(
					'produce', async ({ kind, rtpParameters, appData }, callback, errback) =>
					{
						try
						{
							// eslint-disable-next-line no-shadow
							const { id } = await this.sendRequest(
								'produce',
								{
									transportId : this._sendTransport.id,
									kind,
									rtpParameters,
									appData
								});

							callback({ id });
						}
						catch (error)
						{
							errback(error);
						}
					});
			}

			const transportInfo = await this.sendRequest(
				'createWebRtcTransport',
				{
					forceTcp  : this._forceTcp,
					producing : false,
					consuming : true
				});

			const {
				id,
				iceParameters,
				iceCandidates,
				dtlsParameters
			} = transportInfo;

			this._recvTransport = this._mediasoupDevice.createRecvTransport(
				{
					id,
					iceParameters,
					iceCandidates,
					dtlsParameters,
					iceServers : this._turnServers
				});

			this._recvTransport.on(
				'connect', ({ dtlsParameters }, callback, errback) => // eslint-disable-line no-shadow
				{
					this.sendRequest(
						'connectWebRtcTransport',
						{
							transportId : this._recvTransport.id,
							dtlsParameters
						})
						.then(callback)
						.catch(errback);
				});

			this._recvTransport.on(
				'connectionstatechange', (connectState) =>
				{
					switch (connectState)
					{
						case 'disconnected':
						case 'failed':
							this.restartIce(this._recvTransport, this._recvRestartIce, 7500);
							break;

						default:
							clearTimeout(this._recvRestartIce.timer);
							break;
					}
				});

			// Set our media capabilities.
			store.dispatch(meActions.setMediaCapabilities(
				{
					canSendMic     : this._mediasoupDevice.canProduce('audio'),
					canSendWebcam  : this._mediasoupDevice.canProduce('video'),
					canShareScreen : this._mediasoupDevice.canProduce('video') &&
						this._screenSharing.isScreenShareAvailable(),
					canShareFiles : false // this._torrentSupport
				}));

			const {
				authenticated,
				roles,
				peers,
				tracker,
				roomPermissions,
				userRoles,
				allowWhenRoleMissing,
				chatHistory,
				fileHistory,
				lastNHistory,
				locked,
				lobbyPeers,
				accessCode,
				allowSelfMoving,
				autoDistributePeers,
				autoDistributeCreateRooms,
				autoDistributeStrategy,
				persistRoom,
				subRooms,
				parentRoom,
				alwaysVisiblePeers,
				iframeUrlSource,
				drawingTogether,
				playingMusic,
				musicPlayIndex,
				musicPlayback,
				musicVolume,
				writingTogether,
				game,
				friendlyRoomName,
				friendlyRoomDescription,
				timestamp,
				polls,
				backgroundImage,
				likes,
				preferredVideoCodec,
				forcedLayout,
				consumerPreferredLayersLocked,
				roomMode,
				colorString,
				autoCodecSwitching,
				broadcasters
			} = await this.sendRequest(
				'join',
				{
					deviceInfo      : deviceInfo(),
					displayName     : displayName,
					picture         : picture,
					rtpCapabilities : this._mediasoupDevice.rtpCapabilities
				});

			store.dispatch(roomActions.setRoomMode(roomMode));

			if (colorString)
			{
				store.dispatch(
					colorActions.setRoomColors(colorString)
				);

				const { themes } = store.getState().theme;

				store.dispatch(
					themeActions.setTheme(themes.find((theme) => theme.name === 'room'))
				);
			}

			store.dispatch(
				roomActions.setAutoCodecSwitching(autoCodecSwitching)
			);

			store.dispatch(
				roomActions.setConsumerPreferredLayersLocked(consumerPreferredLayersLocked));

			// set available codecs for remote-changing
			store.dispatch(settingsActions.setAvailableVideoCodecs(
				this._mediasoupDevice
					.rtpCapabilities.codecs.filter((f) => f.kind === 'video' && f.mimeType.toLowerCase() !== 'video/rtx')
			));

			store.dispatch(settingsActions.setAvailableAudioCodecs(
				this._mediasoupDevice
					.rtpCapabilities.codecs.filter((f) => f.kind === 'audio')
			));

			logger.debug(
				'_joinRoom() joined [authenticated:"%s", peers:"%o", roles:"%o", userRoles:"%o"]',
				authenticated,
				peers,
				roles,
				userRoles
			);

			tracker && (this._tracker = tracker);

			store.dispatch(roomActions.setRoomAutoDistribute(autoDistributePeers));
			store.dispatch(roomActions.setAutoDistributeCreateRooms(autoDistributeCreateRooms));
			store.dispatch(roomActions.setAutoDistributeStrategy(autoDistributeStrategy));

			store.dispatch(settingsActions.setVideoCodec(preferredVideoCodec));
			store.dispatch(roomActions.setForcedLayout(forcedLayout));

			store.dispatch(roomActions.setPersistRoom(persistRoom));

			store.dispatch(meActions.loggedIn(authenticated));

			store.dispatch(
				roomActions.setParentRoom(parentRoom));

			store.dispatch(roomActions.setRoomPermissions(roomPermissions));

			this._spotlights.setAlwaysVisibleSpotlightPeers(alwaysVisiblePeers);

			store.dispatch(
				roomActions.setAllowSelfMoving(allowSelfMoving));

			const roomUserRoles = new Map();

			Object.entries(userRoles).forEach(([ , val ]) => roomUserRoles.set(val.id, val)); // eslint-disable-line no-unused-vars

			store.dispatch(roomActions.setUserRoles(roomUserRoles));

			if (allowWhenRoleMissing)
				store.dispatch(roomActions.setAllowWhenRoleMissing(allowWhenRoleMissing));

			const myRoles = store.getState().me.roles;

			for (const roleId of roles)
			{
				if (!myRoles.some((myRoleId) => roleId === myRoleId))
				{
					store.dispatch(meActions.addRole(roleId));

					store.dispatch(requestActions.notify(
						{
							text : intl.formatMessage({
								id             : 'roles.gotRole',
								defaultMessage : 'You got the role: {role}'
							}, {
								role : roomUserRoles.get(roleId).label
							})
						}));
				}
			}

			for (const peer of peers)
			{
				store.dispatch(
					peerActions.addPeer({ ...peer, consumers: [] }));
			}

			for (const broadcaster of broadcasters)
			{
				store.dispatch(
					broadcasterActions.addBroadcaster({ ...broadcaster, consumers: [] }));
			}

			if (chatHistory.length > 0)
			{
				store.dispatch(chatActions.addChatHistory(chatHistory));
				store.dispatch(chatDrawerActions.openChatDrawer());
				store.dispatch(chatDrawerActions.setChatDrawerTab('chat'));
			}

			(fileHistory.length > 0) && store.dispatch(
				fileActions.addFileHistory(fileHistory));

			locked ?
				store.dispatch(roomActions.setRoomLocked()) :
				store.dispatch(roomActions.setRoomUnLocked());

			(lobbyPeers.length > 0) && lobbyPeers.forEach((peer) =>
			{
				store.dispatch(
					lobbyPeerActions.addLobbyPeer(peer.id));
				store.dispatch(
					lobbyPeerActions.setLobbyPeerDisplayName(peer.displayName, peer.id));
				store.dispatch(
					lobbyPeerActions.setLobbyPeerPicture(peer.picture, peer.id));
			});

			subRooms.forEach((subroom) =>
			{
				store.dispatch(
					roomManagementActions.addRoom(subroom)
				);
			});

			(accessCode != null) && store.dispatch(
				roomActions.setAccessCode(accessCode));

			// Don't produce if explicitly requested to not to do it.
			if (this._produce)
			{
				if (
					joinVideo &&
					this._havePermission(permissions.SHARE_VIDEO)
				)
				{
					this.updateWebcam({ init: true, start: true, newVideoCodec: preferredVideoCodec });
				}
				if (
					joinAudio &&
					this._mediasoupDevice.canProduce('audio') &&
					this._havePermission(permissions.SHARE_AUDIO)
				)
				{
					if (!this._muted)
					{
						await this.updateMic({ start: true });
						let autoMuteThreshold = 4;

						if ('autoMuteThreshold' in window.config)
						{
							autoMuteThreshold = window.config.autoMuteThreshold;
						}
						if (autoMuteThreshold >= 0 && peers.length >= autoMuteThreshold)
							this.muteMic();
					}
				}
			}

			await this._updateAudioOutputDevices();

			store.dispatch(roomActions.setRoomState('connected'));

			// Clean all the existing notifications.
			store.dispatch(notificationActions.removeAllNotifications());

			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'room.joined',
						defaultMessage : 'You have joined the room'
					})
				}));

			this._spotlights.addPeers(peers);

			if (lastNHistory.length > 0)
			{
				logger.debug('_joinRoom() | got lastN history');

				this._spotlights.addSpeakerList(
					lastNHistory.filter((peerId) => peerId !== this._peerId)
				);
			}

			if (iframeUrlSource)
			{
				this.setIframeUrlSource(iframeUrlSource);
			}

			if (drawingTogether)
			{
				this.setDrawingTogether(drawingTogether);
			}

			if (writingTogether)
			{
				this.setWritingTogether(writingTogether);
			}

			if (timestamp)
			{
				store.dispatch(
					roomActions.modifyCountdownTimer(timestamp));
			}

			if (friendlyRoomName)
			{
				store.dispatch(roomActions.setFriendlyRoomName(friendlyRoomName));
				store.dispatch(roomManagementActions.setFriendlyRoomName(this._subRoomId ? this._subRoomId : this._roomId, friendlyRoomName));
			}
			else
			{
				store.dispatch(roomActions.setFriendlyRoomName(''));
				store.dispatch(roomManagementActions.setFriendlyRoomName(this._subRoomId ? this._subRoomId : this._roomId, ''));
			}

			if (friendlyRoomDescription)
			{
				store.dispatch(roomActions.setFriendlyRoomDescription(friendlyRoomDescription));
				store.dispatch(roomManagementActions.setFriendlyRoomDescription(this._subRoomId ? this._subRoomId : this._roomId, friendlyRoomDescription));
			}
			else
			{
				store.dispatch(roomActions.setFriendlyRoomDescription(''));
				store.dispatch(roomManagementActions.setFriendlyRoomDescription(this._subRoomId ? this._subRoomId : this._roomId, ''));
			}

			if (playingMusic)
			{
				store.dispatch(musicActions.setShowMusicPlayer(playingMusic));
			}

			if (musicPlayIndex)
			{
				store.dispatch(musicActions.setPlayIndex(musicPlayIndex));
			}

			if (musicPlayback !== null)
			{
				store.dispatch(musicActions.setPlayback(musicPlayback));
			}

			if (musicVolume !== null)
			{
				store.dispatch(musicActions.setMusicVolume(musicVolume));
			}

			if (polls)
			{
				this.setPolls(polls);

				if (polls && polls.length && polls.filter((pollItem) => pollItem.kind !== 'overlay').some((pollItem) => pollItem.status === 'active'))
				{
					store.dispatch(chatDrawerActions.openChatDrawer());
					store.dispatch(chatDrawerActions.setChatDrawerTab('polls'));
				}
			}

			store.dispatch(roomActions.setPlayGame(game.chapter, game.video));

			store.dispatch(roomActions.setBackgroundImage(backgroundImage));

			store.dispatch(
				likesActions.setCount(likes));

		}
		catch (error)
		{
			logger.error('_joinRoom() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'room.cantJoin',
						defaultMessage : 'Unable to join the room'
					})
				}));

			this.close();
		}
	}

	async setVideoCodecLock(peerId, locked)
	{
		logger.debug('setVideoCodecLock(%s, %s)', peerId, locked);

		try
		{
			await this.sendRequest('moderator:setVideoCodecLock',
				{
					peerId,
					lockedVideoCodec : locked
				});
		}
		catch (error)
		{
			logger.error('setVideoCodecLock() [error:"%o"]', error);
		}
	}

	async allPeersBackToMainRoom()
	{
		logger.debug('allPeersBackToMainRoom()');

		try
		{
			await this.sendRequest('moderator:moveAllPeersToMainRoom');
			store.dispatch(requestActions.notify(
				{
					text : 'Moving everyone back to main room...'
				}));
		}
		catch (error)
		{
			logger.error('allPeersBackToMainRoom() [error:"%o"]', error);
			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : 'Unable to move everyone back to main room.'
				}));
		}
	}

	async sendSubRoomBackToMainRoom(subRoomId)
	{
		logger.debug('sendSubRoomBackToMainRoom()');

		try
		{
			await this.sendRequest('moderator:moveAllSubRoomPeersToMainRoom', { subRoomId });
			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'room.moveFromSubRoomToMainRoom',
						defaultMessage : 'Moving everyone from room {subRoomId} back to main room...'
					}, {
						subRoomId
					})
				}));
		}
		catch (error)
		{
			logger.error('sendSubRoomBackToMainRoom() [error:"%o"]', error);
			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'room.unableToMoveFromSubRoomToMainRoom',
						defaultMessage : 'Unable to move everyone from room {subRoomId} back to main room...'
					}, {
						subRoomId
					})
				}));
		}
	}

	async closeSubRoom(subRoomId)
	{
		logger.debug('closeSubRoom() "%s"', subRoomId);

		try
		{
			await this.sendRequest('moderator:closeRoom', { subRoomId });
		}
		catch (error)
		{
			logger.error('closeSubRoom() [error: "%o"]', error);
		}
	}

	async changeAudioCodec(audioCodec)
	{
		logger.debug('changeAudioCodec() "%s"', audioCodec);

		try
		{
			await this.sendRequest('moderator:changeAudioCodec', { audioCodec });
		}
		catch (error)
		{
			logger.error('changeAudioCodec() [error: "%o"]', error);
		}
	}

	async startRoomRecord()
	{
		logger.debug('startRoomRecord()');

		try
		{
			store.dispatch(roomActions.setRoomRecord(true));

			const mc = {
				video : true,
				audio : true
			};

			const displayMedia = window.navigator.mediaDevices.getDisplayMedia ?? navigator.getDisplayMedia;

			if (displayMedia)
			{
				displayMedia(mc).then(async (stream) =>
				{
					const recorder = RecordRTC(stream, {
						type : 'video'
					});

					this._recorder = recorder;

					recorder.startRecording();
				});
			}

			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'room.youstartedrecording',
						defaultMessage : 'You started recording the room'
					})
				}));
		}
		catch (error)
		{
			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'room.cantRecord',
						defaultMessage : 'Unable to record the room'
					})
				}));
			logger.error('startRoomRecord() [error:"%o"]', error);
		}
	}

	async stopRoomRecord()
	{
		logger.debug('stopRoomRecord()');

		try
		{
			store.dispatch(roomActions.setRoomRecord(false));

			if (this._recorder)
			{
				this._recorder.stopRecording(() =>
				{
					const blob = this._recorder.getBlob();

					this._recorder = null;

					RecordRTC.invokeSaveAsDialog(blob, 'save.mp4');
				});
			}

			store.dispatch(requestActions.notify(
				{
					text : intl.formatMessage({
						id             : 'room.youstoppedrecording',
						defaultMessage : 'You stopped recording the room'
					})
				}));
		}
		catch (error)
		{
			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'room.cantRecord',
						defaultMessage : 'Unable to record the room'
					})
				}));

			logger.error('stopRoomRecord() [error:"%o"]', error);
		}
	}

	async setPeerCustomField(peerId, key, value)
	{
		logger.debug('setPeerCustomField("%s", "%s", "%s")', peerId, key, value);

		try
		{
			await this.sendRequest('moderator:updateCustomField', {
				peerId,
				key,
				value
			});
		}
		catch (error)
		{
			logger.error('setPeerCustomField() [error: "%o"]', error);
		}
	}

	async deletePeerCustomField(peerId, key)
	{
		logger.debug('deletePeerCustomField("%s", "%s", "%s")', peerId, key);

		try
		{
			await this.sendRequest('moderator:deleteCustomField', {
				peerId,
				key
			});
		}
		catch (error)
		{
			logger.error('deletePeerCustomField() [error: "%o"]', error);
		}
	}

	async lockRoom({ roomId, subRoomId } = {})
	{
		logger.debug('lockRoom()');

		try
		{
			await this.sendRequest('lockRoom', { roomId, subRoomId });

			if (!roomId && !subRoomId)
			{
				store.dispatch(
					roomActions.setRoomLocked());

				store.dispatch(requestActions.notify(
					{
						text : intl.formatMessage({
							id             : 'room.youLocked',
							defaultMessage : 'You locked the room'
						})
					}));
			}
		}
		catch (error)
		{
			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'room.cantLock',
						defaultMessage : 'Unable to lock the room'
					})
				}));

			logger.error('lockRoom() [error:"%o"]', error);
		}
	}

	async unlockRoom({ roomId, subRoomId } = {})
	{
		logger.debug('unlockRoom()');

		try
		{
			await this.sendRequest('unlockRoom', { roomId, subRoomId });

			if (!roomId && !subRoomId)
			{
				store.dispatch(
					roomActions.setRoomUnLocked());

				store.dispatch(requestActions.notify(
					{
						text : intl.formatMessage({
							id             : 'room.youUnLocked',
							defaultMessage : 'You unlocked the room'
						})
					}));
			}
		}
		catch (error)
		{
			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'room.cantUnLock',
						defaultMessage : 'Unable to unlock the room'
					})
				}));

			logger.error('unlockRoom() [error:"%o"]', error);
		}
	}

	getPreferredSpatialLayers(consumer)
	{
		if (!consumer)
			return {
				spatialLayer  : 2,
				temporalLayer : 2
			};

		const consBasedLayers = this.getConsumerCountBasedConsumerLayers(consumer);

		if (consBasedLayers.prioritize)
		{
			logger.debug('getPreferredSpatialLayers(%s) preferred consumer amount based layering', consumer.id);

			return {
				spatialLayer  : consBasedLayers.spatialLayer,
				temporalLayer : consBasedLayers.temporalLayer
			};
		}

		const dimBasedLayers = this.getDimensionBasedConsumerLayers(consumer);

		if (dimBasedLayers.prioritize)
		{
			logger.debug('getPreferredSpatialLayers(%s) preferred dimension-based layering', consumer.id);

			return {
				spatialLayer  : dimBasedLayers.spatialLayer,
				temporalLayer : dimBasedLayers.temporalLayer
			};
		}

		logger.debug('getPreferredSpatialLayers(%s)', consumer.id);

		return {
			spatialLayer  : Math.min(dimBasedLayers.spatialLayer, consBasedLayers.spatialLayer),
			temporalLayer : Math.min(dimBasedLayers.temporalLayer, consBasedLayers.temporalLayer)
		};
	}

	updateConsumerPreferredLayers = simpleDebounce(
		(({
			spatialLayer,
			temporalLayer
		} = {}) =>
		{
			for (const consumer of this._consumers.values())
			{
				if (consumer.kind === 'video')
				{
					this.updateSingleConsumerPreferredLayers({
						consumerId : consumer.id,
						spatialLayer,
						temporalLayer
					});
				}
			}
		}),
		5000);

	async updateSingleConsumerPreferredLayers({
		consumerId,
		spatialLayer,
		temporalLayer
	})
	{
		const {
			consumerPreferredLayersLocked
		} = store.getState().room;

		if (consumerPreferredLayersLocked)
		{
			return;
		}

		logger.debug('updateSingleConsumerPreferredLayers(%s)', consumerId);

		const consumer = this._consumers.get(consumerId);
		const clientConsumer = store.getState().consumers[consumerId];

		if (!clientConsumer || !consumer || !consumer.appData)
			return;

		let targetSpatialLayer;
		let targetTemporalLayer;

		const numberOfPeers = Object.values(store.getState().peers).length;

		// if number of peers are lower than defined in config, always set layers to max
		if (numberOfPeers <= window.config.peersBeforeLayerScaleStart)
		{
			targetSpatialLayer = 2;
			targetTemporalLayer = 2;
		}
		else
		{
			const {
				spatialLayer: automaticSpatialLayer,
				temporalLayer: automaticTemporalLayer
			} = this.getPreferredSpatialLayers(consumer);

			targetSpatialLayer = Math.min(spatialLayer ?? 2, automaticSpatialLayer);

			targetTemporalLayer = Math.min(temporalLayer ?? 2, automaticTemporalLayer);
		}

		if (clientConsumer.preferredSpatialLayer !== targetSpatialLayer ||
			clientConsumer.preferredTemporalLayer !== targetTemporalLayer)
		{
			this.setConsumerPreferredLayers(consumer.id, targetSpatialLayer, targetTemporalLayer);
		}
	}

	getConsumerCountBasedConsumerLayers(consumer)
	{
		const {
			spotlights
		} = store.getState().room;

		const orderedSpotlightArray = Array.from(spotlights);

		const peerSpotlightIndex = orderedSpotlightArray
			.findIndex((peerId) => peerId === consumer.appData.peerId);

		let spatialLayer; // 2 = max resolution, 0 = lowest resolution

		let temporalLayer; // 2 = max framerate, 0 = lowest framerate

		let prioritize = false;

		logger.debug(
			'getPreferredSpatialLayers(consumer %s)',
			consumer.appData.peerId,
			peerSpotlightIndex,
			orderedSpotlightArray);

		if (peerSpotlightIndex !== -1 && peerSpotlightIndex < 2)
		{
			// peer is in spotlight and one of the prominent speakers, so set to highest quality
			spatialLayer = 2;
			temporalLayer = 2;
			prioritize = true;
		}
		else
		{
			const spotlightsLength = spotlights.length;

			if (spotlightsLength < 6)
			{
				spatialLayer = 1;
				temporalLayer = 2;
			}
			else if (spotlightsLength >= 6 && spotlightsLength <= 20)
			{
				spatialLayer = 1;
				temporalLayer = 1;
			}
			else
			{
				spatialLayer = 0;
				temporalLayer = 1;
			}
		}

		logger.debug('getConsumerCountBasedConsumerLayers', spatialLayer, temporalLayer);

		return {
			spatialLayer,
			temporalLayer,
			prioritize
		};
	}

	getDimensionBasedConsumerLayers(consumer)
	{
		const {
			appData
		} = consumer;

		const consumers = store.getState().consumers;

		const {
			vpWidth,
			vpHeight,
			temporalLayers
		} = consumers[consumer.id];

		const { width, height, resolutionScalings } = appData;

		const adaptiveScalingFactor = Math.min(Math.max(
			window.config.adaptiveScalingFactor || 0.75, 0.5), 1.0);

		const prioritize = false;

		let spatialLayer = 0;

		if (resolutionScalings)
		{
			for (let i = 0; i < resolutionScalings.length; i++)
			{
				const levelWidth = adaptiveScalingFactor * width / resolutionScalings[i];
				const levelHeight = adaptiveScalingFactor * height / resolutionScalings[i];

				if (vpWidth >= levelWidth || vpHeight >= levelHeight)
				{
					spatialLayer = i;
				}
				else
				{
					break;
				}
			}
		}

		let temporalLayer = temporalLayers - 1;

		if (resolutionScalings)
		{
			if (spatialLayer === 0 && temporalLayer > 0)
			{
				const lowestLevelWidth = width / resolutionScalings[0];
				const lowestLevelHeight = height / resolutionScalings[0];

				if (vpWidth < lowestLevelWidth * 0.5
					&& vpHeight < lowestLevelHeight * 0.5)
				{
					temporalLayer -= 1;
				}
				if (temporalLayer > 0
					&& vpWidth < lowestLevelWidth * 0.25
					&& vpHeight < lowestLevelHeight * 0.25)
				{
					temporalLayer -= 1;
				}
			}
		}

		logger.debug('getDimensionBasedConsumerLayers', spatialLayer, temporalLayer);

		return {
			spatialLayer,
			temporalLayer,
			prioritize
		};
	}

	async setAccessCode(code)
	{
		logger.debug('setAccessCode()');

		try
		{
			await this.sendRequest('setAccessCode', { accessCode: code });

			store.dispatch(
				roomActions.setAccessCode(code));

			store.dispatch(requestActions.notify(
				{
					text : 'Access code saved.'
				}));
		}
		catch (error)
		{
			logger.error('setAccessCode() [error:"%o"]', error);
			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : 'Unable to set access code.'
				}));
		}
	}

	async setJoinByAccessCode(value)
	{
		logger.debug('setJoinByAccessCode()');

		try
		{
			await this.sendRequest('setJoinByAccessCode', { joinByAccessCode: value });

			store.dispatch(
				roomActions.setJoinByAccessCode(value));

			store.dispatch(requestActions.notify(
				{
					text : `You switched Join by access-code to ${value}`
				}));
		}
		catch (error)
		{
			logger.error('setAccessCode() [error:"%o"]', error);
			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : 'Unable to set join by access code.'
				}));
		}
	}

	async addExtraVideo(videoDeviceId)
	{
		logger.debug(
			'addExtraVideo() [videoDeviceId:"%s"]',
			videoDeviceId
		);

		store.dispatch(
			roomActions.setExtraVideoOpen(false));

		if (!this._mediasoupDevice.canProduce('video'))
		{
			logger.error('addExtraVideo() | cannot produce video');

			return;
		}

		let track;

		store.dispatch(
			meActions.setWebcamInProgress(true));

		try
		{
			const device = this._webcams[videoDeviceId];
			const {
				resolution,
				videoCodec
			} = store.getState().settings;

			if (!device)
				throw new Error('no webcam devices');

			const stream = await navigator.mediaDevices.getUserMedia(
				{
					video :
					{
						deviceId : { ideal: videoDeviceId },
						...VIDEO_CONSTRAINTS[resolution]
					}
				});

			([ track ] = stream.getVideoTracks());

			const { width, height } = track.getSettings();

			let producer;

			if (this._useSimulcast)
			{
				const videoCodecToUse = this._mediasoupDevice
					.rtpCapabilities
					.codecs
					.find((c) =>
					{
						if (videoCodec)
						{
							return c.mimeType.toLowerCase() === videoCodec.toLowerCase();
						}

						return c.kind === 'video';
					});

				const encodings = this._getEncodings(width, height, videoCodecToUse);
				const resolutionScalings = getResolutionScalings(encodings);

				producer = await this._sendTransport.produce(
					{
						track,
						encodings,
						codec        : videoCodecToUse,
						codecOptions :
						{
							videoGoogleStartBitrate : 1000
						},
						appData :
						{
							source : 'extravideo',
							width,
							height,
							resolutionScalings
						}
					});
			}
			else
			{
				producer = await this._sendTransport.produce({
					track,
					appData :
					{
						source : 'extravideo'
					}
				});
			}

			this._extraVideoProducers.set(producer.id, producer);

			store.dispatch(producerActions.addProducer(
				{
					id            : producer.id,
					deviceLabel   : device.label,
					source        : 'extravideo',
					paused        : producer.paused,
					track         : producer.track,
					rtpParameters : producer.rtpParameters,
					codec         : producer.rtpParameters.codecs[0].mimeType.split('/')[1]
				}));

			// store.dispatch(settingsActions.setSelectedWebcamDevice(deviceId));

			await this._updateWebcams();

			producer.on('transportclose', () =>
			{
				this._extraVideoProducers.delete(producer.id);

				producer = null;
			});

			producer.on('trackended', () =>
			{
				store.dispatch(requestActions.notify(
					{
						type : 'error',
						text : intl.formatMessage({
							id             : 'devices.cameraDisconnected',
							defaultMessage : 'Camera disconnected'
						})
					}));

				this.disableExtraVideo(producer.id)
					.catch(() => {});
			});

			logger.debug('addExtraVideo() succeeded');
		}
		catch (error)
		{
			logger.error('addExtraVideo() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'devices.cameraError',
						defaultMessage : 'An error occurred while accessing your camera'
					})
				}));

			if (track)
				track.stop();
		}

		store.dispatch(
			meActions.setWebcamInProgress(false));
	}

	async disableMic()
	{
		logger.debug('disableMic()');

		if (!this._micProducer)
			return;

		store.dispatch(meActions.setAudioInProgress(true));

		this._micProducer.close();

		store.dispatch(
			producerActions.removeProducer(this._micProducer.id));

		try
		{
			await this.sendRequest(
				'closeProducer', { producerId: this._micProducer.id });
		}
		catch (error)
		{
			logger.error('disableMic() [error:"%o"]', error);
		}

		this._micProducer = null;

		store.dispatch(meActions.setAudioInProgress(false));
	}

	async selectRandomPerson()
	{
		logger.debug('selectRandomPerson()');

		try
		{
			await this.sendRequest('initiateRandomPersonSelect');
		}
		catch (error)
		{

			logger.error('selectRandomPerson() [error:"%o"]', error);
		}
	}

	async updateScreenSharing({
		start = false,
		newResolution = null,
		newFrameRate = null
	} = {})
	{
		logger.debug('updateScreenSharing() [start:"%s"]', start);

		let track;

		let audioTrack;

		try
		{
			const available = this._screenSharing.isScreenShareAvailable();

			if (!available)
				throw new Error('screen sharing not available');

			if (!this._mediasoupDevice.canProduce('video'))
				throw new Error('cannot produce video');

			if (newResolution)
				store.dispatch(settingsActions.setScreenSharingResolution(newResolution));

			if (newFrameRate)
				store.dispatch(settingsActions.setScreenSharingFrameRate(newFrameRate));

			store.dispatch(meActions.setScreenShareInProgress(true));

			const {
				screenSharingResolution,
				screenSharingFrameRate,
				videoCodec
			} = store.getState().settings;

			if (start)
			{
				const stream = await this._screenSharing.start({
					...VIDEO_CONSTRAINTS[screenSharingResolution],
					audio : {
						channelCount     : 1,
						autoGainControl  : false,
						echoCancellation : false,
						noiseSuppression : false,
						sampleSize       : 16,
						sampleRate       : 48000
					},
					frameRate : screenSharingFrameRate
				});

				track = stream.getVideoTracks()[0];

				const { width, height } = track.getSettings();

				audioTrack = stream.getAudioTracks()[0];

				const videoCodecToUse = this._mediasoupDevice
					.rtpCapabilities
					.codecs
					.find((c) =>
					{
						if (videoCodec)
						{
							return c.mimeType.toLowerCase() === videoCodec.toLowerCase();
						}

						return c.kind === 'video';
					});

				if (this._useSharingSimulcast)
				{
					let encodings = this._getEncodings(width, height, videoCodecToUse, true);

					if (videoCodecToUse.mimeType.toLowerCase() !== 'video/vp9')
					{
						encodings = encodings
							.map((encoding) => ({ ...encoding, dtx: true }));
					}

					this._screenSharingProducer = await this._sendTransport.produce(
						{
							track,
							encodings,
							codec        : videoCodecToUse,
							codecOptions :
							{
								videoGoogleStartBitrate : 1000
							},
							appData :
							{
								source : 'screen'
							}
						});
				}
				else
				{
					this._screenSharingProducer = await this._sendTransport.produce({
						track,
						codec   : videoCodecToUse,
						appData :
						{
							source : 'screen'
						}
					});

				}

				if (audioTrack)
				{
					this._screenSharingAudioProducer = await this._sendTransport.produce(
						{
							track        : audioTrack,
							codecOptions :
								{
									opusStereo          : false,
									opusDtx             : true,
									opusFec             : true,
									opusPtime           : 20,
									opusMaxPlaybackRate : 48000
								},
							appData :
								{
									source : 'screenAudio'
								}
						});

					store.dispatch(producerActions.addProducer(
						{
							id            : this._screenSharingAudioProducer.id,
							deviceLabel   : 'screenAudio',
							source        : 'screenAudio',
							paused        : this._screenSharingAudioProducer.paused,
							track         : this._screenSharingAudioProducer.track,
							rtpParameters : this._screenSharingAudioProducer.rtpParameters,
							codec         : this._screenSharingAudioProducer.rtpParameters.codecs[0].mimeType.split('/')[1]
						}));

					this._screenSharingAudioProducer.on('transportclose', () =>
					{
						this._screenSharingAudioProducer = null;
					});

					this._screenSharingAudioProducer.on('trackended', () =>
					{
						store.dispatch(requestActions.notify(
							{
								type : 'error',
								text : intl.formatMessage({
									id             : 'devices.screenSharingDisconnected',
									defaultMessage : 'Screen sharing audio disconnected'
								})
							}));
						this.disableScreenSharingAudio();
					});

					this._screenSharingAudioProducer.volume = 0;
				}

				store.dispatch(producerActions.addProducer(
					{
						id            : this._screenSharingProducer.id,
						deviceLabel   : 'screen',
						source        : 'screen',
						paused        : this._screenSharingProducer.paused,
						track         : this._screenSharingProducer.track,
						rtpParameters : this._screenSharingProducer.rtpParameters,
						codec         : this._screenSharingProducer.rtpParameters.codecs[0].mimeType.split('/')[1]
					}));

				this._screenSharingProducer.on('transportclose', () =>
				{
					this._screenSharingProducer = null;
				});

				this._screenSharingProducer.on('trackended', () =>
				{
					store.dispatch(requestActions.notify(
						{
							type : 'error',
							text : intl.formatMessage({
								id             : 'devices.screenSharingDisconnected',
								defaultMessage : 'Screen sharing disconnected'
							})
						}));

					this.disableScreenSharing();
				});
			}
			else if (this._screenSharingProducer)
			{
				({ track } = this._screenSharingProducer);

				await track.applyConstraints(
					{
						...VIDEO_CONSTRAINTS[screenSharingResolution],
						frameRate : screenSharingFrameRate
					}
				);
			}
		}
		catch (error)
		{
			logger.error('updateScreenSharing() [error:"%o"]', error);

			store.dispatch(requestActions.notify(
				{
					type : 'error',
					text : intl.formatMessage({
						id             : 'devices.screenSharingError',
						defaultMessage : 'An error occurred while accessing your screen'
					})
				}));

			if (track)
				track.stop();
		}

		store.dispatch(meActions.setScreenShareInProgress(false));
	}

	async disableScreenSharingAudio()
	{
		logger.debug('disableScreenSharingAudio()');

		if (!this._screenSharingAudioProducer)
			return;

		store.dispatch(meActions.setScreenShareInProgress(true));

		this._screenSharingAudioProducer.close();

		store.dispatch(
			producerActions.removeProducer(this._screenSharingAudioProducer.id));

		try
		{
			await this.sendRequest(
				'closeProducer', { producerId: this._screenSharingAudioProducer.id });
		}
		catch (error)
		{
			logger.error('disableScreenSharingAudio() [error:"%o"]', error);
		}

		this._screenSharingAudioProducer = null;

		store.dispatch(meActions.setScreenShareInProgress(false));
	}

	async disableScreenSharing()
	{
		logger.debug('disableScreenSharing()');

		if (!this._screenSharingProducer)
			return;

		store.dispatch(meActions.setScreenShareInProgress(true));

		this._screenSharingProducer.close();

		store.dispatch(
			producerActions.removeProducer(this._screenSharingProducer.id));

		try
		{
			await this.sendRequest(
				'closeProducer', { producerId: this._screenSharingProducer.id });
		}
		catch (error)
		{
			logger.error('disableScreenSharing() [error:"%o"]', error);
		}

		this._screenSharingProducer = null;

		this.disableScreenSharingAudio();

		this._screenSharing.stop();

		store.dispatch(meActions.setScreenShareInProgress(false));
	}

	async disableExtraVideo(id)
	{
		logger.debug('disableExtraVideo()');

		const producer = this._extraVideoProducers.get(id);

		if (!producer)
			return;

		store.dispatch(meActions.setWebcamInProgress(true));

		producer.close();

		store.dispatch(
			producerActions.removeProducer(id));

		try
		{
			await this.sendRequest(
				'closeProducer', { producerId: id });
		}
		catch (error)
		{
			logger.error('disableWebcam() [error:"%o"]', error);
		}

		this._extraVideoProducers.delete(id);

		store.dispatch(meActions.setWebcamInProgress(false));
	}

	async disableWebcam()
	{
		logger.debug('disableWebcam()');

		if (!this._webcamProducer)
			return;

		store.dispatch(meActions.setWebcamInProgress(true));

		this._webcamProducer.close();

		store.dispatch(
			producerActions.removeProducer(this._webcamProducer.id));

		try
		{
			await this.sendRequest(
				'closeProducer', { producerId: this._webcamProducer.id });
		}
		catch (error)
		{
			logger.error('disableWebcam() [error:"%o"]', error);
		}

		this._webcamProducer = null;
		store.dispatch(settingsActions.setVideoMuted(true));
		store.dispatch(meActions.setWebcamInProgress(false));
	}

	async setRoomTheme(colorString)
	{
		logger.debug('Setting room theme');

		try
		{
			await this.sendRequest('setRoomTheme', { colorString });
		}
		catch (error)
		{
			logger.error('setRoomTheme() [error:"%o"]', error);
		}
	}

	async _setNoiseThreshold(threshold)
	{
		logger.debug('_setNoiseThreshold() [threshold:"%s"]', threshold);

		if (!this._hark)
		{
			logger.error('_setNoiseThreshold() failed; hark not available');

			return;
		}

		this._hark.setThreshold(threshold);

		store.dispatch(
			settingsActions.setNoiseThreshold(threshold));
	}

	async _updateAudioDevices()
	{
		logger.debug('_updateAudioDevices()');

		// Reset the list.
		this._audioDevices = {};

		try
		{
			logger.debug('_updateAudioDevices() | calling enumerateDevices()');

			const devices = await navigator.mediaDevices.enumerateDevices();

			for (const device of devices)
			{
				if (device.kind !== 'audioinput')
					continue;

				this._audioDevices[device.deviceId] = device;
			}

			store.dispatch(
				meActions.setAudioDevices(this._audioDevices));
		}
		catch (error)
		{
			logger.error('_updateAudioDevices() [error:"%o"]', error);
		}
	}

	async _updateWebcams()
	{
		logger.debug('_updateWebcams()');

		// Reset the list.
		this._webcams = {};

		try
		{
			logger.debug('_updateWebcams() | calling enumerateDevices()');

			const devices = await navigator.mediaDevices.enumerateDevices();

			for (const device of devices)
			{
				if (device.kind !== 'videoinput')
					continue;

				this._webcams[device.deviceId] = device;
			}

			store.dispatch(
				meActions.setWebcamDevices(this._webcams));
		}
		catch (error)
		{
			logger.error('_updateWebcams() [error:"%o"]', error);
		}
	}

	async _getAudioDeviceId()
	{
		logger.debug('_getAudioDeviceId()');

		try
		{
			logger.debug('_getAudioDeviceId() | calling _updateAudioDeviceId()');

			await this._updateAudioDevices();

			const { selectedAudioDevice } = store.getState().settings;

			if (selectedAudioDevice && this._audioDevices[selectedAudioDevice])
			{
				return selectedAudioDevice;
			}
			else
			{
				const audioDevices = Object.values(this._audioDevices);

				return audioDevices[0] ? audioDevices[0].deviceId : null;
			}
		}
		catch (error)
		{
			logger.error('_getAudioDeviceId() [error:"%o"]', error);
		}
	}

	async _getWebcamDeviceId()
	{
		logger.debug('_getWebcamDeviceId()');

		try
		{
			logger.debug('_getWebcamDeviceId() | calling _updateWebcams()');

			await this._updateWebcams();

			const { selectedWebcam } = store.getState().settings;

			if (selectedWebcam && this._webcams[selectedWebcam])
				return selectedWebcam;
			else
			{
				const webcams = Object.values(this._webcams);

				return webcams[0] ? webcams[0].deviceId : null;
			}
		}
		catch (error)
		{
			logger.error('_getWebcamDeviceId() [error:"%o"]', error);
		}
	}

	async _updateAudioOutputDevices()
	{
		logger.debug('_updateAudioOutputDevices()');

		// Reset the list.
		this._audioOutputDevices = {};

		try
		{
			logger.debug('_updateAudioOutputDevices() | calling enumerateDevices()');

			const devices = await navigator.mediaDevices.enumerateDevices();

			for (const device of devices)
			{
				if (device.kind !== 'audiooutput')
					continue;

				this._audioOutputDevices[device.deviceId] = device;
			}

			store.dispatch(
				meActions.setAudioOutputDevices(this._audioOutputDevices));

			const { selectedAudioOutputDevice } = store.getState().settings;

			if (!selectedAudioOutputDevice && this._audioOutputDevices !== {})
			{
				store.dispatch(
					settingsActions.setSelectedAudioOutputDevice(
						Object.keys(this._audioOutputDevices)[0]
					)
				);
			}
		}
		catch (error)
		{
			logger.error('_updateAudioOutputDevices() [error:"%o"]', error);
		}
	}

	async setPreviewVideoMuted(previewVideoMuted)
	{
		logger.debug('setPreviewVideoMuted()');

		store.dispatch(settingsActions.setVideoMuted(previewVideoMuted));

		const { inLobby } = store.getState().room;

		if (inLobby)
		{
			try
			{
				await this.sendRequest('changePreviewVideoMuted', { previewVideoMuted });
			}
			catch (error)
			{
				logger.error('setPreviewVideoMuted() [error:"%o"]', error);
			}
		}
	}

	async setPreviewAudioMuted(previewAudioMuted)
	{
		logger.debug('setPreviewAudioMuted()');

		store.dispatch(settingsActions.setAudioMuted(previewAudioMuted));

		const { inLobby } = store.getState().room;

		if (inLobby)
		{
			try
			{
				await this.sendRequest('changePreviewAudioMuted', { previewAudioMuted });
			}
			catch (error)
			{
				logger.error('setPreviewAudioMuted() [error:"%o"]', error);
			}
		}
	}

	setIframeUrlSource(iframeUrlSource)
	{
		store.dispatch(roomActions.setDrawingTogether(false));

		store.dispatch(
			roomActions.setIFrameURL(iframeUrlSource));
	}

	doApplause()
	{
		store.dispatch(roomActions.setConfetti(true));
		clearTimeout(this._applauseTimeout);
		this._applauseTimeout = setTimeout(() =>
		{
			store.dispatch(roomActions.setConfetti(false));
			this._applauseTimeout = null;
		}, 1000 * 6);
	}

	doCricket()
	{
		store.dispatch(roomActions.setCricket(true));
		clearTimeout(this._cricketTimeout);
		if (this._soundCricket.paused)
			this._soundCricket.play();
		this._cricketTimeout = setTimeout(() =>
		{
			store.dispatch(roomActions.setCricket(false));
			this._cricketTimeout = null;
		}, 1000 * 6);
	}

	setDrawingTogether(drawingTogether)
	{
		store.dispatch(roomActions.setDrawingTogether(drawingTogether));

		if (drawingTogether)
		{
			const { displayName } = store.getState().settings;

			const roomIdToUse = encodeURIComponent(this._subRoomId ? `${this._roomId}-${this._subRoomId}` : this._roomId);

			store.dispatch(
				roomActions.setIFrameURL(`/club-whiteboard/?whiteboardid=${roomIdToUse}&username=${encodeURIComponent(displayName)}`));
		}
		else
		{
			store.dispatch(
				roomActions.setIFrameURL(''));
		}
	}

	setWritingTogether(writingTogether)
	{
		store.dispatch(roomActions.setWritingTogether(writingTogether));

		if (writingTogether)
		{
			const { displayName } = store.getState().settings;

			const roomIdToUse = encodeURIComponent(this._subRoomId ? `${this._roomId}-${this._subRoomId}` : this._roomId);

			store.dispatch(
				roomActions.setIFrameURL(`/club-pad/p/${roomIdToUse}?showChat=false&showLineNumbers=false&userName=${encodeURIComponent(displayName)}`));
		}
		else
		{
			store.dispatch(
				roomActions.setIFrameURL(''));
		}
	}

	setPolls(polls)
	{
		store.dispatch(pollActions.setPolls(polls));
	}

	_havePermission(permission)
	{
		const {
			roomPermissions,
			allowWhenRoleMissing
		} = store.getState().room;

		if (!roomPermissions)
			return false;

		const { roles } = store.getState().me;

		const permitted = roles.some((userRoleId) =>
			roomPermissions[permission].some((permissionRole) =>
				userRoleId === permissionRole.id
			)
		);

		if (permitted)
			return true;

		if (!allowWhenRoleMissing)
			return false;

		const peers = Object.values(store.getState().peers);

		// Allow if config is set, and no one is present
		if (allowWhenRoleMissing.includes(permission) &&
			peers.filter(
				(peer) =>
					peer.roles.some(
						(roleId) => roomPermissions[permission].some((permissionRole) =>
							roleId === permissionRole.id
						)
					)
			).length === 0
		)
			return true;

		return false;
	}

	_closeConsumer(consumerId)
	{
		const consumer = this._consumers.get(consumerId);

		this._spotlights.removeVideoConsumer(consumerId);

		if (!consumer)
			return;

		consumer.close();

		if (consumer.hark != null)
			consumer.hark.stop();

		this._consumers.delete(consumerId);

		const { peerId } = consumer.appData;

		store.dispatch(
			consumerActions.removeConsumer(consumerId, peerId));
	}

	setHideNoVideoParticipants(hideNoVideoParticipants)
	{
		this._spotlights.hideNoVideoParticipants = hideNoVideoParticipants;
	}

	_chooseEncodings(simulcastProfiles, size)
	{
		let encodings;

		const sortedMap = new Map([ ...Object.entries(simulcastProfiles) ]
			.sort((a, b) => parseInt(b[0]) - parseInt(a[0])));

		for (const [ key, value ] of sortedMap)
		{
			if (key < size)
			{
				if (encodings === null)
				{
					encodings = value;
				}

				break;
			}

			encodings = value;
		}

		if (encodings.length === 1)
		{
			encodings.push(encodings[0]);
		}

		return encodings;
	}

	_getEncodings(width, height, codec, screenSharing = false)
	{
		let encodings;

		const size = (width > height ? width : height);

		if (codec.mimeType.toLowerCase() === 'video/vp9')
			encodings = screenSharing ? VIDEO_SVC_ENCODINGS : VIDEO_KSVC_ENCODINGS;
		else if ('simulcastProfiles' in window.config)
			encodings = this._chooseEncodings(window.config.simulcastProfiles, size);
		else if ('simulcastEncodings' in window.config)
			encodings = window.config.simulcastEncodings;
		else
			encodings = this._chooseEncodings(VIDEO_SIMULCAST_PROFILES, size);

		return encodings;
	}

	getPeerViewLink(peer, screenshare = false)
	{
		const linkParts = [ window.location.origin, this._roomId, this._subRoomId ];

		const urlSearchParams = new URLSearchParams();

		urlSearchParams.set('name', peer.displayName);
		urlSearchParams.set('view', '1');
		urlSearchParams.set('produce', 'false');
		urlSearchParams.set('displayName', '_view_');
		urlSearchParams.set('forceView', screenshare ? 'screen' : 'video');

		return `${linkParts.filter((f) => Boolean(f)).join('/')}?${urlSearchParams.toString()}`;
	}
}
