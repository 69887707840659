export type SelectRandomPersonState = {
    showDialog: boolean;
    selectedPeer: any;
    inProgress: boolean;
}
const initialState: SelectRandomPersonState =
    {
    	showDialog   : false,
    	selectedPeer : null,
    	inProgress   : false
    };

const selectRandomPerson = (state = initialState, action: any) =>
{
	switch (action.type)
	{
		case 'SET_SHOW_DIALOG':
		{
			return { ...state, showDialog: action.payload.show };
		}

		case 'CLOSE_DIALOG':
		{
			return { ...state, showDialog: false };
		}

		case 'SET_SELECT_IN_PROGRESS': {
			return { ...state, inProgress: action.payload.inProgress };
		}

		case 'SET_SELECTED_PEER':
		{
			return { ...state, selectedPeer: action.payload.peerId };
		}

		case 'RESET_SELECTED_PEER': {
			return { ...state, selectedPeer: null };
		}

		default:
			return state;
	}
};

export default selectRandomPerson;