const getVideoConstraints = (videoAspectRatio: any): any =>
{
	return ({
		'low' :
			{
				width  : { ideal: 320 },
			 	height : { ideal: Math.round(320 / videoAspectRatio) }
			 },
		'mediumlow' : {
			width  : { ideal: 480 },
			height : { ideal: Math.round(480 / videoAspectRatio) }
		},
		'medium' : {
			width  : { ideal: 640 },
			height : { ideal: Math.round(640 / videoAspectRatio) }
		},
		'high' : {
			width  : { ideal: 960 },
			height : { ideal: Math.round(960 / videoAspectRatio) }
		},
		'highhd' : {
			width  : { ideal: 1280 },
			height : { ideal: Math.round(1280 / videoAspectRatio) }
		},
		'veryhigh' : {
			width  : { ideal: 1920 },
			height : { ideal: Math.round(1920 / videoAspectRatio) }
		},
		'ultra' : {
			width  : { ideal: 3840 },
			height : { ideal: Math.round(3840 / videoAspectRatio) }
		}
	});
};

export default getVideoConstraints;
