import React, { forwardRef, SyntheticEvent } from 'react';
import { Card, CardActions, makeStyles, Typography } from '@material-ui/core';
import * as chatDrawerActions from '../../actions/chatDrawerActions';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme: any) => ({
	root : {
		cursor                       : 'pointer',
		display                      : 'flex',
		flexWrap                     : 'wrap',
		flexGrow                     : 1,
		maxWidth                     : 288,
		[theme.breakpoints.up('sm')] : {
			flexGrow : 'initial',
			minWidth : 288
		}
	},
	card : {
		display         : 'block',
		backgroundColor : '#003767',
		width           : '100%',
		color           : 'white'
	},
	cardActions : {
		display : 'block'
	},
	textContainer : {
		margin               : 0,
		display              : '-webkit-box',
		overflow             : 'hidden',
		wordBreak            : 'break-word',
		'-webkit-line-clamp' : 4,
		'-webkit-box-orient' : 'vertical'
	},
	user : {
		fontWeight : 600
	}
}));

type DispatchProps = {
	openChat: () => void;
}

type OwnProps = {
	id: number;
	message: { name: string; message: string; };
}

type ChatSnackbarProps = OwnProps & DispatchProps;

// eslint-disable-next-line react/display-name
const ChatSnackbar = forwardRef<any, ChatSnackbarProps>((props: ChatSnackbarProps, ref: any) =>
{
	const classes = useStyles();
	const { closeSnackbar } = useSnackbar();

	const onClick = (e: SyntheticEvent<HTMLDivElement>) =>
	{
		e.preventDefault();
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
		props.openChat();
		closeSnackbar(props.id);
	};

	return (
		<div ref={ref} className={classes.root} onClick={onClick}>
			<Card className={classes.card}>
				<CardActions className={classes.cardActions} disableSpacing>
					<div className={classes.textContainer}>
						<Typography variant={'subtitle2'}>
							<span className={classes.user}>{props.message.name}</span> says:
						</Typography>
					</div>
					<div className={classes.textContainer}>
						<Typography variant={'subtitle2'}>
							{props.message.message}
						</Typography>
					</div>
				</CardActions>
			</Card>
		</div>
	);
});

const mapDispatchToProps = (dispatch: any): DispatchProps =>
{
	return {
		openChat : () =>
		{
			dispatch(chatDrawerActions.setChatDrawerTab('chat'));
			dispatch(chatDrawerActions.openChatDrawer());
		}
	};
};

export default connect(
	null,
	mapDispatchToProps,
	null,
	{
		forwardRef : true
	}
)(ChatSnackbar);
