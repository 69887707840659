type PollsState = {
    createPollOpen: boolean;
    quizHighscoreOpen: boolean;
    polls: Array<object>;
}

const initialState: PollsState = {
	createPollOpen    : false,
	quizHighscoreOpen : false,
	polls             : []
};

const polls = (state = initialState, action: any) =>
{

	switch (action.type)
	{

		case 'SET_CREATE_POLL_OPEN':
		{
			const { createPollOpen } = action.payload;

			return { ...state, createPollOpen };
		}

		case 'SET_QUIZ_HIGHSCORE_OPEN':
		{
			const { quizHighscoreOpen } = action.payload;

			return { ...state, quizHighscoreOpen };
		}

		case 'SET_POLLS':
		{
			const { polls } = action.payload;

			return { ...state, polls };
		}

		default:
			return state;

	}

};

export default polls;