import React from 'react';
import { createTheme, MuiThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';

import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { connect } from 'react-redux';
import { ColorKeyOptions } from '../../helpers/color';

type StoreProps = {
	colors: { [key in ColorKeyOptions] : any };
}

type OwnProps = {
    children: any;
}

type CustomThemeProviderProps = OwnProps & StoreProps;

const CustomThemeProvider = ({
	children,
	colors
}: CustomThemeProviderProps) =>
{
	const breakpoints = createBreakpoints({});

	const themeVariables = {
		customVariables :
		{
			toolbar :
			{
				backgroundColor : colors[ColorKeyOptions.TOOLBAR],
				color           : colors[ColorKeyOptions.TOOLBAR_TEXT]
			},
			background :
			{
				backgroundColor : colors[ColorKeyOptions.BACKGROUND]
			},
			controlButtons :
			{
				backgroundColor : colors[ColorKeyOptions.CONTROL_BUTTON_BACKGROUND],
				color           : colors[ColorKeyOptions.CONTROL_BUTTON_FILL]
			},
			leftDrawer :
			{
				color           : colors[ColorKeyOptions.LEFT_DRAWER_TEXT_COLOR],
				backgroundColor : colors[ColorKeyOptions.LEFT_DRAWER_BACKGROUND]
			},
			rightDrawer :
			{
				color           : colors[ColorKeyOptions.RIGHT_DRAWER_TABS_COLOR],
				backgroundColor : colors[ColorKeyOptions.RIGHT_DRAWER_BACKGROUND]
			},
			peer :
			{
				backgroundColor : colors[ColorKeyOptions.PEER_BACKGROUND]
			},
			chat :
			{
				input :
				{
					color : colors[ColorKeyOptions.CHAT_INPUT_COLOR]
				},
				private :
				{
					color           : colors[ColorKeyOptions.CHAT_PRIVATE_MESSAGE_COLOR],
					backgroundColor : colors[ColorKeyOptions.CHAT_PRIVATE_MESSAGE_BACKGROUND]
				},
				received :
				{
					color           : colors[ColorKeyOptions.CHAT_MESSAGE_RECEIVED_COLOR],
					backgroundColor : colors[ColorKeyOptions.CHAT_MESSAGE_RECEIVED_BACKGROUND]
				},
				sent :
				{
					color           : colors[ColorKeyOptions.CHAT_MESSAGE_SENT_COLOR],
					backgroundColor : colors[ColorKeyOptions.CHAT_MESSAGE_SENT_BACKGROUND]
				}
			}
		},
		palette :
			{
				primary :
					{
						main : '#313131'
					}
			},
		typography :
			{
				fontFamily :
				[
					'Euclid',
					'Helvetica Neue',
					'Helvetica',
					'Arial',
					'sans-serif'
				].join(',')
			},
		overrides : {
			MuiFab :
				{
					primary :
						{
							backgroundColor : '#5F9B2D',
							'&:hover'       :
								{
									backgroundColor : '#518029'
								}
						}
				},
			MuiBadge :
				{
					colorPrimary :
						{
							backgroundColor : '#5F9B2D',
							'&:hover'       :
								{
									backgroundColor : '#518029'
								}
						}
				},
			MuiSelect :
				{
					select : {
						'&:focus' : {
							backgroundColor : '$labelcolor'
						}
					}
				}
		}
	};

	let theme = createTheme(themeVariables);

	theme = responsiveFontSizes(theme);

	return (
		<MuiThemeProvider theme={theme}>
			{children}
		</MuiThemeProvider>
	);
};

const mapStateToProps = (state: any) =>
{
	return {
		colors : state.color.colors
	};
};

export default connect(
	mapStateToProps,
	null,
	null,
	{
		areStatesEqual : (next: any, prev: any) =>
		{
			return (
				next.color.colors === prev.color.colors
			);
		}
	}
)(CustomThemeProvider);