export const setSelectedAudioDevice = (deviceId) =>
	({
		type    : 'CHANGE_AUDIO_DEVICE',
		payload : { deviceId }
	});

export const setSelectedAudioOutputDevice = (deviceId) =>
	({
		type    : 'CHANGE_AUDIO_OUTPUT_DEVICE',
		payload : { deviceId }
	});

export const setSelectedWebcamDevice = (deviceId) =>
	({
		type    : 'CHANGE_WEBCAM',
		payload : { deviceId }
	});

export const setPermissions = (permissionsSet) =>
	({
		type    : 'SET_PERMISSIONS',
		payload : { permissionsSet }
	});

export const setVideoResolution = (resolution) =>
	({
		type    : 'SET_VIDEO_RESOLUTION',
		payload : { resolution }
	});

export const setVideoFrameRate = (frameRate) =>
	({
		type    : 'SET_VIDEO_FRAME_RATE',
		payload : { frameRate }
	});

export const setVideoCodec = (videoCodec) =>
	({
		type    : 'SET_VIDEO_CODEC',
		payload : { videoCodec }
	});

export const setScreenSharingResolution = (screenSharingResolution) =>
	({
		type    : 'SET_SCREEN_SHARING_RESOLUTION',
		payload : { screenSharingResolution }
	});

export const setScreenSharingFrameRate = (screenSharingFrameRate) =>
	({
		type    : 'SET_SCREEN_SHARING_FRAME_RATE',
		payload : { screenSharingFrameRate }
	});

export const setAspectRatio = (aspectRatio) =>
	({
		type    : 'SET_ASPECT_RATIO',
		payload : { aspectRatio }
	});

export const setDisplayName = (displayName) =>
	({
		type    : 'SET_DISPLAY_NAME',
		payload : { displayName }
	});

export const toggleAdvancedMode = () =>
	({
		type : 'TOGGLE_ADVANCED_MODE'
	});

export const togglePermanentBottomBar = () =>
	({
		type : 'TOGGLE_PERMANENT_TOPBAR'
	});

export const toggleShowNotifications = () =>
	({
		type : 'TOGGLE_SHOW_NOTIFICATIONS'
	});

export const setEchoCancellation = (echoCancellation) =>
	({
		type    : 'SET_ECHO_CANCELLATION',
		payload : { echoCancellation }
	});

export const setAutoGainControl = (autoGainControl) =>
	({
		type    : 'SET_AUTO_GAIN_CONTROL',
		payload : { autoGainControl }
	});

export const setNoiseSuppression = (noiseSuppression) =>
	({
		type    : 'SET_NOISE_SUPPRESSION',
		payload : { noiseSuppression }
	});

export const setVoiceActivatedUnmute = (voiceActivatedUnmute) =>
	({
		type    : 'SET_VOICE_ACTIVATED_UNMUTE',
		payload : { voiceActivatedUnmute }
	});

export const setNoiseThreshold = (noiseThreshold) =>
	({
		type    : 'SET_NOISE_THRESHOLD',
		payload : { noiseThreshold }
	});

export const toggleHiddenControls = () =>
	({
		type : 'TOGGLE_HIDDEN_CONTROLS'
	});

export const toggleNotificationSounds = () =>
	({
		type : 'TOGGLE_NOTIFICATION_SOUNDS'
	});

export const setLastN = (lastN) =>
	({
		type    : 'SET_LAST_N',
		payload : { lastN }
	});
export const toggleMirrorOwnVideo = () =>
	({
		type : 'TOGGLE_MIRROR_OWN_VIDEO'
	});

export const setMirrorOwnVideo = (enabled) =>
	({
		type    : 'SET_MIRROR_OWN_VIDEO',
		payload : { enabled }
	});

export const toggleBlurBackground = () =>
	({
		type : 'TOGGLE_BLUR_BACKGROUND'
	});

export const toggleImageBackground = () =>
	({
		type : 'TOGGLE_IMAGE_BACKGROUND'
	});

export const togglePerformanceMode = () =>
	({
		type : 'TOGGLE_PERFORMANCE_MODE'
	});

export const setAudioMuted = (audioMuted) =>
	({
		type    : 'SET_AUDIO_MUTED',
		payload : { audioMuted }
	});

export const setVideoMuted = (videoMuted) =>
	({
		type    : 'SET_VIDEO_MUTED',
		payload : { videoMuted }
	});

export const setDefaultLayout = (layout) =>
	({
		type    : 'SET_DEFAULT_LAYOUT',
		payload : { layout }
	});

export const setAvailableVideoCodecs = (availableVideoCodecs) =>
	({
		type    : 'SET_AVAILABLE_VIDEO_CODECS',
		payload : { availableVideoCodecs }
	});

export const setAvailableAudioCodecs = (availableAudioCodecs) =>
	({
		type    : 'SET_AVAILABLE_AUDIO_CODECS',
		payload : { availableAudioCodecs }
	});

export const setCameraZoomCapabilities = (cameraZoomCapabilities) =>
	({
		type    : 'SET_CAMERA_ZOOM_CAPABILITIES',
		payload : { cameraZoomCapabilities }
	});

export const setCurrentZoomLevel = (currentZoomLevel) =>
	({
		type    : 'SET_CURRENT_ZOOM_LEVEL',
		payload : { currentZoomLevel }
	});

export const setUserId = (userId) =>
	({
		type    : 'SET_USER_ID',
		payload : { userId }
	});

export const setLocalPicture = (localPicture) =>
	({
		type    : 'SET_LOCAL_PICTURE',
		payload : { localPicture }
	});

export const toggleHideNoVideoParticipants = () =>
	({
		type : 'TOGGLE_HIDE_NO_VIDEO_PARTICIPANTS'
	});
