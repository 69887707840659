export const toggleChatDrawer = () =>
	({
		type : 'TOGGLE_CHAT_DRAWER'
	});

export const openChatDrawer = () =>
	({
		type : 'OPEN_CHAT_DRAWER'
	});

export const closeChatDrawer = () =>
	({
		type : 'CLOSE_CHAT_DRAWER'
	});

export const setChatDrawerTab = (tab: string) =>
	({
		type    : 'SET_CHAT_DRAWER_TAB',
		payload : { tab }
	});
