import { ColorKeyOptions } from '../helpers/color';

export const setBackgroundColor = (color: string) => ({
	type    : 'SET_BACKGROUND_COLOR',
	payload : { color }
});

export const setCustomizerOpen = (open: boolean) => ({
	type    : 'SET_CUSTOMIZER_OPEN',
	payload : { open }
});

export const setColor = (key: ColorKeyOptions, value: string) => ({
	type    : 'SET_COLOR',
	payload : { key, value }
});

export const setColors = (colors: { [key in ColorKeyOptions]: any }, remote = false) => ({
	type    : 'SET_COLORS',
	payload : { colors, remote }
});

export const setColorsFromString = (colorString: string) => ({
	type    : 'SET_COLORS_FROM_STRING',
	payload : { colorString }
});

export const setRoomColors = (colorString: string) => ({
	type    : 'SET_ROOM_COLORS_FROM_STRING',
	payload : { colorString }
});
