export function createNewMessage(text, sender, name, picture, type = 'message', recipients)
{
	return {
		type,
		text,
		time : Date.now(),
		name,
		sender,
		picture,
		recipients
	};
}