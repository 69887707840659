import { FILL_EMPTY_FIRST } from '../distributionStrategies';

const initialState =
{
	roomId                        : '',
	subRoomId                     : '',
	name                          : '',
	// new/connecting/connected/disconnected/closed,
	state                         : 'new',
	locked                        : false,
	autoDistribute                : false,
	autoDistributeCreateRooms     : true,
	autoDistributeStrategy        : FILL_EMPTY_FIRST,
	inLobby                       : false,
	signInRequired                : false,
	overRoomLimit                 : false,
	// access code to the room if locked and joinByAccessCode == true
	accessCode                    : '',
	// if true: accessCode is a possibility to open the room
	joinByAccessCode              : true,
	activeSpeakerId               : null,
	previousActiveSpeakerId       : null,
	torrentSupport                : false,
	showSettings                  : false,
	fullScreenConsumer            : null, // ConsumerID
	windowConsumer                : null, // ConsumerID
	currentProducer               : null,
	IFrameURL                     : null,
	forcedLayout                  : null,
	showWebsite                   : true,
	toolbarsVisible               : true,
	mode                          : window.config.defaultLayout || 'democratic',
	selectedPeers                 : [],
	spotlights                    : [],
	alwaysVisibleSpotlights       : [],
	subRooms                      : [],
	parentRoom                    : null,
	rolesManagerPeer              : null, // peerId
	peerManagerPeer               : null, // peerId
	settingsOpen                  : false,
	extraVideoOpen                : false,
	hideSelfView                  : false,
	rolesManagerOpen              : false,
	peerManagerOpen               : false,
	hotkeysOpen                   : false,
	helpOpen                      : false,
	aboutOpen                     : false,
	currentSettingsTab            : 'video', // video, audio, appearance, advanced
	lockDialogOpen                : false,
	joined                        : false,
	muteAllInProgress             : false,
	lobbyPeersPromotionInProgress : false,
	stopAllVideoInProgress        : false,
	closeMeetingInProgress        : false,
	clearChatInProgress           : false,
	clearFileSharingInProgress    : false,
	roomPermissions               : null,
	userRoles                     : null,
	allowWhenRoleMissing          : null,
	allowSelfMoving               : false,
	selectedPeerId                : null,
	isRecording                   : false,
	autoCodecSwitching            : false,
	consumerPreferredLayersLocked : false,
	leaveDialogOpen               : false,
	randomPeopleSelectDialogOpen  : false,
	eventInfoOpen                 : false,
	roomMode                      : 'default',
	votes                         : [],
	unavailable                   : false
};

const room = (state = initialState, action) =>
{
	switch (action.type)
	{
		case 'SET_ROOM_NAME':
		{
			const { name } = action.payload;

			return { ...state, name };
		}

		case 'SET_ROOM_ID':
		{
			const { id } = action.payload;

		    return { ...state, roomId: id };
		}

		case 'SET_UNAVAILABLE':
		{
			const { flag } = action.payload;

		    return { ...state, unavailable: flag };
		}

		case 'SET_SUB_ROOM_ID':
		{
			const { id } = action.payload;

			return { ...state, subRoomId: id };
		}

		case 'SET_ROOM_STATE':
		{
			const roomState = action.payload.state;

			if (roomState === 'connected')
				return { ...state, state: roomState };
			else
				return {
					...state,
					state                   : roomState,
					activeSpeakerId         : null,
					previousActiveSpeakerId : null
				};
		}

		case 'SET_ROOM_LOCKED':
		{
			return { ...state, locked: true };
		}

		case 'SET_ROOM_UNLOCKED':
		{
			return { ...state, locked: false };
		}

		case 'SET_AUTO_DISTRIBUTE_PEERS':
		{
			const { autoDistribute } = action.payload;

			return { ...state, autoDistribute };
		}

		case 'SET_AUTO_DISTRIBUTE_CREATE_ROOMS':
		{
			const { autoDistributeCreateRooms } = action.payload;

			return { ...state, autoDistributeCreateRooms };
		}

		case 'SET_AUTO_DISTRIBUTE_STRATEGY':
		{
			const { autoDistributeStrategy } = action.payload;

			return { ...state, autoDistributeStrategy };
		}

		case 'SET_PERSIST_ROOM':
		{
			const { persistRoom } = action.payload;

			return { ...state, persistRoom };
		}

		case 'SET_IN_LOBBY':
		{
			const { inLobby } = action.payload;

			return { ...state, inLobby };
		}

		case 'SET_SIGN_IN_REQUIRED':
		{
			const { signInRequired } = action.payload;

			return { ...state, signInRequired };
		}
		case 'SET_SUB_ROOMS':
		{
			const { subRooms } = action.payload;

			return { ...state, subRooms };
		}
		case 'SET_PARENT_ROOM':
		{
			const { parentRoom } = action.payload;

			return { ...state, parentRoom };
		}

		case 'SET_OVER_ROOM_LIMIT':
		{
			const { overRoomLimit } = action.payload;

			return { ...state, overRoomLimit };
		}
		case 'SET_ACCESS_CODE':
		{
			const { accessCode } = action.payload;

			return { ...state, accessCode };
		}

		case 'SET_JOIN_BY_ACCESS_CODE':
		{
			const { joinByAccessCode } = action.payload;

			return { ...state, joinByAccessCode };
		}

		case 'SET_LOCK_DIALOG_OPEN':
		{
			const { lockDialogOpen } = action.payload;

			return { ...state, lockDialogOpen };
		}

		case 'SET_SETTINGS_OPEN':
		{
			const { settingsOpen } = action.payload;

			return { ...state, settingsOpen };
		}

		case 'SET_EXTRA_VIDEO_OPEN':
		{
			const { extraVideoOpen } = action.payload;

			return { ...state, extraVideoOpen };
		}

		case 'SET_ROLES_MANAGER_PEER':
		{
			const { rolesManagerPeer } = action.payload;

			return { ...state, rolesManagerPeer };
		}

		case 'SET_ROLES_MANAGER_OPEN':
		{
			const { rolesManagerOpen } = action.payload;

			return { ...state, rolesManagerOpen };
		}

		case 'SET_PEER_MANAGER_PEER':
		{
			const { peerManagerPeer } = action.payload;

			return { ...state, peerManagerPeer };
		}

		case 'SET_PEER_MANAGER_OPEN':
		{
			const { peerManagerOpen } = action.payload;

			return { ...state, peerManagerOpen };
		}

		case 'SET_HOTKEYS_OPEN':
		{
			const { hotkeysOpen } = action.payload;

			return { ...state, hotkeysOpen };
		}

		case 'SET_HELP_OPEN':
		{
			const { helpOpen } = action.payload;

			return { ...state, helpOpen };
		}

		case 'SET_ABOUT_OPEN':
		{
			const { aboutOpen } = action.payload;

			return { ...state, aboutOpen };
		}

		case 'SET_SETTINGS_TAB':
		{
			const { tab } = action.payload;

			return { ...state, currentSettingsTab: tab };
		}

		case 'SET_ROOM_ACTIVE_SPEAKER':
		{
			const { peerId } = action.payload;

			return {
				...state,
				activeSpeakerId         : peerId,
				previousActiveSpeakerId : state.activeSpeakerId
			};
		}

		case 'FILE_SHARING_SUPPORTED':
		{
			const { supported } = action.payload;

			return { ...state, torrentSupport: supported };
		}

		case 'TOGGLE_JOINED':
		{
			const joined = true;

			return { ...state, joined };
		}

		case 'TOGGLE_FULLSCREEN_CONSUMER':
		{
			const { consumerId } = action.payload;
			const currentConsumer = state.fullScreenConsumer;

			return { ...state, fullScreenConsumer: currentConsumer ? null : consumerId };
		}

		case 'TOGGLE_WINDOW_CONSUMER':
		{
			const { consumerId } = action.payload;
			const currentConsumer = state.windowConsumer;

			if (currentConsumer === consumerId)
				return { ...state, windowConsumer: null };
			else
				return { ...state, windowConsumer: consumerId };
		}

		case 'SET_TOOLBARS_VISIBLE':
		{
			return state;
			//
			// const { toolbarsVisible } = action.payload;
			//
			// return { ...state, toolbarsVisible };
		}

		case 'SET_DISPLAY_MODE':
			return { ...state, mode: action.payload.mode };

		case 'SET_FORCED_LAYOUT':
			return { ...state, forcedLayout: action.payload.forcedLayout };

		case 'ADD_SELECTED_PEER':
		{
			const { peerId } = action.payload;

			const selectedPeers = [ ...state.selectedPeers, peerId ];

			return { ...state, selectedPeers };
		}

		// Also listen for peers leaving
		case 'REMOVE_PEER':
		case 'REMOVE_SELECTED_PEER':
		{
			const { peerId } = action.payload;

			const selectedPeers =
				state.selectedPeers.filter((peer) =>
					peer !== peerId);

			return { ...state, selectedPeers };
		}

		case 'CLEAR_SELECTED_PEER_ID':
		{
			return { ...state, selectedPeerId: null };
		}

		case 'CLEAR_SELECTED_PEERS':
		{
			return { ...state, selectedPeers: [] };
		}

		case 'SET_SPOTLIGHTS':
		{
			const { spotlights } = action.payload;

			return { ...state, spotlights };
		}

		case 'SET_ALWAYS_VISIBLE_SPOTLIGHTS':
		{
			const { alwaysVisibleSpotlights } = action.payload;

			return { ...state, alwaysVisibleSpotlights };
		}

		case 'CLEAR_SPOTLIGHTS':
		{
			return { ...state, spotlights: [] };
		}

		case 'SET_LOBBY_PEERS_PROMOTION_IN_PROGRESS':
			return { ...state, lobbyPeersPromotionInProgress: action.payload.flag };

		case 'MUTE_ALL_IN_PROGRESS':
			return { ...state, muteAllInProgress: action.payload.flag };

		case 'STOP_ALL_VIDEO_IN_PROGRESS':
			return { ...state, stopAllVideoInProgress: action.payload.flag };

		case 'STOP_ALL_SCREEN_SHARING_IN_PROGRESS':
			return { ...state, stopAllScreenSharingInProgress: action.payload.flag };

		case 'CLOSE_MEETING_IN_PROGRESS':
			return { ...state, closeMeetingInProgress: action.payload.flag };

		case 'CLEAR_CHAT_IN_PROGRESS':
			return { ...state, clearChatInProgress: action.payload.flag };

		case 'CLEAR_FILE_SHARING_IN_PROGRESS':
			return { ...state, clearFileSharingInProgress: action.payload.flag };

		case 'SET_ROOM_PERMISSIONS':
		{
			const { roomPermissions } = action.payload;

			return { ...state, roomPermissions };
		}

		case 'SET_USER_ROLES':
		{
			const { userRoles } = action.payload;

			return { ...state, userRoles };
		}

		case 'SET_ALLOW_WHEN_ROLE_MISSING':
		{
			const { allowWhenRoleMissing } = action.payload;

			return { ...state, allowWhenRoleMissing };
		}

		case 'SET_HIDE_SELF_VIEW':
		{
			const { hideSelfView } = action.payload;

			return { ...state, hideSelfView };
		}

		case 'OPEN_RANDOM_PEOPLE_SELECT_DIALOG':
		{
			return { ...state, randomPeopleSelectDialogOpen: true };
		}

		case 'CLOSE_RANDOM_PEOPLE_SELECT_DIALOG':
		{
			return { ...state, randomPeopleSelectDialogOpen: false };
		}

		case 'SET_ALLOW_SELF_MOVING':
		{
			const { allowSelfMoving } = action.payload;

			return { ...state, allowSelfMoving };
		}

		case 'SET_IFRAME_URL':
		{
			const { IFrameURL } = action.payload;

			return { ...state, IFrameURL };
		}

		case 'START_COUNTDOWN_TIMER':
		{
			const { timestamp } = action.payload;

			return { ...state, timestamp };
		}

		case 'VOTE_NEXT':
		{
			const { votes } = action.payload;

			return { ...state, votes };
		}

		case 'SET_ROOM_RECORD':
		{
			const { isRecording } = action.payload;

			return { ...state, isRecording };
		}

		case 'SET_AUDIO_CODEC':
		{
			const { audioCodec } = action.payload;

			return { ...state, audioCodec };
		}

		case 'TOGGLE_FULLSCREEN_PRODUCER':
		{
			const currentProducer = state.currentProducer;

			return { ...state, currentProducer: currentProducer ? null : true };
		}

		case 'SET_CONSUMER_PREFERRED_LAYERS_LOCKED':
		{
			const { consumerPreferredLayersLocked } = action.payload;

			return { ...state, consumerPreferredLayersLocked };
		}

		case 'SET_SHOW_WEBSITE':
		{
			const { showWebsite } = action.payload;

			return { ...state, showWebsite };
		}

		case 'SET_PLAY_GAME':
		{
			const { chapter, video } = action.payload;

			return { ...state, chapter, video };
		}

		case 'SET_DRAWING_TOGETHER':
		{
			const { drawingTogether } = action.payload;

			return { ...state, drawingTogether };
		}

		case 'SET_WRITING_TOGETHER':
		{
			const { writingTogether } = action.payload;

			return { ...state, writingTogether };
		}

		case 'SET_CONFETTI':
		{
			const { showConfetti } = action.payload;

			return { ...state, showConfetti };
		}

		case 'SET_CRICKET':
		{
			const { showCricket } = action.payload;

			return { ...state, showCricket };
		}

		case 'OPEN_LEAVE_DIALOG':
		{
			return { ...state, leaveDialogOpen: true };
		}

		case 'CLOSE_LEAVE_DIALOG':
		{
			return { ...state, leaveDialogOpen: false };
		}

		case 'SET_BACKGROUND_ROOM_IMAGE':
		{
			const { image } = action.payload;

			return { ...state, image };
		}

		case 'SET_EVENT_INFO_OPEN':
		{
			const { eventInfoOpen } = action.payload;

			return { ...state, eventInfoOpen };
		}

		case 'SET_FRIENDLY_ROOM_NAME':
		{
			const { friendlyRoomName } = action.payload;

			return { ...state, friendlyRoomName };
		}

		case 'SET_FRIENDLY_ROOM_DESCRIPTION':
		{
			const { friendlyRoomDescription } = action.payload;

			return { ...state, friendlyRoomDescription };
		}

		case 'ADD_ALWAYS_VISIBLE_PEER':
		{
			const { peerId, prepend, reset } = action.payload;

			let newAlwaysVisibleSpotlights;

			if (reset)
				newAlwaysVisibleSpotlights = [ peerId ];
			else
			if (prepend)
			{
				newAlwaysVisibleSpotlights = [
					peerId,
					...state.alwaysVisibleSpotlights
						.filter((visiblePeerId) => visiblePeerId !== peerId)
				];
			}
			else
			{
				newAlwaysVisibleSpotlights = [
					...state.alwaysVisibleSpotlights
						.filter((visiblePeerId) => visiblePeerId !== peerId),
					peerId
				];
			}

			return { ...state, alwaysVisibleSpotlights: newAlwaysVisibleSpotlights };
		}

		case 'REMOVE_ALWAYS_VISIBLE_PEER':
		{
			const newAlwaysVisibleSpotlights = state.alwaysVisibleSpotlights
				.filter(peer => peer !== action.payload.peerId);

			return { ...state, alwaysVisibleSpotlights: newAlwaysVisibleSpotlights };
		}

		case 'SET_ROOM_MODE':
		{
			return { ...state, roomMode: action.payload.roomMode };
		}

		case 'SET_AUTO_CODEC_SWITCHING':
		{
			return { ...state, autoCodecSwitching: action.payload.autoCodecSwitching };
		}

		default:
			return state;
	}
};

export default room;
