export enum ColorKeyOptions {
	BACKGROUND,
	PEER_BACKGROUND,
	TOOLBAR,
	TOOLBAR_TEXT,
	CONTROL_BUTTON_BACKGROUND,
	CONTROL_BUTTON_FILL,
	LEFT_DRAWER_BACKGROUND,
	LEFT_DRAWER_TABS_BACKGROUND,
	LEFT_DRAWER_TEXT_COLOR,
	RIGHT_DRAWER_BACKGROUND,
	RIGHT_DRAWER_TABS_BACKGROUND,
	RIGHT_DRAWER_TABS_COLOR,
	CHAT_MESSAGE_RECEIVED_BACKGROUND,
	CHAT_MESSAGE_RECEIVED_COLOR,
	CHAT_MESSAGE_SENT_BACKGROUND,
	CHAT_MESSAGE_SENT_COLOR,
	CHAT_PRIVATE_MESSAGE_BACKGROUND,
	CHAT_PRIVATE_MESSAGE_COLOR,
	CHAT_INPUT_COLOR
}

export enum ColorCategory {
	GENERAL,
	TOOLBAR,
	BUTTONS,
	CHAT,
	SIDEBAR_LEFT,
	SIDEBAR_RIGHT
}

export const readColorsFromString = (colorString: string) =>
{
	const colorParts = colorString.split('/');

	if (colorParts.length !== Object.keys(ColorKeyOptions).length / 2)
	{
		return {};
	}

	const colorObj = Object.keys(ColorKeyOptions as any).reduce((acc, curr: string, idx) =>
	{
		if (idx >= colorParts.length)
		{
			return acc;
		}

		return {
			...acc,
			[curr] : colorParts[idx]
		};
	}, {} as any);

	const newColors = Object.keys(colorObj).sort()
		.reduce((a, c) =>
		{
			a[c] = colorObj[c];

			return a;
		}, {} as any);

	return newColors;
};