const debounce = (func: Function, wait: number, immediate: boolean = false) =>
{
	let timeout: number | undefined;

	return (...args: any[]) =>
	{
		const context = this;

		const later = () =>
		{
			timeout = undefined;
			if (!immediate)
				func.apply(context, args);
		};

		const callNow = immediate && !timeout;

		window.clearTimeout(timeout);

		timeout = window.setTimeout(later, wait);

		if (callNow)
			func.apply(context, args);
	};
};

export default debounce;
