export const permissions = {
	// The role(s) have permission to lock/unlock a room
	CHANGE_ROOM_LOCK   : 'CHANGE_ROOM_LOCK',
	// The role(s) have permission to promote a peer from the lobby
	PROMOTE_PEER       : 'PROMOTE_PEER',
	// The role(s) have permission to give/remove other peers roles
	MODIFY_ROLE        : 'MODIFY_ROLE',
	// The role(s) have permission to send chat messages
	SEND_CHAT          : 'SEND_CHAT',
	// The role(s) have permission to moderate chat
	MODERATE_CHAT      : 'MODERATE_CHAT',
	// The role(s) have permission to share audio
	SHARE_AUDIO        : 'SHARE_AUDIO',
	// The role(s) have permission to share video
	SHARE_VIDEO        : 'SHARE_VIDEO',
	// The role(s) have permission to share screen
	SHARE_SCREEN       : 'SHARE_SCREEN',
	// The role(s) have permission to produce extra video
	EXTRA_VIDEO        : 'EXTRA_VIDEO',
	// The role(s) have permission to share files
	SHARE_FILE         : 'SHARE_FILE',
	// The role(s) have permission to create polls
	CREATE_POLLS       : 'CREATE_POLLS',
	// The role(s) have permission to create polls
	MODERATE_POLLS     : 'MODERATE_POLLS',
	// The role(s) have permission to set peers in focus
	SET_PEER_FOCUS     : 'SET_PEER_FOCUS',
	// The role(s) have permission to set background room image
	BACKGROUND_IMAGE   : 'BACKGROUND_IMAGE',
	// The role(s) have permission to set room countdown timer
	ROOM_TIMER         : 'ROOM_TIMER',
	// The role(s) have permission to mute others
	MUTE_PEERS         : 'MUTE_PEERS',
	// The role(s) have permission to play music
	PLAY_MUSIC         : 'PLAY_MUSIC',
	// The role(s) have permission to change room name and description
	CHANGE_ROOM_INFO   : 'CHANGE_ROOM_INFO',
	// The role(s) have permission to moderate files
	MODERATE_FILES     : 'MODERATE_FILES',
	// The role(s) have permission to moderate room (e.g. kick user)
	MODERATE_ROOM      : 'MODERATE_ROOM',
	// The role(s) have permission to toggle room modes for this room
	ROOM_MODE          : 'ROOM_MODE',
	SET_IFRAME         : 'SET_IFRAME',
	SET_ROOM_THEME     : 'SET_ROOM_THEME',
	INIT_RANDOM_PERSON : 'INIT_RANDOM_PERSON',
	MAKE_SELFIE        : 'MAKE_SELFIE',
	PLAY_SOUND_FX      : 'PLAY_SOUND_FX',
	COLOR_SPLASH       : 'COLOR_SPLASH',
	CUSTOM_FIELDS      : 'CUSTOM_FIELDS'
};