import React from 'react';
import { Theme, withStyles } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { Styles } from '@material-ui/core/styles/withStyles';

const styles: Styles<Theme, {}> = () => ({
	anchorOriginBottomRight :
        {
        	bottom : '80px'
        }
});

type OwnProps = {
    classes: any;
    children: React.ReactNode;
}

type StyledSnackbarProviderProps = OwnProps;

const StyledSnackbarProvider = ({
	children,
	classes
}: StyledSnackbarProviderProps) =>
{
	return (
		<SnackbarProvider classes={{
		    anchorOriginBottomRight : classes.anchorOriginBottomRight
		}}
		>
			{children}
		</SnackbarProvider>
	);
};

export default withStyles(styles)(StyledSnackbarProvider);