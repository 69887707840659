const initialState =
{
	toolAreaOpen   : false,
	currentToolTab : 'users' // users, subrooms
};

const toolarea = (state = initialState, action) =>
{
	switch (action.type)
	{
		case 'TOGGLE_TOOL_AREA':
		{
			const toolAreaOpen = !state.toolAreaOpen;

			return { ...state, toolAreaOpen };
		}

		case 'OPEN_TOOL_AREA':
		{
			const toolAreaOpen = true;

			return { ...state, toolAreaOpen };
		}

		case 'CLOSE_TOOL_AREA':
		{
			const toolAreaOpen = false;

			return { ...state, toolAreaOpen };
		}

		case 'SET_TOOL_TAB':
		{
			const { toolTab } = action.payload;

			return { ...state, currentToolTab: toolTab };
		}

		default:
			return state;
	}
};

export default toolarea;
