type DialogState = {
    showRemotePresentationDialog: boolean;
    showRoomModeDialog: boolean;
	showRoomManager: boolean;
	roomManagerRoomId: string;
	showCustomFieldDialog: boolean;
	customFieldDialogPeerId: string;
	showManageCustomFieldDialog: boolean;
	manageCustomFieldKey: null | string;
}

const initialState: DialogState = {
	showRemotePresentationDialog : false,
	showRoomModeDialog           : false,
	showRoomManager              : false,
	roomManagerRoomId            : '',
	showCustomFieldDialog        : false,
	customFieldDialogPeerId      : '',
	showManageCustomFieldDialog  : false,
	manageCustomFieldKey         : null
};

const dialog = (state = initialState, action: any) =>
{
	switch (action.type)
	{
		case 'SET_REMOTE_PRESENTATION_DIALOG_OPEN':
			return { ...state, showRemotePresentationDialog: action.payload.open };

		case 'SET_ROOM_MODE_DIALOG_OPEN':
			return { ...state, showRoomModeDialog: action.payload.open };

		case 'SET_ROOM_MANAGER_DIALOG_OPEN':
			return { ...state, showRoomManager: action.payload.open };

		case 'SET_ROOM_MANAGER_ROOM_ID':
			return { ...state, roomManagerRoomId: action.payload.roomId };

		case 'SET_CUSTOM_FIELD_DIALOG_OPEN':
			return { ...state, showCustomFieldDialog: action.payload.open };

		case 'SET_CUSTOM_FIELD_DIALOG_PEER_ID':
			return { ...state, customFieldDialogPeerId: action.payload.peerId };

		case 'SET_MANAGE_CUSTOM_FIELD_DIALOG_OPEN':
			return { ...state, showManageCustomFieldDialog: action.payload.open };
		case 'SET_MANAGE_CUSTOM_FIELD_KEY':
			return { ...state, manageCustomFieldKey: action.payload.key };

		default: {
			return state;
		}
	}
};

export default dialog;