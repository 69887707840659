const initialState = {};

const peerAudio = (state = initialState, action: any) =>
{
	switch (action.type)
	{
		case 'SET_PEER_MAKING_SOUND':
		{
			const { peerId, isMakingSound } = action.payload;

			return { ...state, [peerId]: isMakingSound };
		}

		default:
			return state;
	}
};

export default peerAudio;
