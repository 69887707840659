import Logger from './Logger';

type Effect = { loaded: boolean; audio: any };
type Effects = {
    [key:string]: Effect
}

const logger = new Logger('SoundFx');

export default class SoundFx
{
    private effects: Effects = {};

    load(effect: string)
    {
    	if (this.effects?.[effect]?.loaded)
    		return this.effects[effect];

    	const audio = new Audio();

    	if (effect === 'colorHighlight')
    	{
    		audio.volume = 0.01;
    	}
    	else
    	{
    		audio.volume = 0.4;
    	}

    	audio.addEventListener('loadeddata', () =>
    	{
    		if (effect === 'colorHighlight')
    		{
    			audio.volume = 0.01;
    		}
    		else
    		{
    			audio.volume = 0.4;
    		}
    	});

    	switch (effect)
    	{
    		case 'applause':
    		    audio.src= '/sounds/applause.mp3';
    		    break;
    		case 'crickets':
    			audio.src= '/sounds/crickets.m4a';
    			break;
    		case 'drum_joke':
    			audio.src= '/sounds/drum_joke.mp3';
    			break;
    		case 'money':
    			audio.src= '/sounds/ka-ching.mp3';
    			break;
    		case 'hallelujah':
    			audio.src= '/sounds/hallelujah.mp3';
    			break;
    		case 'bell':
    			audio.src= '/sounds/bell-ring.mp3';
    			break;
    		case 'muted':
    			audio.src= '/sounds/censor-beep-2.mp3';
    			break;
    		case 'colorHighlight':
    			audio.src= '/sounds/splatwet.mp3';
    			break;
    		default:
    		    throw new Error(`Unknown effect "${effect}"`);
    	}

    	this.effects[effect] = {
    		audio,
    		loaded : true
    	};

    	return this.effects[effect];
    }

    play(effect: string)
    {
    	try
    	{
    		const { audio } = this.load(effect);

    		audio.play();
    	}
    	catch (e: any)
    	{
    		logger.error('Could not play effect "%s"', effect);
    	}
    }
}