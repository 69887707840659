export const FILL_EMPTY_FIRST = {
	id      : 'fill-empty-first',
	label   : 'Fill Empty First',
	tooltip : 'Distribute users evenly among all rooms by filling up the least empty rooms first'
};

export const FILL_FIRST = {
	id      : 'fill-first',
	label   : 'Fill First',
	tooltip : 'Distribute users by filling rooms one by one until the optimal user count is achieved.'
};

const distributionStrategies = [
	FILL_EMPTY_FIRST,
	FILL_FIRST
];

export default distributionStrategies;
