import { ColorCategory, ColorKeyOptions, readColorsFromString } from '../helpers/color';

export const ColorCategories = [
	{
		id    : ColorCategory.GENERAL,
		label : 'General'
	},
	{
		id    : ColorCategory.TOOLBAR,
		label : 'Toolbar'
	},
	{
		id    : ColorCategory.BUTTONS,
		label : 'Buttons'
	},
	{
		id    : ColorCategory.CHAT,
		label : 'Chat'
	},
	{
		id    : ColorCategory.SIDEBAR_LEFT,
		label : 'Left Sidebar'
	},
	{
		id    : ColorCategory.SIDEBAR_RIGHT,
		label : 'Right Sidebar'
	}
];

export const defaultColors = {
	[ColorKeyOptions.BACKGROUND]                       : '#1B1624',
	[ColorKeyOptions.PEER_BACKGROUND]                  : '#5C4191',
	[ColorKeyOptions.CONTROL_BUTTON_BACKGROUND]        : '#8A3784',
	[ColorKeyOptions.CONTROL_BUTTON_FILL]              : '#FFFFFF',
	[ColorKeyOptions.LEFT_DRAWER_BACKGROUND]           : '#46314F',
	[ColorKeyOptions.LEFT_DRAWER_TABS_BACKGROUND]      : '#FFFFFF',
	[ColorKeyOptions.LEFT_DRAWER_TEXT_COLOR]           : '#FFFFFF',
	[ColorKeyOptions.RIGHT_DRAWER_BACKGROUND]          : '#FFFFFF',
	[ColorKeyOptions.RIGHT_DRAWER_TABS_BACKGROUND]     : '#FFFFFF',
	[ColorKeyOptions.RIGHT_DRAWER_TABS_COLOR]          : '#6B6B6B',
	[ColorKeyOptions.TOOLBAR]                          : '#003767',
	[ColorKeyOptions.TOOLBAR_TEXT]                     : '#FFFFFF',
	[ColorKeyOptions.CHAT_MESSAGE_RECEIVED_BACKGROUND] : '#F5F5F5',
	[ColorKeyOptions.CHAT_MESSAGE_RECEIVED_COLOR]      : '#6B6B6B',
	[ColorKeyOptions.CHAT_MESSAGE_SENT_BACKGROUND]     : '#F5F5F5',
	[ColorKeyOptions.CHAT_MESSAGE_SENT_COLOR]          : '#6B6B6B',
	[ColorKeyOptions.CHAT_PRIVATE_MESSAGE_BACKGROUND]  : '#C275CD',
	[ColorKeyOptions.CHAT_PRIVATE_MESSAGE_COLOR]       : '#6B6B6B',
	[ColorKeyOptions.CHAT_INPUT_COLOR]                 : '#6B6B6B'
};

export const ColorSettings = [
	{
		id       : ColorKeyOptions.BACKGROUND,
		label    : 'Background',
		category : ColorCategory.GENERAL
	},
	{
		id       : ColorKeyOptions.PEER_BACKGROUND,
		label    : 'Peer Background',
		category : ColorCategory.GENERAL
	},
	{
		id       : ColorKeyOptions.TOOLBAR,
		label    : 'Toolbar Background',
		category : ColorCategory.TOOLBAR
	},
	{
		id       : ColorKeyOptions.TOOLBAR_TEXT,
		label    : 'Toolbar Text',
		category : ColorCategory.TOOLBAR
	},
	{
		id       : ColorKeyOptions.CONTROL_BUTTON_BACKGROUND,
		label    : 'Control Button Background',
		category : ColorCategory.BUTTONS
	},
	{
		id       : ColorKeyOptions.CONTROL_BUTTON_FILL,
		label    : 'Control Button Fill',
		category : ColorCategory.BUTTONS
	},
	{
		id       : ColorKeyOptions.LEFT_DRAWER_BACKGROUND,
		label    : 'Left Drawer Background Color',
		category : ColorCategory.SIDEBAR_LEFT
	},
	{
		id       : ColorKeyOptions.LEFT_DRAWER_TABS_BACKGROUND,
		label    : 'Left Drawer Tab Background Color',
		category : ColorCategory.SIDEBAR_LEFT
	},
	{
		id       : ColorKeyOptions.LEFT_DRAWER_TEXT_COLOR,
		label    : 'Left Drawer Tab Color',
		category : ColorCategory.SIDEBAR_LEFT
	},
	{
		id       : ColorKeyOptions.RIGHT_DRAWER_BACKGROUND,
		label    : 'Right Drawer Background Color',
		category : ColorCategory.SIDEBAR_RIGHT
	},
	{
		id       : ColorKeyOptions.RIGHT_DRAWER_TABS_BACKGROUND,
		label    : 'Right Drawer Tab Background Color',
		category : ColorCategory.SIDEBAR_RIGHT
	},
	{
		id       : ColorKeyOptions.RIGHT_DRAWER_TABS_COLOR,
		label    : 'Right Drawer Tab Color',
		category : ColorCategory.SIDEBAR_RIGHT
	},
	{
		id       : ColorKeyOptions.CHAT_MESSAGE_RECEIVED_BACKGROUND,
		label    : 'Received Background',
		category : ColorCategory.CHAT
	},
	{
		id       : ColorKeyOptions.CHAT_MESSAGE_RECEIVED_COLOR,
		label    : 'Received Message Text Color',
		category : ColorCategory.CHAT
	},
	{
		id       : ColorKeyOptions.CHAT_PRIVATE_MESSAGE_BACKGROUND,
		label    : 'Private Message Background',
		category : ColorCategory.CHAT
	},
	{
		id       : ColorKeyOptions.CHAT_PRIVATE_MESSAGE_COLOR,
		label    : 'Private Message Text',
		category : ColorCategory.CHAT
	},
	{
		id       : ColorKeyOptions.CHAT_MESSAGE_SENT_COLOR,
		label    : 'Sent Message Text Color',
		category : ColorCategory.CHAT
	},
	{
		id       : ColorKeyOptions.CHAT_MESSAGE_SENT_BACKGROUND,
		label    : 'Sent Message Background',
		category : ColorCategory.CHAT
	},
	{
		id       : ColorKeyOptions.CHAT_INPUT_COLOR,
		label    : 'Chat Input Color',
		category : ColorCategory.CHAT
	}
];

type ColorState = {
    customizerOpen: boolean;
    colors: { [key in ColorKeyOptions]: any };
	colorsAsString: string;
}

const initState: ColorState = {
	customizerOpen : false,
	colors         : defaultColors,
	colorsAsString : Object.values(defaultColors).join('/')
};

const color = (state: ColorState = initState, action: any) =>
{
	switch (action.type)
	{
		// case 'SET_CUSTOMIZER_OPEN': {
		// 	return { ...state, customizerOpen: action.payload.open };
		// }

		// case 'SET_COLOR': {
		//     const { key, value } = action.payload;

		//     const newColors = {
		// 		...state.colors,
		// 		[key] : value
		// 	};

		// 	return {
		// 	    ...state,
		// 		colors         : newColors,
		// 		colorsAsString : Object.values(newColors).join('/')

		// 	};
		// }

		// case 'SET_COLORS_FROM_STRING':
		// {
		// 	const { colorString } = action.payload;

		// 	const newColors = readColorsFromString(colorString);

		// 	return {
		// 		...state,
		// 		colors         : newColors,
		// 		colorsAsString : Object.values(newColors).join('/')
		// 	};
		// }

		// case 'SET_COLORS': {
		// 	const { colors } = action.payload;

		// 	return {
		// 		...state,
		// 		colors,
		// 		colorsAsString : Object.values(colors).join('/')
		// 	};
		// }

		// case 'SET_THEME':
		// {
		// 	const { theme } = action.payload;

		// 	return {
		// 		...state,
		// 		colors         : theme.colors,
		// 		colorsAsString : Object.values(theme.colors).join('/')
		// 	};
		// }
		default:
			return initState;
	}
};

export default color;