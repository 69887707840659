import {
	createStore,
	applyMiddleware,
	compose
} from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootReducer from './reducers/rootReducer';
import { Action, ThunkAction } from '@reduxjs/toolkit';

const settingsBlacklist = createTransform(
	(state: any) => state,
	(state: any) =>
	{
		const newState = { ...state };

		// always initiate acceptedPermissions with false
		newState.acceptedPermissions = false;

		return newState;
	},
	{ whitelist: [ 'settings' ] }
);

const persistConfig =
{
	key             : 'root',
	storage         : storage,
	stateReconciler : autoMergeLevel2,
	transforms      : [ settingsBlacklist ],
	whitelist       : [ 'settings', 'color', 'theme' ]
};

const reduxMiddlewares =
[
	thunk
];

if (process.env.REACT_APP_DEBUG === '*' || process.env.NODE_ENV !== 'production')
{
	const reduxLogger = createLogger(
		{
			// filter VOLUME level actions from log
			predicate : (getState, action) => !(action.type === 'SET_PEER_VOLUME'),
			duration  : true,
			timestamp : false,
			level     : 'info',
			logErrors : true
		});

	// @ts-ignore
	reduxMiddlewares.push(reduxLogger);
}

const composeEnhancers =
// @ts-ignore
	typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
		// @ts-ignore
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) :
		compose;

const enhancer = composeEnhancers(
	applyMiddleware(...reduxMiddlewares)
);

// @ts-ignore
const pReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
	pReducer,
	enhancer
);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
	RootState,
	unknown,
Action<string>>;

export const persistor = persistStore(store);