export type ChatDrawerState = {
    chatDrawerOpen: boolean;
    currentChatDrawerTab: 'chat' | 'files';
    unreadMessages: number;
    unreadFiles: number;
}

const initialState: ChatDrawerState =
    {
    	chatDrawerOpen       : false,
    	currentChatDrawerTab : 'chat', // chat, files
    	unreadMessages       : 0,
    	unreadFiles          : 0
    };

const chatDrawer = (state = initialState, action: any) =>
{
	switch (action.type)
	{
		case 'TOGGLE_CHAT_DRAWER': {
			const chatDrawerOpen = !state.chatDrawerOpen;
			const unreadMessages = chatDrawerOpen && state.currentChatDrawerTab === 'chat' ? 0 : state.unreadMessages;
			const unreadFiles = chatDrawerOpen && state.currentChatDrawerTab === 'files' ? 0 : state.unreadFiles;

			return { ...state, chatDrawerOpen, unreadMessages, unreadFiles };
		}

		case 'OPEN_CHAT_DRAWER': {
			const chatDrawerOpen = true;
			const unreadMessages = state.currentChatDrawerTab === 'chat' ? 0 : state.unreadMessages;
			const unreadFiles = state.currentChatDrawerTab === 'files' ? 0 : state.unreadFiles;

			return { ...state, chatDrawerOpen, unreadMessages, unreadFiles };
		}

		case 'CLOSE_CHAT_DRAWER': {
			const chatDrawerOpen = false;

			return { ...state, chatDrawerOpen };
		}

		case 'SET_CHAT_DRAWER_TAB': {
			const { tab } = action.payload;
			const unreadMessages = tab === 'chat' ? 0 : state.unreadMessages;
			const unreadFiles = tab === 'files' ? 0 : state.unreadFiles;

			return { ...state, currentChatDrawerTab: tab, unreadMessages, unreadFiles };
		}

		case 'ADD_NEW_RESPONSE_MESSAGE': {
			if (state.chatDrawerOpen && state.currentChatDrawerTab === 'chat')
			{
				return state;
			}

			return { ...state, unreadMessages: state.unreadMessages + 1 };
		}

		case 'ADD_FILE': {
			if (state.chatDrawerOpen && state.currentChatDrawerTab === 'files')
			{
				return state;
			}

			return { ...state, unreadFiles: state.unreadFiles + 1 };
		}

		default:
			return state;
	}
};

export default chatDrawer;
