import { ColorKeyOptions, readColorsFromString } from '../helpers/color';

export type ClubTheme = {
    id: number;
    name: string;
    label: string;
    colors: { [key in ColorKeyOptions]: any };
};

type ThemeState = {
    currentTheme: ClubTheme;
    themes: ClubTheme[];
}

const defaultTheme = {
	id     : 0,
	name   : 'default',
	label  : 'Default',
	colors : {
		[ColorKeyOptions.BACKGROUND]                       : '#1B1624',
		[ColorKeyOptions.PEER_BACKGROUND]                  : '#5C4191',
		[ColorKeyOptions.CONTROL_BUTTON_BACKGROUND]        : '#8A3784',
		[ColorKeyOptions.CONTROL_BUTTON_FILL]              : '#FFFFFF',
		[ColorKeyOptions.LEFT_DRAWER_BACKGROUND]           : '#FFFFFF',
		[ColorKeyOptions.LEFT_DRAWER_TABS_BACKGROUND]      : '#FFFFFF',
		[ColorKeyOptions.LEFT_DRAWER_TEXT_COLOR]           : '#6B6B6B',
		[ColorKeyOptions.RIGHT_DRAWER_BACKGROUND]          : '#FFFFFF',
		[ColorKeyOptions.RIGHT_DRAWER_TABS_BACKGROUND]     : '#FFFFFF',
		[ColorKeyOptions.RIGHT_DRAWER_TABS_COLOR]          : '#6B6B6B',
		[ColorKeyOptions.TOOLBAR]                          : '#003767',
		[ColorKeyOptions.TOOLBAR_TEXT]                     : '#FFFFFF',
		[ColorKeyOptions.CHAT_MESSAGE_RECEIVED_BACKGROUND] : '#F5F5F5',
		[ColorKeyOptions.CHAT_MESSAGE_RECEIVED_COLOR]      : '#6B6B6B',
		[ColorKeyOptions.CHAT_MESSAGE_SENT_BACKGROUND]     : '#F5F5F5',
		[ColorKeyOptions.CHAT_MESSAGE_SENT_COLOR]          : '#6B6B6B',
		[ColorKeyOptions.CHAT_PRIVATE_MESSAGE_BACKGROUND]  : '#C275CD',
		[ColorKeyOptions.CHAT_PRIVATE_MESSAGE_COLOR]       : '#6B6B6B',
		[ColorKeyOptions.CHAT_INPUT_COLOR]                 : '#6B6B6B'
	}
};

const initState: ThemeState = {
	currentTheme : defaultTheme,
	themes       : [
	    defaultTheme,
		{
			id     : 1,
			name   : 'light',
			label  : 'Light',
			colors :
                {
                	[ColorKeyOptions.BACKGROUND]                       : '#EBEBEB',
                	[ColorKeyOptions.PEER_BACKGROUND]                  : '#5c4191',
                	[ColorKeyOptions.CONTROL_BUTTON_BACKGROUND]        : '#FFFFFF',
                	[ColorKeyOptions.CONTROL_BUTTON_FILL]              : '#8A3784',
                	[ColorKeyOptions.LEFT_DRAWER_BACKGROUND]           : '#8A3784',
                	[ColorKeyOptions.LEFT_DRAWER_TABS_BACKGROUND]      : '#3A2644',
                	[ColorKeyOptions.LEFT_DRAWER_TEXT_COLOR]           : '#FFFFFF',
                	[ColorKeyOptions.RIGHT_DRAWER_BACKGROUND]          : '#8A3784',
                	[ColorKeyOptions.RIGHT_DRAWER_TABS_BACKGROUND]     : '#3A2644',
                	[ColorKeyOptions.RIGHT_DRAWER_TABS_COLOR]          : '#FFFFFF',
                	[ColorKeyOptions.TOOLBAR]                          : '#F8F8F8',
                	[ColorKeyOptions.TOOLBAR_TEXT]                     : '#8A3784',
                	[ColorKeyOptions.CHAT_MESSAGE_RECEIVED_BACKGROUND] : '#F5F5F5',
                	[ColorKeyOptions.CHAT_MESSAGE_RECEIVED_COLOR]      : '#4A4A4A',
                	[ColorKeyOptions.CHAT_MESSAGE_SENT_BACKGROUND]     : '#F5F5F5',
                	[ColorKeyOptions.CHAT_MESSAGE_SENT_COLOR]          : '#4A4A4A',
                	[ColorKeyOptions.CHAT_PRIVATE_MESSAGE_BACKGROUND]  : '#C275CD',
                	[ColorKeyOptions.CHAT_PRIVATE_MESSAGE_COLOR]       : '#4A4A4A',
                	[ColorKeyOptions.CHAT_INPUT_COLOR]                 : '#FFFFFF'
                }
		},
		{
			id     : 2,
			name   : 'dark_orange',
			label  : 'Dark/Orange',
			colors :
                {
                	[ColorKeyOptions.BACKGROUND]                       : '#14212A',
                	[ColorKeyOptions.PEER_BACKGROUND]                  : '#181A31',
                	[ColorKeyOptions.CONTROL_BUTTON_BACKGROUND]        : '#DE7E38',
                	[ColorKeyOptions.CONTROL_BUTTON_FILL]              : '#FFFFFF',
                	[ColorKeyOptions.LEFT_DRAWER_BACKGROUND]           : '#31344F',
                	[ColorKeyOptions.LEFT_DRAWER_TABS_BACKGROUND]      : '#3A2644',
                	[ColorKeyOptions.LEFT_DRAWER_TEXT_COLOR]           : '#FFFFFF',
                	[ColorKeyOptions.RIGHT_DRAWER_BACKGROUND]          : '#31344F',
                	[ColorKeyOptions.RIGHT_DRAWER_TABS_BACKGROUND]     : '#3A2644',
                	[ColorKeyOptions.RIGHT_DRAWER_TABS_COLOR]          : '#FFFFFF',
                	[ColorKeyOptions.TOOLBAR]                          : '#262A44',
                	[ColorKeyOptions.TOOLBAR_TEXT]                     : '#FFFFFF',
                	[ColorKeyOptions.CHAT_MESSAGE_RECEIVED_BACKGROUND] : '#CD7134',
                	[ColorKeyOptions.CHAT_MESSAGE_RECEIVED_COLOR]      : '#FFFFFF',
                	[ColorKeyOptions.CHAT_MESSAGE_SENT_BACKGROUND]     : '#ff9741',
                	[ColorKeyOptions.CHAT_MESSAGE_SENT_COLOR]          : '#FFFFFF',
                	[ColorKeyOptions.CHAT_PRIVATE_MESSAGE_BACKGROUND]  : '#C275CD',
                	[ColorKeyOptions.CHAT_PRIVATE_MESSAGE_COLOR]       : '#CCCCCC',
                	[ColorKeyOptions.CHAT_INPUT_COLOR]                 : '#FFFFFF'
                }
		}
	]
};

const theme = (state: ThemeState = initState, action: any) =>
{
	switch (action.type)
	{
		// case 'SET_THEME': {
		// 	const { theme } = action.payload;

		// 	if (!theme)
		// 	{
		// 		return state;
		// 	}

		// 	return { ...state, currentTheme: action.payload.theme };
		// }

		// case 'SET_COLORS': {
		// 	const { colors, remote } = action.payload;

		// 	if (remote)
		// 	{
		// 		return state;
		// 	}

		// 	let newThemes = state.themes;

		// 	const customTheme = {
		// 		id     : 9998,
		// 		name   : 'custom',
		// 		label  : 'Custom',
		// 		colors : colors
		// 	};

		// 	const hasCustomTheme = state.themes.findIndex((theme) => theme.name === customTheme.name) !== -1;

		// 	if (hasCustomTheme)
		// 	{
		// 		newThemes = newThemes
		// 			.map((theme) =>
		// 			{
		// 				return theme.name === 'custom' ? customTheme : theme;
		// 			});
		// 	}
		// 	else
		// 	{
		// 		newThemes = [ ...state.themes, customTheme ];
		// 	}

		// 	return {
		// 		...state,
		// 		themes       : newThemes,
		// 		currentTheme : customTheme
		// 	};
		// }

		// case 'SET_ROOM_COLORS_FROM_STRING':
		// {
		// 	const { colorString } = action.payload;

		// 	if (!colorString)
		// 	{
		// 		return state;
		// 	}

		// 	const newColors = readColorsFromString(colorString);

		// 	if (!newColors)
		// 	{
		// 		return state;
		// 	}

		// 	let newThemes = state.themes;

		// 	const roomTheme = {
		// 		id     : 9999,
		// 		name   : 'room',
		// 		label  : 'Room',
		// 		colors : newColors
		// 	};

		// 	const hasRoomTheme = state.themes.findIndex((theme) => theme.name === roomTheme.name) !== -1;

		// 	if (hasRoomTheme)
		// 	{
		// 		newThemes = newThemes
		// 			.map((theme) =>
		// 			{
		// 				return theme.name === 'room' ? roomTheme : theme;
		// 			});
		// 	}
		// 	else
		// 	{
		// 		newThemes = [ ...state.themes, roomTheme ];
		// 	}

		// 	return {
		// 		...state,
		// 		themes       : newThemes,
		// 		currentTheme : state.currentTheme.name === roomTheme.name ? roomTheme : state.currentTheme
		// 	};
		// }

		// case 'ENABLE_ROOM_THEME':
		// {
		// 	const roomTheme = state.themes.find((theme) => theme.name === 'room');

		// 	if (!roomTheme)
		// 	{
		// 		return state;
		// 	}

		// 	return {
		// 		...state,
		// 		currentTheme : roomTheme
		// 	};
		// }

		default:
			return state;
	}
};

export default theme;