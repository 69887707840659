export const setShowMusicPlayer = (showPlayer: boolean) => ({
	type    : 'SET_SHOW_MUSIC_PLAYER',
	payload : { showPlayer }
});

export const setPlayIndex = (playIndex: number) => ({
	type    : 'SET_PLAY_INDEX',
	payload : { playIndex }
});

export const setPlayback = (playback: boolean) => ({
	type    : 'SET_PLAYBACK',
	payload : { playback }
});

export const setMusicSeek = (currentTime: number) => ({
	type    : 'SET_MUSIC_SEEK',
	payload : { currentTime }
});

export const setMusicVolume = (volume: number) => ({
	type    : 'SET_MUSIC_VOLUME',
	payload : { volume }
});