type LikesState = {
    count: number;
    prevCount: number;
}

const initialState: LikesState = {
	count     : -1,
	prevCount : -1
};

const likes = (state = initialState, action: any) =>
{
	switch (action.type)
	{
		case 'SET_COUNT':
			// eslint-disable-next-line no-case-declarations
			const newPrevCount = state.count === -1 ? action.payload.count : state.count;

			return { ...state, prevCount: newPrevCount, count: action.payload.count };

		default:
			return state;
	}
};

export default likes;