export type MusicState = {
    showPlayer: boolean;
	playIndex: number;
	playback: boolean;
	currentTime: number;
	volume: number;
}
const initialState: MusicState =
    {
    	showPlayer  : false,
    	playIndex   : 0,
    	playback    : true,
    	currentTime : 0,
    	volume      : 0.1
    };

const music = (state = initialState, action: any) =>
{
	switch (action.type)
	{
		case 'SET_SHOW_MUSIC_PLAYER':
		{
			const { showPlayer } = action.payload;

			return { ...state, showPlayer };
		}

		case 'SET_PLAY_INDEX':
		{
			const { playIndex } = action.payload;

			return { ...state, playIndex };
		}

		case 'SET_PLAYBACK':
		{
			const { playback } = action.payload;

			return { ...state, playback };
		}

		case 'SET_MUSIC_SEEK':
		{
			const { currentTime } = action.payload;

			return { ...state, currentTime };
		}

		case 'SET_MUSIC_VOLUME':
		{
			const { volume } = action.payload;

			return { ...state, volume };
		}

		default:
			return state;
	}
};

export default music;