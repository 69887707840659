export const setRoomName = (name) =>
	({
		type    : 'SET_ROOM_NAME',
		payload : { name }

	});

export const setUnavailable = (flag) =>
	({
		type    : 'SET_UNAVAILABLE',
		payload : { flag }
	});

export const setRoomId = (id) =>
	({
		type    : 'SET_ROOM_ID',
		payload : { id }

	});

export const setSubRoomId = (id) =>
	({
		type    : 'SET_SUB_ROOM_ID',
		payload : { id }

	});

export const setRoomState = (state) =>
	({
		type    : 'SET_ROOM_STATE',
		payload : { state }

	});

export const setRoomActiveSpeaker = (peerId) =>
	({
		type    : 'SET_ROOM_ACTIVE_SPEAKER',
		payload : { peerId }
	});

export const setRoomLocked = () =>
	({
		type : 'SET_ROOM_LOCKED'
	});

export const setRoomUnLocked = () =>
	({
		type : 'SET_ROOM_UNLOCKED'
	});

export const setRoomAutoDistribute = (autoDistribute) =>
	({
		type    : 'SET_AUTO_DISTRIBUTE_PEERS',
		payload : { autoDistribute }
	});

export const setAutoDistributeCreateRooms = (autoDistributeCreateRooms) =>
	({
		type    : 'SET_AUTO_DISTRIBUTE_CREATE_ROOMS',
		payload : { autoDistributeCreateRooms }
	});

export const setAutoDistributeStrategy = (autoDistributeStrategy) =>
	({
		type    : 'SET_AUTO_DISTRIBUTE_STRATEGY',
		payload : { autoDistributeStrategy }
	});

export const setPersistRoom = (persistRoom) =>
	({
		type    : 'SET_PERSIST_ROOM',
		payload : { persistRoom }
	});

export const setInLobby = (inLobby) =>
	({
		type    : 'SET_IN_LOBBY',
		payload : { inLobby }
	});

export const setSignInRequired = (signInRequired) =>
	({
		type    : 'SET_SIGN_IN_REQUIRED',
		payload : { signInRequired }
	});

export const setParentRoom = (parentRoom) =>
	({
		type    : 'SET_PARENT_ROOM',
		payload : { parentRoom }
	});

export const setOverRoomLimit = (overRoomLimit) =>
	({
		type    : 'SET_OVER_ROOM_LIMIT',
		payload : { overRoomLimit }
	});

export const setAccessCode = (accessCode) =>
	({
		type    : 'SET_ACCESS_CODE',
		payload : { accessCode }
	});

export const setJoinByAccessCode = (joinByAccessCode) =>
	({
		type    : 'SET_JOIN_BY_ACCESS_CODE',
		payload : { joinByAccessCode }
	});

export const setSettingsOpen = (settingsOpen) =>
	({
		type    : 'SET_SETTINGS_OPEN',
		payload : { settingsOpen }
	});

export const setExtraVideoOpen = (extraVideoOpen) =>
	({
		type    : 'SET_EXTRA_VIDEO_OPEN',
		payload : { extraVideoOpen }
	});

export const setRolesManagerOpen = (rolesManagerOpen) =>
	({
		type    : 'SET_ROLES_MANAGER_OPEN',
		payload : { rolesManagerOpen }
	});

export const setRolesManagerPeer = (rolesManagerPeer) =>
	({
		type    : 'SET_ROLES_MANAGER_PEER',
		payload : { rolesManagerPeer }
	});

export const setPeerManagerOpen = (peerManagerOpen) =>
	({
		type    : 'SET_PEER_MANAGER_OPEN',
		payload : { peerManagerOpen }
	});

export const setPeerManagerPeer = (peerManagerPeer) =>
	({
		type    : 'SET_PEER_MANAGER_PEER',
		payload : { peerManagerPeer }
	});

export const setHotkeysOpen = (hotkeysOpen) =>
	({
		type    : 'SET_HOTKEYS_OPEN',
		payload : { hotkeysOpen }
	});

export const setHelpOpen = (helpOpen) =>
	({
		type    : 'SET_HELP_OPEN',
		payload : { helpOpen }
	});

export const setAboutOpen = (aboutOpen) =>
	({
		type    : 'SET_ABOUT_OPEN',
		payload : { aboutOpen }
	});

export const setSettingsTab = (tab) =>
	({
		type    : 'SET_SETTINGS_TAB',
		payload : { tab }
	});

export const setLockDialogOpen = (lockDialogOpen) =>
	({
		type    : 'SET_LOCK_DIALOG_OPEN',
		payload : { lockDialogOpen }
	});

export const setFileSharingSupported = (supported) =>
	({
		type    : 'FILE_SHARING_SUPPORTED',
		payload : { supported }
	});

export const toggleConsumerWindow = (consumerId) =>
	({
		type    : 'TOGGLE_WINDOW_CONSUMER',
		payload : { consumerId }
	});

export const setToolbarsVisible = (toolbarsVisible) =>
	({
		type    : 'SET_TOOLBARS_VISIBLE',
		payload : { toolbarsVisible }
	});

export const setDisplayMode = (mode) =>
	({
		type    : 'SET_DISPLAY_MODE',
		payload : { mode }
	});

export const setForcedLayout = (forcedLayout) =>
	({
		type    : 'SET_FORCED_LAYOUT',
		payload : { forcedLayout }
	});

export const addSelectedPeer = (peerId) =>
	({
		type    : 'ADD_SELECTED_PEER',
		payload : { peerId }
	});

export const removeSelectedPeer = (peerId) =>
	({
		type    : 'REMOVE_SELECTED_PEER',
		payload : { peerId }
	});

export const clearSelectedPeers = () =>
	({
		type : 'CLEAR_SELECTED_PEERS'
	});

export const setForcedSecondHighlightedPeerId = (peerId) =>
	({
		type    : 'SET_FORCED_SECOND_HIGHLIGHTED_PEER_ID',
		payload : { peerId }
	});

export const setSpotlights = (spotlights) =>
	({
		type    : 'SET_SPOTLIGHTS',
		payload : { spotlights }
	});

export const setAlwaysVisibleSpotlights = (alwaysVisibleSpotlights) =>
	({
		type    : 'SET_ALWAYS_VISIBLE_SPOTLIGHTS',
		payload : { alwaysVisibleSpotlights }
	});

export const clearSpotlights = () =>
	({
		type : 'CLEAR_SPOTLIGHTS'
	});

export const toggleJoined = () =>
	({
		type : 'TOGGLE_JOINED'
	});

export const setAllowSelfMoving = (allowSelfMoving) =>
	({
		type    : 'SET_ALLOW_SELF_MOVING',
		payload : { allowSelfMoving }
	});

export const toggleConsumerFullscreen = (consumerId) =>
	({
		type    : 'TOGGLE_FULLSCREEN_CONSUMER',
		payload : { consumerId }
	});

export const setLobbyPeersPromotionInProgress = (flag) =>
	({
		type    : 'SET_LOBBY_PEERS_PROMOTION_IN_PROGRESS',
		payload : { flag }
	});

export const setMuteAllInProgress = (flag) =>
	({
		type    : 'MUTE_ALL_IN_PROGRESS',
		payload : { flag }
	});

export const setStopAllVideoInProgress = (flag) =>
	({
		type    : 'STOP_ALL_VIDEO_IN_PROGRESS',
		payload : { flag }
	});

export const setStopAllScreenSharingInProgress = (flag) =>
	({
		type    : 'STOP_ALL_SCREEN_SHARING_IN_PROGRESS',
		payload : { flag }
	});

export const setCloseMeetingInProgress = (flag) =>
	({
		type    : 'CLOSE_MEETING_IN_PROGRESS',
		payload : { flag }
	});

export const setClearChatInProgress = (flag) =>
	({
		type    : 'CLEAR_CHAT_IN_PROGRESS',
		payload : { flag }
	});

export const setClearFileSharingInProgress = (flag) =>
	({
		type    : 'CLEAR_FILE_SHARING_IN_PROGRESS',
		payload : { flag }
	});

export const setRoomPermissions = (roomPermissions) =>
	({
		type    : 'SET_ROOM_PERMISSIONS',
		payload : { roomPermissions }
	});

export const setUserRoles = (userRoles) =>
	({
		type    : 'SET_USER_ROLES',
		payload : { userRoles }
	});

export const setAllowWhenRoleMissing = (allowWhenRoleMissing) =>
	({
		type    : 'SET_ALLOW_WHEN_ROLE_MISSING',
		payload : { allowWhenRoleMissing }
	});

export const setHideSelfView = (hideSelfView) =>
	({
		type    : 'SET_HIDE_SELF_VIEW',
		payload : { hideSelfView }
	});

export const setIFrameURL = (IFrameURL) =>
	({
		type    : 'SET_IFRAME_URL',
		payload : { IFrameURL }
	});

export const modifyCountdownTimer = (timestamp) =>
	({
		type    : 'START_COUNTDOWN_TIMER',
		payload : { timestamp }
	});

export const voteNext = (votes) =>
	({
		type    : 'VOTE_NEXT',
		payload : { votes }
	});

export const setRoomRecord = (isRecording) =>
	({
		type    : 'SET_ROOM_RECORD',
		payload : { isRecording }
	});

export const setAudioCodec = (audioCodec) =>
	({
		type    : 'SET_AUDIO_CODEC',
		payload : { audioCodec }
	});

export const toggleProducerFullscreen = () =>
	({
		type    : 'TOGGLE_FULLSCREEN_PRODUCER',
		payload : { }
	});

export const setConsumerPreferredLayersLocked = (consumerPreferredLayersLocked) =>
	({
		type    : 'SET_CONSUMER_PREFERRED_LAYERS_LOCKED',
		payload : { consumerPreferredLayersLocked }
	});

export const setShowWebsite = (showWebsite) =>
	({
		type    : 'SET_SHOW_WEBSITE',
		payload : { showWebsite }
	});

export const setPlayGame = (chapter, video) =>
	({
		type    : 'SET_PLAY_GAME',
		payload : { chapter, video }
	});

export const setDrawingTogether = (drawingTogether) =>
	({
		type    : 'SET_DRAWING_TOGETHER',
		payload : { drawingTogether }
	});

export const setWritingTogether = (writingTogether) =>
	({
		type    : 'SET_WRITING_TOGETHER',
		payload : { writingTogether }
	});

export const setConfetti = (showConfetti) =>
	({
		type    : 'SET_CONFETTI',
		payload : { showConfetti }
	});

export const setCricket = (showCricket) =>
	({
		type    : 'SET_CRICKET',
		payload : { showCricket }
	});

export const closeLeaveDialog = () =>
	({
		type : 'CLOSE_LEAVE_DIALOG'
	});

export const openLeaveDialog = () =>
	({
		type : 'OPEN_LEAVE_DIALOG'
	});

export const setBackgroundImage = (image) =>
	({
		type    : 'SET_BACKGROUND_ROOM_IMAGE',
		payload : { image }
	});

export const setEventInfoOpen = (eventInfoOpen) =>
	({
		type    : 'SET_EVENT_INFO_OPEN',
		payload : { eventInfoOpen }
	});

export const setFriendlyRoomName = (friendlyRoomName) =>
	({
		type    : 'SET_FRIENDLY_ROOM_NAME',
		payload : { friendlyRoomName }
	});

export const setFriendlyRoomDescription = (friendlyRoomDescription) =>
	({
		type    : 'SET_FRIENDLY_ROOM_DESCRIPTION',
		payload : { friendlyRoomDescription }
	});

export const addAlwaysVisiblePeer = (peerId, prepend = false, reset = false) =>
	({
		type    : 'ADD_ALWAYS_VISIBLE_PEER',
		payload : { peerId, prepend, reset }
	});

export const removeAlwaysVisiblePeer = (peerId) =>
	({
		type    : 'REMOVE_ALWAYS_VISIBLE_PEER',
		payload : { peerId }
	});

export const setRoomMode = (roomMode) =>
	({
		type    : 'SET_ROOM_MODE',
		payload : { roomMode }
	});

export const setAutoCodecSwitching = (autoCodecSwitching) =>
	({
		type    : 'SET_AUTO_CODEC_SWITCHING',
		payload : { autoCodecSwitching }
	});
