import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import MediaCard from './MediaCard';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const styles = (theme) =>
	({
		root :
		{
			backgroundColor : 'white',
			overflowX       : 'hidden',
			height          : '100%',
			padding         : theme.spacing(3)
		},

		logo :
		{
			display      : 'block',
			marginTop    : theme.spacing(1),
			marginBottom : theme.spacing(2)
		}
	});

const defaultRoomImage = 'https://withlocals-com-res.cloudinary.com/image/upload/v1627570392/x/default.png';

function Alert(props)
{
	return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const ChooseRoomX = ({
	classes
}) =>
{
	const [ rooms, setRooms ] = useState([]);
	const [ open, setOpen ] = useState(false);

	useEffect(() =>
	{
		let mounted = true;

		const getRoomsUrl = () =>
		{
			const port =
			process.env.NODE_ENV !== 'production' ?
				window.config.developmentPort
				:
				window.config.productionPort;
			const url = `${window.location.protocol}//${window.location.hostname}:${port}/api/rooms`;

			return url;
		};

		const getRooms = async () =>
		{
			const data = await fetch(getRoomsUrl());

			return await data.json();
		};

		const getAndSetRooms = () =>
		{
			getRooms()
		  .then(rooms =>
				{
					if (mounted)
						setRooms(
							rooms.rooms.filter((room) => room.startingTime <= 11 * 60).sort((a, b) => b.startingTime - a.startingTime));
		  });
		};

		const id = setInterval(getAndSetRooms, 5 * 1000);

		getAndSetRooms();

		return () =>
		{
			mounted = false;
			clearInterval(id);
		};
	  }, []);

	  const onShare = () =>
	  {
		setOpen(true);
	  };

	  const handleClose = (event, reason) =>
	  {
		if (reason === 'clickaway')
		  return;

		setOpen(false);
	  };

	return (
		<div className={classes.root}>
			<Grid container spacing={2} className={classes.rootGridContainer}>
				<Grid item xs={12}>
					<Grid container>
						<Grid item xs={9}>
							{ window.config.logo && <img alt='Logo' className={classes.logo} src={window.config.logo} /> }
							<Typography variant='h5'>
								<FormattedMessage
									id='room.chooseRoom'
									defaultMessage='Choose the room you would like to join.'
								/>
							</Typography>
						</Grid>
						<Grid item>
							<Button
								component={Link}
								to={{
									pathname : '/custom'
								}}
								variant='outlined'
								color='primary'
							>
								<FormattedMessage
									id='label.customRoomButton'
									defaultMessage='Create custom room'
								/>
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={4} alignItems={'stretch'}>
						{rooms.map((room) =>
							(
								<Grid item key={`mediacard-${room.roomId}`} xs={12} sm={6} md={4}>
									<MediaCard className={classes.mediaCard} onShare={onShare} roomId={room.roomId} title={room.friendlyRoomName || room.roomId} image={room.roomImage || defaultRoomImage} numberOfPeers={room.peers} startingTime={room.startingTime} />
								</Grid>
							)
						)}
					</Grid>
				</Grid>
			</Grid>
			<Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
				<Alert onClose={handleClose} severity='success'>
					Share message copied to clipboard!
				</Alert>
			</Snackbar>
		</div>

	);
};

ChooseRoomX.propTypes =
{
	classes : PropTypes.object.isRequired
};

export default withStyles(styles)(ChooseRoomX);