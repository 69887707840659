export function getSignalingUrl(
	{
		peerId,
		roomId,
		subRoomId = null,
		overrideLock = false,
		isView = false,
		userId
	})
{
	const port =
		process.env.NODE_ENV !== 'production' ?
			window.config.developmentPort
			:
			window.config.productionPort;

	const httpOnly = process.env.HTTPS === 'true';

	const queryParams = new URLSearchParams();

	queryParams.set('peerId', peerId);
	queryParams.set('roomId', roomId);
	queryParams.set('userId', userId);
	if (subRoomId)
	{
		queryParams.set('subRoomId', subRoomId);
	}

	if (overrideLock)
	{
		queryParams.set('o', '1');
	}

	if (isView)
	{
		queryParams.set('v', '1');
	}

	const protocol = httpOnly ? 'ws' : 'wss';

	const url = `${protocol}://${window.location.hostname}:${port}/?${queryParams.toString()}`;

	return url;
}
