export const setCreatePollOpen = (createPollOpen: boolean) =>
	({
		type    : 'SET_CREATE_POLL_OPEN',
		payload : { createPollOpen }
	});

export const setQuizHighscoreOpen = (quizHighscoreOpen: boolean) =>
	({
		type    : 'SET_QUIZ_HIGHSCORE_OPEN',
		payload : { quizHighscoreOpen }
	});

export const setPolls = (polls: Array<object>) =>
	({
		type    : 'SET_POLLS',
		payload : { polls }
	});