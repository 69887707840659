export const resetSelectedPeer = () => ({
	type : 'RESET_SELECTED_PEER'
});

export const setSelectedPeer = (peerId: string) => ({
	type    : 'SET_SELECTED_PEER',
	payload : { peerId }
});

export const setShowDialog = (show: boolean) => ({
	type    : 'SET_SHOW_DIALOG',
	payload : { show }
});

export const closeDialog = () => ({
	type : 'CLOSE_DIALOG'
});

export const setRandomPersonSelectInProgress = (inProgress: boolean) =>
	({
		type    : 'SET_SELECT_IN_PROGRESS',
		payload : { inProgress }
	});