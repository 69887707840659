export const STATE_PREFIX = 'ROOM_MANAGEMENT';

export type Room = {
    id: string;
    roomId: string;
	subRoomId?: string;
	locked?: boolean;
    friendlyRoomName?: string;
    peers: any[];
    persistRoom: boolean;
    parentRoom?: Room;
}

const initialRoomState = {
	id          : '',
	roomId      : '',
	locked      : false,
	persistRoom : true,
	peers       : []
};

const room = (state: Room = initialRoomState, action: any) =>
{
	switch (action.type)
	{
		case `${STATE_PREFIX}:ADD_ROOM`:
		{
			return action.payload.room;
		}

		case `${STATE_PREFIX}:REMOVE_PEER`:
		{
			const peers = state.peers;

			const newPeers = peers.filter((p) => p.id !== action.payload.peerId);

			return {
				...state,
				peers : newPeers
			};
		}

		case `${STATE_PREFIX}:SET_FRIENDLY_ROOM_NAME`:
		{
			return {
				...state,
				friendlyRoomName : action.payload.name
			};
		}

		case `${STATE_PREFIX}:SET_ROOM_LOCK`:
		{
			return {
				...state,
				locked : action.payload.locked
			};
		}

		case `${STATE_PREFIX}:SET_ROOM_PERSISTENCE`:
		{
			return {
				...state,
				persistRoom : action.payload.persistRoom
			};
		}

		case 'SET_PEER_DISPLAY_NAME':
		{
			const newPeers = state.peers.map((peer) =>
			{
				if (peer.id === action.payload.peerId)
				{
					return {
						...peer,
						displayName : action.payload.displayName
					};
				}

				return peer;
			});

			return {
				...state,
				peers : newPeers
			};
		}

		default :
		{
			return state;
		}
	}
};

type RoomState = { [key: string] : Room };

const initialStateManagementState: RoomState = {};

const roomManagement = (state: RoomState = initialStateManagementState, action: any) =>
{
	switch (action.type)
	{
		case `${STATE_PREFIX}:ADD_ROOM`:
		{
			const oldRoom = state[action.payload.roomId];

			return { ...state, [action.payload.room.id]: room(oldRoom, action) };
		}
		case `${STATE_PREFIX}:REMOVE_ROOM`:
		{
			const { id } = action.payload;
			const newState = { ...state };

			delete newState[id];

			return newState;
		}

		case `${STATE_PREFIX}:SET_ROOM_LOCK`:
		case `${STATE_PREFIX}:SET_ROOM_PERSISTENCE`:
		case `${STATE_PREFIX}:SET_FRIENDLY_ROOM_NAME`:
		case `${STATE_PREFIX}:SET_FRIENDLY_ROOM_DESCRIPTION`:
		case `${STATE_PREFIX}:REMOVE_PEER`:
		case 'SET_PEER_DISPLAY_NAME':
		{
			const oldRoom = state[action.payload.roomId];

			// NOTE: This means that the Peer was closed before, so it's ok.
			if (!oldRoom)
				return state;

			return { ...state, [oldRoom.id]: room(oldRoom as any, action) };
		}
		default:
		{
			return state;
		}
	}
};

export default roomManagement;
