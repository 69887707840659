import { STATE_PREFIX } from '../reducers/roomManagement';

export const addRoom = (room: any) =>
	({
		type    : `${STATE_PREFIX}:ADD_ROOM`,
		payload : { room }
	});

export const removeRoom = (id: string) =>
	({
		type    : `${STATE_PREFIX}:REMOVE_ROOM`,
		payload : { id }
	});

export const removePeer = (roomId:string, peerId: string) =>
	({
		type    : `${STATE_PREFIX}:REMOVE_PEER`,
		payload : { roomId, peerId }
	});

export const setRoomLock = (roomId: string, locked: boolean) =>
	({
		type    : `${STATE_PREFIX}:SET_ROOM_LOCK`,
		payload : { roomId, locked }
	});

export const setRoomPersistence = (roomId: string, persistRoom: boolean) =>
	({
		type    : `${STATE_PREFIX}:SET_ROOM_PERSISTENCE`,
		payload : { roomId, persistRoom }
	});

export const setFriendlyRoomName = (roomId: string, name: string) =>
	({
		type    : `${STATE_PREFIX}:SET_FRIENDLY_ROOM_NAME`,
		payload : { roomId, name }
	});

export const setFriendlyRoomDescription = (roomId: string, description: string) =>
	({
		type    : `${STATE_PREFIX}:SET_FRIENDLY_ROOM_DESCRIPTION`,
		payload : { roomId, description }
	});