import { ChatMessage } from '../reducers/chat';

export const addUserMessage = (text: string, type = 'message', recipients = []) =>
	({
		type    : 'ADD_NEW_USER_MESSAGE',
		payload : { text, type, recipients }
	});

export const addResponseMessage = (message: ChatMessage) =>
	({
		type    : 'ADD_NEW_RESPONSE_MESSAGE',
		payload : { message }
	});

export const addChatHistory = (chatHistory: ChatMessage[]) =>
	({
		type    : 'ADD_CHAT_HISTORY',
		payload : { chatHistory }
	});

export const clearChat = () =>
	({
		type : 'CLEAR_CHAT'
	});

export const setRecipients = (recipients: string[]) =>
	({
		type    : 'SET_RECIPIENTS',
		payload : { recipients }
	});

export const removeRecipient = (peerId: string) =>
	({
		type    : 'REMOVE_RECIPIENT',
		payload : { peerId }
	});