import randomString from 'random-string';
import * as notificationActions from './notificationActions';
import React from 'react';
import ChatSnackbar from '../components/Snackbar/ChatSnackbar';

// This returns a redux-thunk action (a function).
export const notify = ({
	type = 'info',
	text,
	timeout
}) =>
{
	if (!timeout)
	{
		switch (type)
		{
			case 'info':
				timeout = 3000;
				break;
			case 'error':
				timeout = 5000;
				break;
			default:
				timeout = 3000;
				break;
		}
	}

	const notification =
	{
		id      : randomString({ length: 6 }).toLowerCase(),
		type    : type,
		text    : text,
		timeout : timeout,
		options : {
			disableWindowBlurListener : true
		}
	};

	return (dispatch) =>
	{
		dispatch(notificationActions.addNotification(notification));

		setTimeout(() =>
		{
			dispatch(notificationActions.removeNotification(notification.id));
		}, timeout);
	};
};

export const notifyChat = ({
	name,
	text
}) =>
{
	const options = {
		// eslint-disable-next-line react/display-name
		content                   : (key, message) => <ChatSnackbar id={key} message={message}/>,
		disableWindowBlurListener : true,
		anchorOrigin              : {
			vertical : 'bottom'
		},
		dense : true
	};

	const timeout = 5000;
	const type = 'chat';

	const notification =
		{
			id   : randomString({ length: 6 }).toLowerCase(),
			type : type,
			text : {
				name,
				message : text
			},
			timeout : timeout,
			options : options
		};

	return (dispatch) =>
	{
		dispatch(notificationActions.addNotification(notification));

		setTimeout(() =>
		{
			dispatch(notificationActions.removeNotification(notification.id));
		}, timeout);
	};
};
