import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
	root : {
		display       : 'flex',
		flexDirection : 'column',
		maxWidth      : '100%',
		height        : '100%',
		boxShadow     :
			'    0 1px 1px var(--shadow-color),' +
			'    0 2px 2px var(--shadow-color),' +
			'    0 4px 4px var(--shadow-color),' +
			'    0 6px 8px var(--shadow-color),' +
			'    0 8px 16px var(--shadow-color);',
		borderRadius              : theme.spacing(1),
		border                    : '1px solid var(--gallery)',
		transition                : 'transform 130ms ease-in-out',
		'&:hover, &:focus-within' : {
			outline        : 'none',
			textDecoration : 'none',
			transform      : 'translate3d(0, -.25rem, 0)',

			boxShadow : 'var(--card-box-shadow-hover)',
			'&:after' :
			{
				opacity : 1
			}
		},
		'&:after' :
		{
			content      : '',
			position     : 'absolute',
			zIndex       : -1,
			width        : '100%',
			height       : '100%',
			top          : 0,
			left         : 0,
			opacity      : 0,
			borderRadius : theme.spacing(1),
			boxShadow    :
				'    0 4px 2px var(--shadow-color),' +
				'    0 8px 4px var(--shadow-color),' +
				'    0 16px 8px var(--shadow-color)',
			transition : 'opacity 200ms ease-in-out'
		}
	},
	actions :
	{
		marginTop : 'auto'
	},
	media : {
		height : 240
	},
	joinButton :
	{
		marginLeft : 'auto'
	},
	numberOfPeople :
	{
		color    : theme.palette.secondary.main,
		fontSize : '1.5rem'
	}
}));

export default function MediaCard({ roomId, title, image, numberOfPeers, startingTime, className, onShare })
{
	const classes = useStyles();
	const history = useHistory();

	const formatStartingTime = (startingTime) =>
	{
		const tenMin = 10 * 60;

		if (startingTime >= tenMin)
		{
			const minutes = Math.round((startingTime - tenMin) / 60);

			return `${minutes} minutes ago`;
		}
		else
		{
			const minutes = Math.round((tenMin - startingTime) / 60);

			return `${minutes} minutes from now`;
		}
	};

	const onShareClick = async () =>
	{
		const url = `${window.location.protocol}//${window.location.host}/${encodeURIComponent(roomId)}`;

		if (navigator.canShare && navigator.canShare())
		{
			try
			{
				await navigator.share({
					url,
					text  : `Come join me on this interactive experience: "${title}"!`,
					title : `Join ${title}`
				});
			}
			catch (exception)
			{
				// eslint-disable-next-line no-console
				console.error('failed to share (native share)');
			}
		}
		else
		{
			try
			{
				await navigator.clipboard.writeText(`Come join me on this interactive experience: "${title} here: ${url}"!`);

				onShare();
			}
			catch (exception)
			{
				// eslint-disable-next-line no-console
				console.error('failed to share (clipboard-copy)');
			}
		}

	};

	return (
		<Card className={classnames(classes.root, className)}>
			<CardMedia
				className={classes.media}
				image={image}
				title={title}
			/>
			<CardContent>
				<Typography gutterBottom variant='h5' component='h2'>
					{title}
				</Typography>
				<Typography variant='body2' color='textSecondary' component='p'>
					Number of people: {numberOfPeers > 0 ? <b className={classes.numberOfPeople}>{numberOfPeers}</b> : numberOfPeers}
				</Typography>
				<Typography variant='body2' color='textSecondary' component='p'>
					Starting time: {formatStartingTime(startingTime)}
				</Typography>
			</CardContent>
			<CardActions disableSpacing className={classes.actions}>
				<Button size='small' color='primary' onClick={onShareClick}>
					Share
				</Button>
				<Button className={classes.joinButton} variant={'contained'} color='secondary' onClick={() =>
				{
					history.push(`/${roomId}?wizard=1`);
				}
				}
				>
					Join
				</Button>
			</CardActions>
		</Card>
	);
}