/**
 * Create a function which will call the callback function
 * after the given amount of milliseconds has passed since
 * the last time the callback function was called.
 */
export const idle = (callback, delay) =>
{
	let handle;

	return () =>
	{
		if (handle)
		{
			clearTimeout(handle);
		}

		handle = setTimeout(callback, delay);
	};
};

/**
 * Error produced when a socket request has a timeout.
 */
export class SocketTimeoutError extends Error
{
	constructor(message)
	{
		super(message);

		this.name = 'SocketTimeoutError';

		if (Error.hasOwnProperty('captureStackTrace')) // Just in V8.
			Error.captureStackTrace(this, SocketTimeoutError);
		else
			this.stack = (new Error(message)).stack;
	}
}

export const IMG_EXTENSIONS = [ 'jpeg', 'jpg', 'gif', 'png', 'apng', 'svg', 'bmp', 'ico' ];
export const VIDEO_EXTENSIONS = [ 'mp4', 'ogv', 'webm', 'mov', '3gp', 'mpeg' ];
export const filename2type = (filename) =>
{
	const nameParts = filename.split('.');
	const extension = nameParts[nameParts.length - 1];

	if (IMG_EXTENSIONS.includes(extension.toLowerCase()))
	{
		return 'img';
	}

	if (VIDEO_EXTENSIONS.includes(extension.toLowerCase()))
	{
		return 'video';
	}

	return null;
};

export const getYoutubeEmbedUrl = (IFrameURL) =>
{
	const getId = (url) =>
	{
		const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
		const match = url.match(regExp);

		if (match && match[2].length === 11)
		{
		  return match[2];
		}
	};

	const id = getId(IFrameURL);

	if (id)
	{
		const urlParams = (new URL(IFrameURL)).searchParams;
		const timestampParam = urlParams.get('t') ? `start=${urlParams.get('t')}` : '';

		return `https://www.youtube.com/embed/${id}?autoplay=1&${timestampParam}`;
	}

	return IFrameURL;
};

export const isValidImageUrl = (url, callback) =>
{
	const img = new Image();

	let timer = null;

	img.onerror = img.onabort = function()
	{
		clearTimeout(timer);
		callback(false);
	};
	img.onload = function()
	{
		clearTimeout(timer);
		callback(true);
	};
	timer = setTimeout(function()
	{
		callback(false);
	}, 2000);
	img.src = url;
};