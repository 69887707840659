export const setRemotePresentationDialogOpen = (open: boolean) =>
	({
		type    : 'SET_REMOTE_PRESENTATION_DIALOG_OPEN',
		payload : { open }
	});

export const setRoomModeDialogOpen = (open: boolean) =>
	({
		type    : 'SET_ROOM_MODE_DIALOG_OPEN',
		payload : { open }
	});

export const setRoomManagerDialogOpen = (open: boolean) =>
	({
		type    : 'SET_ROOM_MANAGER_DIALOG_OPEN',
		payload : { open }
	});

export const setRoomManagerRoomId = (roomId: string) =>
	({
		type    : 'SET_ROOM_MANAGER_ROOM_ID',
		payload : { roomId }
	});

export const setCustomFieldDialogOpen = (open: boolean) =>
	({
		type    : 'SET_CUSTOM_FIELD_DIALOG_OPEN',
		payload : { open }
	});

export const setCustomFieldDialogPeerId = (peerId: string) =>
	({
		type    : 'SET_CUSTOM_FIELD_DIALOG_PEER_ID',
		payload : { peerId }
	});

export const setManageCustomFieldDialogOpen = (open: boolean) =>
	({
		type    : 'SET_MANAGE_CUSTOM_FIELD_DIALOG_OPEN',
		payload : { open }
	});

export const setManageCustomFieldKey = (key: string | null) =>
	({
		type    : 'SET_MANAGE_CUSTOM_FIELD_KEY',
		payload : { key }
	});