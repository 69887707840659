import { combineReducers } from 'redux';
import room from './room';
import me from './me';
import producers from './producers';
import peers from './peers';
import lobbyPeers from './lobbyPeers';
import consumers from './consumers';
import notifications from './notifications';
import chat from './chat';
import toolarea from './toolarea';
import files from './files';
import polls from './polls';
import settings from './settings';
import transports from './transports';
import dialog from './dialog';
import color from './color';
import chatDrawer from './chatDrawer';
import selectRandomPerson from './selectRandomPerson';
import music from './music';
import likes from './likes';
import peerAudio from './peerAudio';
import theme from './theme';
import roomManagement from './roomManagement';
import lobby from './lobby';
import broadcasters from './broadcasters';

export default combineReducers({
	room,
	me,
	producers,
	likes,
	lobby,
	dialog,
	consumers,
	transports,
	peers,
	lobbyPeers,
	peerAudio,
	notifications,
	chat,
	toolarea,
	files,
	settings,
	color,
	chatDrawer,
	polls,
	selectRandomPerson,
	music,
	theme,
	roomManagement,
	broadcasters
});
