type LobbyState = {
    message: string;
}

const initialState: LobbyState = {
	message : 'The moderator will invite you to the live stream when it’s your cue to go live. Make sure to keep this tab open. Enjoy & break a leg!'
};

const dialog = (state = initialState, action: any) =>
{
	switch (action.type)
	{
		case 'SET_LOBBY_MESSAGE':
		{
			const { message } = action.payload;

			return { ...state, message };
		}

		default: {
			return state;
		}
	}
};

export default dialog;