import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import WithlocalsLogo from '../WithlocalsLogo';
import * as roomActions from '../../actions/roomActions';
import ZooskLogo from '../ZooskLogo';
import {
	createStyles,
	DialogContent,
	DialogContentText,
	IconButton,
	lighten,
	makeStyles,
	Theme
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme: any) =>
	createStyles(
		({
			root :
            {
            	display              : 'flex',
            	width                : '100%',
            	height               : '100%',
            	backgroundColor      : theme.customVariables.background.backgroundColor,
            	backgroundImage      : `url(${window.config ? window.config.background : null})`,
            	backgroundAttachment : 'fixed',
            	backgroundPosition   : 'center',
            	backgroundSize       : 'cover',
            	backgroundRepeat     : 'no-repeat'
            },
			dialogTitle :
            {
            	display        : 'flex',
            	justifyContent : 'center',
            	alignItems     : 'center',
            	padding        : 0
            },
			dialogPaper :
            {
            	borderRadius                   : '1rem',
            	width                          : '30vw',
            	[theme.breakpoints.down('lg')] :
                    {
                    	width : '30vw'
                    },
            	[theme.breakpoints.down('md')] :
                    {
                    	width : '50vw'
                    },
            	[theme.breakpoints.down('sm')] :
                    {
                    	width : '70vw'
                    },
            	[theme.breakpoints.down('xs')] :
                    {
                    	margin : 0,
                    	width  : '95vw'
                    }
            },
			logo :
            {
            	display      : 'block',
            	maxWidth     : '100%',
            	marginTop    : theme.spacing(1),
            	marginBottom : theme.spacing(1)
            },
			unavailableText :
            {
            	color  : 'var(--guardsman-red)',
            	margin : theme.spacing(1)
            },
			smallLogo : {
				display   : 'block',
				margin    : '1rem auto',
				maxHeight : '2rem',
				maxWidth  : 390
			},
			withlocalsLogoContainer :
            {
            	display         : 'flex',
            	justifyContent  : 'center',
            	alignItems      : 'center',
            	backgroundColor : '#F2F5F6',
            	padding         : theme.spacing(1)
            },
			withlocalsLogo :
            {
            	height : '1.5rem',
            	margin : '.5rem auto',
            	fill   : 'var(--razzmatazz)',
            	color  : 'var(--razzmatazz)'
            },
			title :
			{
				fontWeight : 600
			},
			text :
			{

			}
		}))
);

type StoreProps = {
	unavailable: boolean;
}

type DispatchProps = {
	resetUnavailableRoom: () => void
}

type UnavailableRoomProps = StoreProps & DispatchProps;

const UnavailableRoom = (props: UnavailableRoomProps) =>
{
	const { unavailable, resetUnavailableRoom } = props;
	const classes = useStyles();
	const history = useHistory();

	useEffect(() =>
	{
		if (unavailable)
		{
			resetUnavailableRoom();
		}
	}, [ unavailable, resetUnavailableRoom ]);

	return (
		<div className={classes.root}>
			<Dialog
				open
				classes={{
					paper : classes.dialogPaper
				}}
			>
				<div className={classes.withlocalsLogoContainer}>
					<IconButton onClick={history.goBack} edge={'end'}>
						<ArrowBackIosIcon/>
					</IconButton>
					<WithlocalsLogo className={classes.withlocalsLogo}/>
				</div>
				{window.config.disableZoosk ? <img alt='Logo' className={classes.smallLogo} src={window.config.logo} /> : <ZooskLogo className={classes.smallLogo}/>}
				<DialogContent>
					<DialogContentText variant={'h6'} className={classes.title}>
						Oh no!
					</DialogContentText>
					<DialogContentText variant={'subtitle1'} className={classes.text}>
						The room you tried to join is no longer available. To resolve it, please create a new experience room <a href={'#'}>here</a>.
					</DialogContentText>
					{window.config.supportEmail &&
					<DialogContentText variant={'subtitle1'} className={classes.text}>
						If you think we made a mistake here, please contact <a href={window.config.supportEmail}>{window.config.supportEmail}</a>.
					</DialogContentText>
					}
				</DialogContent>
			</Dialog>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	unavailable : state.room.unavailable
});

const mapDispatchToProps = {
	resetUnavailableRoom : () => roomActions.setUnavailable(false)
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : (next: any, prev: any) =>
		{
			return (
				prev.room.unavailable === next.room.unavailable
			);
		}
	}
)(UnavailableRoom);
