const initialState: any = {};

const broadcaster = (state = initialState, action: any) =>
{
	switch (action.type)
	{
		case 'ADD_BROADCASTER':
			return action.payload.broadcaster;

		case 'SET_ROOM_ID':
			return { ...state, roomId: action.payload.roomId };

		case 'SET_SUB_ROOM_ID':
			return { ...state, subRoomId: action.payload.subRoomId };

		case 'SET_BROADCASTER_DISPLAY_NAME':
			return { ...state, displayName: action.payload.displayName };

		case 'SET_BROADCASTER_VIDEO_IN_PROGRESS':
			return { ...state, broadcasterVideoInProgress: action.payload.flag };

		case 'SET_BROADCASTER_AUDIO_IN_PROGRESS':
			return { ...state, broadcasterAudioInProgress: action.payload.flag };

		case 'ADD_CONSUMER': {
			const consumers = [ ...state.consumers, action.payload.consumer.id ];

			return { ...state, consumers };
		}

		case 'REMOVE_CONSUMER': {
			const consumers = state.consumers.filter((consumer: any) =>
				consumer !== action.payload.consumerId);

			return { ...state, consumers };
		}

		case 'STOP_BROADCASTER_AUDIO_IN_PROGRESS':
			return {
				...state,
				stopBroadcasterAudioInProgress : action.payload.flag
			};

		case 'START_BROADCASTER_VIDEO_IN_PROGRESS':
			return {
				...state,
				stopBroadcasterVideoInProgress : false
			};

		case 'STOP_BROADCASTER_VIDEO_IN_PROGRESS':
			return {
				...state,
				stopBroadcasterVideoInProgress : action.payload.flag
			};

		default:
			return state;
	}
};

const broadcasters = (state = initialState, action: any) =>
{
	switch (action.type)
	{
		case 'ADD_BROADCASTER': {
			return { ...state, [action.payload.broadcaster.id]: broadcaster(undefined, action) };
		}

		case 'REMOVE_BROADCASTER': {
			const { broadcasterId } = action.payload;
			const newState = { ...state };

			delete newState[broadcasterId];

			return newState;
		}

		case 'SET_BROADCASTER_VIDEO_IN_PROGRESS':
		case 'SET_BROADCASTER_AUDIO_IN_PROGRESS':
		case 'ADD_CONSUMER':
		case 'STOP_BROADCASTER_AUDIO_IN_PROGRESS':
		case 'STOP_BROADCASTER_VIDEO_IN_PROGRESS':
		case 'REMOVE_CONSUMER': {
			const oldBroadcaster = state[action.payload.broadcasterId || action.payload.peerId];

			// NOTE: This means that the Peer was closed before, so it's ok.
			if (!oldBroadcaster)
				return state;

			return { ...state, [oldBroadcaster.id]: broadcaster(oldBroadcaster, action) };
		}

		case 'CLEAR_BROADCASTERS': {
			return initialState;
		}

		default:
			return state;
	}
};

export default broadcasters;
