export const addBroadcaster = (broadcaster) =>
	({
		type    : 'ADD_BROADCASTER',
		payload : { broadcaster }
	});

export const removeBroadcaster = (broadcasterId) =>
	({
		type    : 'REMOVE_BROADCASTER',
		payload : { broadcasterId }
	});

export const clearPeers = () =>
	({
		type : 'CLEAR_PEERS'
	});

export const setPeerDisplayName = (displayName, broadcasterId, roomId, subRoomId) =>
	({
		type    : 'SET_PEER_DISPLAY_NAME',
		payload : { displayName, broadcasterId, roomId, subRoomId }
	});

export const setBroadcasterVideoInProgress = (broadcasterId, flag) =>
	({
		type    : 'SET_BROADCASTER_VIDEO_IN_PROGRESS',
		payload : { broadcasterId, flag }
	});

export const setBroadcasterAudioInProgress = (broadcasterId, flag) =>
	({
		type    : 'SET_BROADCASTER_AUDIO_IN_PROGRESS',
		payload : { broadcasterId, flag }
	});

export const setPeerLabel = (peerId, label) =>
	({
		type    : 'SET_PEER_LABEL',
		payload : { peerId, label }
	});
