import
{
	createNewMessage
} from './helper';

export type ChatMessage = {
	type: string;
	text: string;
	time : number;
	name: string;
	sender : string;
	picture: string;
	recipients?: string[];
}

type ChatState = {
	messages: ChatMessage[];
	recipients: string[];
}

const initialState: ChatState = {
	messages   : [], // list of strings
	recipients : [ 'all' ]
};

const chat = (state = initialState, action: any) =>
{
	switch (action.type)
	{
		case 'ADD_NEW_USER_MESSAGE':
		{
			const { text, type, recipients } = action.payload;

			const message = createNewMessage(text, 'client', 'Me', undefined, type, recipients);

			return {
				...state,
				messages : [ ...state.messages, message ]
			};
		}

		case 'ADD_NEW_RESPONSE_MESSAGE':
		{
			const { message } = action.payload;

			return {
				...state,
				messages : [ ...state.messages, message ]
			};
		}

		case 'ADD_CHAT_HISTORY':
		{
			const { chatHistory } = action.payload;

			return {
				...state,
				messages : [ ...state.messages, ...chatHistory ]
			};
		}

		case 'CLEAR_CHAT':
		{
			return {
				...state,
				messages : []
			};
		}

		case 'SET_RECIPIENTS':
		{
			return {
				...state,
				recipients : action.payload.recipients
			};
		}

		case 'REMOVE_RECIPIENT':
		{
			return {
				...state,
				recipients : [
					...state.recipients.filter((recipientId) => recipientId !== action.payload.peerId)
				]
			};
		}

		default:
			return state;
	}
};

export default chat;
